/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { API_getContractList, API_addContract, API_editContract, API_deleteContract, API_getContractDetail } from "../../service/common"
import Header from "../../components/header";
import { Button, Dialog, Icon, Input, Popup, Switch, Toast, Uploader } from "@nutui/nutui-react";
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate, useParams } from "react-router-dom";
import FileFilter from "../../components/FileFilter";

const ContractList = (props) => {
    const navigate = useNavigate()
    const params=useParams()
    const [pageIndex, setPageIndex] = useState(1);
    const [contractList, setContractList] = useState([]);
    const [invoiceLoading, setInvoiceLoading] = useState(false);
    const [refreshHasMore, setRefreshHasMore] = useState(true);
    const [showContract, setShowContract] = useState(false)
    const [contractInfo, setContractInfo] = useState({})
    const [linkList, setLinkList] = useState([])
    const [removeInfo, setRemoveInfo] = useState({})
    const [showDialog, setShowDialog] = useState(false)
    const [chooseIndex,setChooseIndex]=useState(0)
    useEffect(() => {
        if(params.id>0){
            setInvoiceLoading(true);
            API_getContractDetail(params).then(res=>{
                setInvoiceLoading(false);
                res.data.conteactTextLink=res.data.conteactTextLink.split(',')
                setContractList([res.data]);
                setRefreshHasMore(false)
            })
        }else{
            onGetContractList()
        }
        
    }, [pageIndex])
    const onGetContractList = () => {
        if (invoiceLoading) { return; }
        setInvoiceLoading(true);
        API_getContractList({
            accept: 0,
            pageIndex,
            pageSize: 10
        }).then(res => {
            res.data.map(row=>{
                row.conteactTextLink=row.conteactTextLink.split(',')
            })
            let list = contractList.concat(res.data);
            setContractList([...list]);
            setInvoiceLoading(false);
            if (res.data.length < 10) {
                setRefreshHasMore(false)
            }
        })
    }
    // 触底加载
    const onLoadContractList = () => {
        setPageIndex(pageIndex + 1)
    }
    // 下拉刷新
    const onRefreshContractList = () => {
        setContractList([])
        setPageIndex(1)
        onGetContractList()
    }
    // 下载合同
    const onDownLoadContract = (item) => {
        window.open(item.conteactTextLink)
    }
    const onRemoveContract = (item) => {
        API_deleteContract(removeInfo).then(res => {
            const updatedItems = contractList.filter((_, i) => i !== chooseIndex);
            setContractList(updatedItems);
            Toast.success('删除完成');
            setShowDialog(false)
        })
    }
    // 合同签名
    const onNavSignature = (e) => {
        navigate('/signToImage/signature')
    }
    const onEditContractInfo = async () => {
        console.log(contractInfo)
        if (contractInfo.contractId) {
            await API_editContract(contractInfo).then(res => Toast.success('编辑完成'))
        }
        else {
            await API_addContract(contractInfo).then(res => Toast.success('添加完成'))
        }
        setShowContract(false)
        onRefreshContractList()
    }
    return (
        <>
            <Header title="合同列表"></Header>
            <div className="costom_auto_area">
                <div className="costom_auto_height padlr30" >
                    <InfiniteScroll dataLength={contractList.length} hasMore={refreshHasMore} next={onLoadContractList} pullDownToRefresh inverse refreshFunction={onRefreshContractList}>
                        {contractList.map((item,index) =>
                            <div className="costom_card padlr30" key={item.id}>
                                <div className="l_flex_xbyc gap_b_1 cont_header">
                                    <div className="font15 blod line_1 major padr30 flex1">{item.header}</div>
                                    <div>{item.statue}</div>
                                </div>
                                <div className="l_flex_xbyc gap_b_1 padtb30">
                                
                                    <div className="padl30">
                                        <div className="cont_info">
                                            <div className="l_flex_xbyc">
                                                <div className="minor">公开状态</div>
                                                <div className="line_1 custom_label">{item.isCanSeekContract ? '公开' : '私有'}</div>
                                            </div>
                                            <div className="l_flex_xbyc">
                                                <div className="minor">签名状态</div>
                                                <div className="line_1 custom_label">{item.signature ? '已签名' : '未签名'}</div>
                                            </div>
                                            <div className="l_flex_xbyc">
                                                <div className="minor">创建日期</div>
                                                <div className="line_1 custom_label">{item.createTime}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="cont_file">
                                    {item.conteactTextLink.map(row=>{
                                        return <FileFilter width='100px' url={row} />
                                    })}
                                    
                                </div>
                                <div className="l_flex_xeyc cont_func comor">
                                    {params.id==0?<>
                                       {/* <div className="cont_func_item" onClick={() => {
                                           setContractInfo(item); setShowContract(true);
                                           setLinkList([{
                                               url: item.conteactTextLink,
                                               status: 'success',
                                               type: 'image',
                                           }])
                                       }}>
                                           <Icon name="edit"></Icon>编辑
                                       </div>
                                       <div className="cont_func_item" onClick={() => {setChooseIndex(index); setRemoveInfo({ contractId: item.contractId }); setShowDialog(true) }}>
                                           <Icon name="del"></Icon>删除
                                       </div>
                                       {!item.signature &&
                                           <div className="cont_func_item danger" onClick={() => onNavSignature()}>
                                               <Icon name="edit"></Icon>签名
                                           </div>} */}
                                    </>:<div className="cont_func_item" onClick={() => navigate('/transaction/'+params.id)}>
                                        <Button type='primary'>查看业务</Button>
                                    </div>}
                                </div>
                            </div>
                        )}
                    </InfiniteScroll>
                </div>
                {/* <div className="bottom_area">
                    <div className="bottom_fixed_btn">
                        <Button block onClick={() => setShowContract(true)} type="info">添加合同</Button>
                    </div>
                </div> */}
            </div>
            <Popup visible={showContract} onClose={() => { setContractInfo({}); setShowContract(false) }} style={{ width: '80vw' }} closeable round>
                <div className="pad30 blod align_c gap_b_1">合同信息</div>
                <Input name="text" onChange={(val) => { setContractInfo({ ...contractInfo, header: val }) }} label="抬头" inputAlign="right" defaultValue={contractInfo.header} placeholder="文本" border={false} />
                <div className="l_flex_xbyc padtb20">
                    <div className="font14 padlr30">是否公开</div>
                    <div className="padlr30">
                        <Switch checked={contractInfo.isCanSeekContract} onChange={(value, event) => setContractInfo({ ...contractInfo, isCanSeekContract: value })} />
                    </div>
                </div>
                <div className="l_flex_xbyc padtb20 gap_b_1">
                    <div className="font14 padlr30">合同文件</div>
                    <div className="padlr30">
                        <Uploader url='http://api.zone.yczlsq.com/taxhelppark/UserLogin/FileUpload'
                            defaultFileList={linkList}
                            onSuccess={(res) => {
                                setContractInfo({ ...contractInfo, conteactTextLink: JSON.parse(res.responseText).data })
                            }}
                            listType="picture"
                        />
                    </div>
                </div>
                <div className="pad30">
                    <Button onClick={onEditContractInfo} type="info" block>确认</Button>
                </div>
            </Popup>
            <Dialog
                title="删除合同"
                content="删除的合同将无法恢复，是否继续?"
                visible={showDialog}
                onOk={onRemoveContract}
                onCancel={() => setShowDialog(false)}
            >
            </Dialog>
        </>
    )
}

export default ContractList
