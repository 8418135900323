/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { API_getInvoiceList, API_getTransactionList, API_addBusinessList, API_AddContract, API_userGetContracts } from "../../service/common"
import Header from "../../components/header";
import { Button, Icon, ImagePreview, Infiniteloading, Popup, Tag, Toast } from "@nutui/nutui-react";
import { useNavigate } from "react-router-dom";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import FileFilter from "../../components/FileFilter";
import { RightOutline } from "antd-mobile-icons";

const InvoiceList = (props) => {
  const navigate = useNavigate()
  const [pageIndex, setPageIndex] = useState(1);
  const [invoiceList, setInvoiceList] = useState([]);
  const [invoiceLoading, setInvoiceLoading] = useState(false);
  const [refreshHasMore, setRefreshHasMore] = useState(true);
  let orderCode = { 1: '未受理', 2: '已受理', 3: '已处置', 4: '已拒绝' } // 发票状态
  const [showContract, setShowContract] = useState(false);
  const [invoiceInfo, setInvoiceInfo] = useState({});
  const [showContractList, setShowContractList] = useState(false);
  const [contractList, setContractList] = useState([]);
  const [contractInfo, setContractInfo] = useState(0)
  const [showTransList, setShowTransList] = useState(false);
  const [transList, setTransList] = useState([]);
  const [transInfo, setTransInfo] = useState(0)
  const [showPreview, setShowPreview] = useState(false)
  const [transaction, setTransaction] = useState([]);
  const [invoiceIndex,setInvoiceInfoIndex]=useState(null)
  const [hasTransaction,setHasTransaction]=useState(false)
  useEffect(() => {
    onGetInvoiceList()
  }, [pageIndex])
  const onGetInvoiceList = () => {
    if (invoiceLoading) { return; }
    setInvoiceLoading(true);
    API_getInvoiceList({
      accept: 0,
      pageIndex,
      pageSize: 10
    }).then(res => {
      console.log(res)
      let list = invoiceList.concat(res.data);
      setInvoiceList([...list]);
      setInvoiceLoading(false);
      if (res.data.length < 10) {
        setRefreshHasMore(false)
      }
    })
  }
  const refreshLoadMore = (done) => {
    setPageIndex(pageIndex + 1)
    done()
  }

  const onNavInvoiceInfo = (id) => {
    navigate('/invoicePage/' + id)
  }
  const onGetContractList = () => {
    if (contractList.length) { return; }
    API_userGetContracts({
      accept: 0,
      pageIndex: 1,
      pageSize: 99
    }).then(res => {
      setContractList(res.data);
    })
  }

  // 获取业务列表
  const onGetTransList = () => {
    API_getTransactionList({
      contractId: invoiceInfo.contractId,
      pageIndex: 1,
      pageSize: 99
    }).then(res => {
      setTransList(res.data)
    })
  }


  const showBindDetail = (item,index)=>{
    if(item.contractId){
      setContractInfo({header:item.contractlist.header,contractId:item.contractId});
    }else{
      setContractInfo({})
    }

    if(item.cTransactionId){
      setTransInfo({transactionDescribe:item.transactionDescribe,id:item.cTransactionId});
      setHasTransaction(true)
    }else{
      setTransInfo({})
      setHasTransaction(false)
    }


    setShowContract(true); 
    setInvoiceInfo(item);
    setInvoiceInfoIndex(index)
  }

  //确认绑定

  const confirmSubmit= async()=>{
    let reqData={
      applyId: invoiceInfo.id,
      contractId: contractInfo.contractId,
    }
    if(hasTransaction){
      reqData.cTransactionId=transInfo.id
    await  API_addBusinessList(reqData).then(res => {})
   
    }else{
    await  API_AddContract(reqData)
    }
    Toast.success('已绑定完成')
    window.location.reload()

  }

  return (
    <>
      <Header title="发票管理"></Header>
      <div className="costom_auto_area">
        <div className="costom_auto_height" id="customScroll">
          <Infiniteloading containerId="customScroll" useWindow={false} hasMore={refreshHasMore} onLoadMore={refreshLoadMore}>
            {invoiceList.map((item,index) =>
              <div className="costom_card" key={item.id}>
                <div className="l_flex_xbyc pad30">
                  <div className="l_flex_xbyc">
                    <div className="font15 blod major padr10">发票编号：{item.id}</div>
                    <CopyToClipboard
                      onCopy={() => Toast.text('复制完成')}
                      text={item.id}>
                      <Icon name="order"></Icon>
                    </CopyToClipboard>
                  </div>
                  <div className="minor font15">{orderCode[item.orderState]}</div>
                </div>
                <div className="invo_info" onClick={() => onNavInvoiceInfo(item.id)}>
                  <div className="l_flex_xbyc">
                    <div className="minor">发票抬头</div>
                    <div className="line_1 custom_label">{item.header}</div>
                  </div>
                  <div className="l_flex_xbyc">
                    <div className="minor">发票类型</div>
                    <div className="custom_label">{item.type}</div>
                  </div>
                  <div className="l_flex_xbyc">
                    <div className="minor">开票金额</div>
                    <div className="custom_label">¥{item.money}</div>
                  </div>
                  <div className="l_flex_xbyc">
                    <div className="minor">申请时间</div>
                    <div className="line_1 custom_label">{item.createTime}</div>
                  </div>
                  <div className="l_flex_xbyc">
                    <div className="minor">关联合同</div>
                    <div className="line_1 custom_label">{item.contractId?<div>{item.contractlist.header}</div>:<div>无</div>}</div>
                  </div>
                  <div className="l_flex_xbyc">
                    <div className="minor">关联业务</div>
                    <div className="line_1 custom_label">{item.cTransactionId?<div>{item.transactionDescribe}</div>:<div>无</div>}</div>
                  </div>
                </div>
                <div className="l_flex_xeyc cont_func comor">
                  {/* <Button size="small" plain>重新申请</Button> */}
                  {/* <Button onClick={handlePreviewInvoice(item)} size="small" plain>预览发票</Button> */}
                  <Button type='info' size="small" plain onClick={e=>navigate('/cashflowList/'+item.id)}>查看现金流</Button>
                  <Button type='info' size="small" plain onClick={e=>navigate('/contractList/'+item.contractId)}>查看合同/业务</Button>
                  {/* <Button onClick={() => { showBindDetail(item,index) }} size="small" plain type="danger">绑定合同/业务</Button> */}
                </div>
              </div>
            )}
          </Infiniteloading>
        </div>
      </div>
      <Popup visible={showContract} style={{ width: '86vw' }} onClose={() => setShowContract(false)} closeable round>
        <div className="pad30 blod align_c gap_b_1">发票关联信息</div>
        <>
            <div className="l_flex_xbyc pad30 font14" onClick={() => setShowContractList(true)}>
                    <div>合同</div>
                    <div className="l_flex_xbyc minor">
                        {contractInfo?.header || '选择合同'}
                        <RightOutline />
                    </div>
                </div>
          </>
          <>
            <div className="l_flex_xbyc pad30 font14" >
                    <div>业务情况</div>
                    <div className="l_flex_xbyc minor">
                     <Button shape="square" onClick={e=>{setHasTransaction(true)}} style={{marginRight:"20px"}} type="info" plain ={!hasTransaction}>有业务</Button>
                     <Button shape="square" onClick={e=>{setHasTransaction(false)}} type="info" plain ={hasTransaction}>无业务</Button>
                    </div>
                </div>
          </> 

       {hasTransaction&& <>
            <div className="l_flex_xbyc pad30 font14" onClick={() => setShowTransList(true)}>
                    <div>业务</div>
                    <div className="l_flex_xbyc minor">
                        {transInfo.id?transInfo.transactionDescribe : '请选择业务'}
                        <RightOutline />
                    </div>
                </div>
          </> }
        <div className="pad30">
          <Button type="info" onClick={() => confirmSubmit()} block>确认</Button>
        </div>
      </Popup>
      <Popup visible={showContractList} onOpen={onGetContractList} onClose={() => setShowContractList(false)} position="bottom" round>
        <div className="costom_auto_area" style={{ height: '70vh' }}>
          <div className="pad30 font15 rel tc">合同列表
          </div>
          <div className="costom_auto_height gap_b_1 padlr30">
            {contractList.map(data => (
              <div className="flex_align_c costom_card" onClick={() => {setContractInfo(data);setShowContractList(false)}} key={data.contractId}>
                <div className="padl30">
                  {data.contractId === contractInfo.contractId ? <Icon name="check-checked" color="#fa2c19"></Icon> : <Icon name="check-normal"></Icon>}
                </div>
                <div className="pad30 flex1">
                  <div className="l_flex_xbyc">
                    <div className="blod comer line_1 major padr30 flex1">{data.header}</div>
                    <Tag color={data.isCanSeekContract ? '#409EFF' : '#F56C6C'}>{data.isCanSeekContract ? '公开' : '私有'}</Tag>
                  </div>
                  <div className="minor font14 padt10">{data.createTime}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Popup>
      <Popup visible={showTransList} onOpen={onGetTransList} onClose={() => setShowTransList(false)} position="bottom" round>
        <div className="costom_auto_area" style={{ height: '70vh' }}>
          <div className="pad30 font15 rel tc">可绑定业务列表
          </div>
          <div className="costom_auto_height gap_b_1 padlr30">
            {transList.map(data => (
              <div className="flex_align_c costom_card" onClick={() => {setTransInfo(data);setShowTransList(false)}} key={data.id}>
                <div className="padl30">
                  {data.id === transInfo.id ? <Icon name="check-checked" color="#fa2c19"></Icon> : <Icon name="check-normal"></Icon>}
                </div>
                <div className="pad30 flex1 font14 lh18">
                  <div className="l_flex_xbyc ">
                    <div className="minor">关联合同抬头</div>
                    <div className="line_1 custom_label">{data?.contractlist?.header}</div>
                  </div>
                  <div className="l_flex_xbyc ">
                    <div className="minor">业务名称</div>
                    <div className="line_1 custom_label">{data.transactionDescribe}</div>
                  </div>
                  <div className="l_flex_xbyc ">
                    <div className="minor">创建时间</div>
                    <div className="line_1 custom_label">{data.createTime}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Popup>
      <ImagePreview images={transaction} initNo={0} show={showPreview} onClose={() => setShowPreview(false)} />
    </>
  )
}

export default InvoiceList
