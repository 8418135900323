import { useEffect, useState } from "react";
import Header from "../../components/header";
import {
  API_geCategoryList,
  API_getClasses,
  API_getProductList,
} from "../../service/common";
import { Empty, Tabs, Toast } from "@nutui/nutui-react";
import Product from "../../components/shoppingMall/product";
import { isWechatBrowser } from "../../config/utils";
const ShoppingMall = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [categoryList, setCategoryList] = useState([]);
  const [categoryID, setCategoryID] = useState(0);
  const [productList, setProductList] = useState([]);
  const [classList, setClassList] = useState([]);
  const [isWeChat] = useState(isWechatBrowser());
  useEffect((e) => {
    let list = [{ ID: 0, name: "全部" }];
    API_geCategoryList({ id: "936" }).then((res) => {
      list = list.concat(res.data.subCategoryList);
      setCategoryList(list);
    });
    API_getClasses().then((res) => {
      setClassList(res.data);
    });
  }, []);

  const getProductList = (index) => {
    setActiveIndex(index);
    let id = categoryList[index].ID;
    setCategoryID(id);
  };

  useEffect(
    (e) => {
      let reqData = {
        start: 1,
        count: 99,
        isSoldOut: false,
        orderBy: "asc",
        categoryID: categoryID || "936",
        categoryClass: categoryID ? 2 : 1,
      };
      Toast.loading("加载中...");
      API_getProductList(reqData).then((res) => {
        Toast.hide();
        setProductList(res.data);
      });
    },
    [categoryID]
  );
  return (
    <>
      <Header title="集采供应"></Header>
      <Tabs
        style={{ height: "calc(100vh - 44px)" }}
        value={activeIndex}
        onChange={(value) => {
          getProductList(value.index);
        }}
        direction="vertical"
      >
        {categoryList.map((item, index) => (
          <Tabs.TabPane value={item.ID} key={item.ID} title={item.name}>
            {activeIndex == index &&
              productList.map((data) => (
                <div style={{position:'relative'}}>
                  {isWeChat && (
                    <wx-open-launch-weapp // 需要把该标签设置为透明的 在非微信微信环境下 是不显示该该标签的
                      username="gh_06d20f9e5b3e" // 所需跳转的小程序原始id，即小程序对应的以gh_开头的id 必填
                      path={
                        `/hiShopping/pages/details/details.html?goodsId=` +
                        data.ID
                      } // 所需跳转的小程序内页面路径及参数 需要在路径后面添加.html
                      env-version="release" // 小程序的版本
                      style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                    >
                      <script type="text/wxtag-template">
                        <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}></div>
                      </script>
                    </wx-open-launch-weapp>
                  ) }
                  <Product itemData={data}></Product>
                  <div style={{height:'1px',backgroundColor:"#eee",width:'100%',marginBottom:'20px'}}></div>
                
                </div>
              ))}
            {productList.length == 0 && (
              <div
                className="flex-center"
                style={{ marginTop: "30px", width: "100%" }}
              >
                <Empty description="暂无商品" />
              </div>
            )}
          </Tabs.TabPane>
        ))}
      </Tabs>
    </>
  );
};

export default ShoppingMall;
