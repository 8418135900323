import { useState, useEffect, useRef } from "react";

import { Button, Popup } from "@nutui/nutui-react";
const AddressPicker = (props) => {
    const [showAmap, setShowAmap] = useState(false);
    const mapRef = useRef(null);
    useEffect(e => {
        if (showAmap) {
            setTimeout(e => {
                console.log(mapRef)
                var iframe = mapRef.current.contentWindow;
                iframe.postMessage('hello', 'https://m.amap.com/picker/');
                window.addEventListener("message", function (e) {
                    if (e.data.location) {
                        setShowAmap(false)
                        props.getAddress(e.data.location.split(','), e.data.name)
                    }
                }, false);
            }, 1000)
        }
    }, [showAmap])
    return (
        <div className="addressPicker">
            <Button
                onClick={() => setShowAmap(true)}
                style={{ marginLeft: "10px" }}
            >
                选取位置
            </Button>
            <Popup
                visible={showAmap}
                style={{ width: '80%', height: "80vh" }}
                onClose={() => setShowAmap(false)}
            >
                <iframe id="map-content" src="https://m.amap.com/picker/?key=030324c1d7cbaa237cbd0c4069f33a13" style={{ width: '100%', height: "80vh" }} ref={mapRef}>
                </iframe>
            </Popup>
        </div>
    )
}

export default AddressPicker