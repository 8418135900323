import { useEffect, useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import './signature.less';
import { useNavigate } from 'react-router-dom';

const Signature = (props) => {
  const signCanvas = useRef(null);
  const navigate = useNavigate()
  useEffect(() => {
    // 竖屏模式下横过来，横屏状态下不变
    var width = document.documentElement.clientWidth;
    var height = document.documentElement.clientHeight;
    if (width < height) {
      detectOrient();
    }
  }, []);
  const confirmSign = () => {
    const sign = signCanvas.current.toDataURL('image/png');
    localStorage.setItem('signBase64', sign);
    navigate('/signToImage/signToImage')
  };

  const detectOrient = function (id = 'content') {
    //先将整个h5页面翻转
    let width = document.documentElement.clientWidth;
    let height = document.documentElement.clientHeight,
      $wrapper = document.getElementById(id),
      style = '',
      canvasStyle = '';
    style += 'width:' + height + 'px;';
    style += 'height:' + width + 'px;';
    style += '-webkit-transform: rotate(90deg); transform: rotate(90deg);';
    style += '-webkit-transform-origin: ' + width / 2 + 'px ' + width / 2 + 'px;';
    style += 'transform-origin: ' + width / 2 + 'px ' + width / 2 + 'px;';
    $wrapper.style.cssText = style;
    //将签名还原翻转，就可以保证在横屏情况下保证画笔的方向跟手势一致，然后再进行高度和宽度的调整。
    let parentElement = document.getElementById('signContent');
    let pw = parentElement.clientWidth;
    let ph = (parentElement.clientHeight - 10);
    parentElement.style.cssText = 'height: ' + ph * 2 + 'px';

    let canvasElement = signCanvas?.current.getCanvas();
    canvasElement.height = pw;
    canvasElement.width = ph * 2;
    canvasStyle += 'transform-origin: ' + ph + 'px ' + ph + 'px;transform: rotate(-90deg);';
    canvasStyle += 'background: #f2f2f2;height:' + pw + ';width:' + ph * 2;
    canvasElement.style.cssText = canvasStyle;
  };

  return (
    <div className="sign-page" id="content">
      <div className='signContainer'>
        <div className='salarySignContainer'>
          <div className='salarySignTitle'>
            <div
              className='goBack'
              onClick={() => {
                navigate(-1)
              }}
            >
              返回
            </div>
            请签名确认
          </div>
          {/* 画布 */}
          <div id="signContent" className='salarySignContent'>
            <SignatureCanvas
              ref={signCanvas}
              penColor="#000"
              backgroundColor="#fff"
            />
          </div>
          {/* 按钮 */}
          <div className='salarySignBar' >
            <div
              className='salarySignBtn btn1'
              onClick={() => {
                confirmSign();
              }}
            >
              确认
            </div>
            <div
              className='salarySignBtn btn2'
              onClick={() => {
                // history.goBack();
                signCanvas.current?.clear();
              }}
            >
              清除
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};
export default Signature;