import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getQueryString } from "../config/utils";
import {
  API_getInvoiceApplyDetail,
  API_handleInvoiceApply,
} from "../service/common";
import {
  Button,
  Cell,
  CellGroup,
  Divider,
  Input,
  Toast,
  Uploader,
} from "@nutui/nutui-react";

const FeedBack = () => {
  const { id } = useParams();
  const [info, setInfo] = useState([]);
  const [money, setMoney] = useState();
  const [money2, setMoney2] = useState();
  const [rate, setRate] = useState("");
  const rateList = [0, 1, 3, 6, 9, 13, 17];
  const [rightNum, setRightNum] = useState(false);
  useEffect(
    (e) => {
      if (money && money2) {
        setRate((((money2 - money) / money) * 100).toFixed(1));
        setRightNum(
          rateList.some((data) => {
            return data == (((money2 - money) / money) * 100).toFixed(1);
          })
        );
      } else {
        setRightNum(false);
        setRate("");
      }
    },
    [money, money2]
  );

  useEffect(
    (e) => {
      API_getInvoiceApplyDetail({ id }).then((res) => {
        setInfo(res.data);
      });
    },
    [id]
  );

  const columns2 = [
    { title: "发票类型", key: "type", type: "text" },
    { title: "开票金额", key: "money", type: "text" },
    { title: "纳税人类型", key: "category", type: "text" },
    { title: "申请时间", key: "createTime", type: "text" },
    {
      title: "状态",
      key: "orderState",
      type: "text",
      render: (text) => {
        switch (text) {
          case 1:
            return "待受理";
          case 2:
            return "待处置";
          case 3:
            return "已处置";
          case 4:
            return "已拒绝";
        }
      },
    },
  ];
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [invoiceImg, setInvoiceImg] = useState("");
  const normFile = (e) => {
    let res = JSON.parse(e.responseText);
    setInvoiceImg(res.data);
  };

  const onFinish = (e) => {
    if (!rightNum) {
      return;
    }
    setLoadingBtn(true);
    let reqData = {
      orderId: info.id,
      message: invoiceImg,
      money: money2,
      tax: rate,
    };
    API_handleInvoiceApply(reqData).then((res) => {
      Toast.success("已提交");
      info.orderState = 3;
      setInfo({ ...info });
      setLoadingBtn(false);
    });
  };

  const scan = () => {
    window.wx.scanQRCode({
      needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
      scanType: ["qrCode"], // 可以指定扫二维码还是一维码，默认二者都有
      success: function (res) {
        let resList = res.resultStr.split(",");
        if (resList.length > 5) {
          setMoney(resList[4]);
        }
      },
    });
  };
  return (
    <div className="feedback-Content">
      <>
      <CellGroup>
        <Cell title="发票编号" desc={info.id}></Cell>
        <Cell title="购货单位" desc={info?.invoiceNotice?.buyer?.name}></Cell>
        <Cell title="售货单位" desc={info?.invoiceNotice?.seller?.name}></Cell>
        <Cell title="发票金额" desc={info.money}></Cell>
      </CellGroup>
        <Divider>处置反馈</Divider>
        {info.orderState == 2?<>
          <Input
          label="不含税金额"
          placeholder="请点击扫码发票二维码"
          defaultValue={money}
          disabled
          center
          slotButton={
            <Button size="small" type="info" onClick={scan}>
              微信扫一扫
            </Button>
          }
        />
        <Input
          label="含税金额"
          size="large"
          placeholder="请输入含税金额"
          onChange={(e) => setMoney2(e.target.value)}
          value={money2}
        />
        <div
          style={{
            textAlign: "left",
            backgroundColor: "#fff",
            padding: "10px 15px",
          }}
        >
          税率:{rate}%
          {!rightNum && (
            <span className="warning">税率错误,检查金额是否正确!</span>
          )}
        </div>
        <div  style={{
            textAlign: "left",
            backgroundColor: "#fff",
            padding: "10px 15px",
          }}>
          <Uploader
            name="file"
            url="http://api.zone.yczlsq.com/taxhelppark/UserLogin/FileUpload"
            uploadIconSize="20px"
            uploadIconTip="上传发票"
            onSuccess={normFile}
          />
        </div>

        <div className="bottom_area">
          <div className="bottom_fixed_btn">
            <Button onClick={onFinish} type="primary" block>
              提交
            </Button>
          </div>
        </div>
        <div style={{ height: "50px" }}></div>
        </>:<Cell title="处置状态" desc="已处置"></Cell>}
      </>
    </div>
  );
};

export default FeedBack;
