import './App.less';
import { Route, Routes, HashRouter as Router, useNavigate } from "react-router-dom";
import FeedBack from './views/feedback';
import Result from "./views/result"
import axios from 'axios';
import '@nutui/nutui-react/dist/style.css'
import Login from './views/login';
import PersonRegister from './views/personRegister';
import { config as AmapReactConfig } from "@amap/amap-react";
import Message from './views/message';
import Signature from './views/signToImage/signature';
import InvoicePage from './views/invoice/invoicePage';
import InvoiceList from './views/invoice/invoiceList';
import ContractList from './views/contract/contractList';
import Dashboard from './views/dashboard';
import InvoiceItems from './views/invoice/InvoiceItems';
import InvoicePreview from './views/invoice/invoicePreview';
import SignToImage from './views/signToImage/signToImage';
import Contract from './views/signToImage/contract';
import Transaction from './views/transaction/transaction';
import CashflowList from './views/cashflow/cashflowList';
import CashflowDetail from './views/cashflow/cashflowDetail';
import ProductList from './views/shoppingMall/productList'
import StaffManage from './views/staff/staffManage';
import SignContract from './views/tools/signContract';
import MyCompany from './views/pitfall/myCompany';
import PitfallList from './views/pitfall/pitfallList';
import ExportFile from './views/pitfall/exportFile';
import Menu from './views/staffTools/menu';
import VisitList from './views/staffTools/visitList';
import VisitMap from './views/staffTools/visitMap';
import Registration from './views/tools/registration';
import StaffCompanyList from './views/staffTools/staffCompanyList';
import ExportVisit from './views/staffTools/exportVisit';
AmapReactConfig.version = "2.0"; // 默认2.0，这里可以不修改
AmapReactConfig.key = "030324c1d7cbaa237cbd0c4069f33a13";
AmapReactConfig.plugins = [
  "AMap.ToolBar",
  "AMap.MoveAnimation",
  "AMap.Geocoder",
  "AMap.AutoComplete",
  // 在此配置你需要预加载的插件，如果不配置，在使用到的时候会自动异步加载
];

// const getShare = function () {
//   if (!isWechatBrowser()) {
//     return;
//   }
//   API_getSignature({
//     url: window.location.href.split("#")[0],
//     officialAccountType:4
//   }).then((resData) => {
//     window.wx.config({
//       debug: false, // 开启调试模式,调用的所有api的返回值会在客户端console.log出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
//       appId: resData.appId, // 必填，公众号的唯一标识
//       timestamp: resData.timestamp, // 必填，生成签名的时间戳
//       nonceStr: resData.noncestr, // 必填，生成签名的随机串
//       signature: resData.signature, // 必填，签名
//       openTagList: ["wx-open-subscribe"],
//       jsApiList: [
//         "updateAppMessageShareData",
//         "updateTimelineShareData",
//         "openLocation",
//         "scanQRCode",
//         "addEventListener",
//       ], // 必填，需要使用的JS接口列表
//     });

//     document.addEventListener("WeixinOpenTagsError", function(e) {
//       alert(JSON.stringify(e)); // 无法使用开放标签的错误原因，需回退兼容。仅无法使用开发标签，JS-SDK其他功能不受影响
//     });
//   });

//   window.wx.error(function (res) {
//     console.log('认证失败', res)
//     alert(res)
//     // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
//   });
// }

// getShare()

function App() {

  return (
    <div className="App">
      <Router>
        <Routes >
        <Route path="/" element={<Login />}></Route>
          <Route path="/staffMenu" > 
            <Route path="/staffMenu/menu" element={<Menu />}> </Route>
            <Route path="/staffMenu/visit" element={<VisitList />}></Route>
            <Route path="/staffMenu/visitMap" element={<VisitMap />}></Route>
            <Route path="/staffMenu/staffCompanyList" element={<StaffCompanyList />}></Route>
            <Route path="/staffMenu/exportVisit" element={<ExportVisit />}></Route>
          </Route>
         
          <Route path="/home" element={<Dashboard />}></Route>
          <Route path="/invoicePage/:id" element={<InvoicePage />}></Route>
          <Route path="/invoiceList" element={<InvoiceList></InvoiceList>}></Route>
          <Route path="/invoiceItems" element={<InvoiceItems />}></Route>
          <Route path="/invoicePreview" element={<InvoicePreview />}></Route>
          <Route path="/feedback/:id" element={<FeedBack></FeedBack>}></Route>
          <Route path="/result/:id" element={<Result></Result>}></Route>
          <Route path="/personRegister" element={<PersonRegister></PersonRegister>}></Route>
          <Route path="/message" element={<Message></Message>}></Route>
          <Route path="/contractList/:id" element={<ContractList />}></Route>
          <Route path="/transaction/:id" element={<Transaction />}></Route>
          <Route path="/cashflowList/:id" element={<CashflowList />}></Route>
          <Route path="/staffList" element={<StaffManage />}></Route>
          <Route path="/cl/:id" element={<CashflowDetail />}></Route>
          <Route path="/sc/:id" element={<SignContract />}></Route>
          <Route path="/signToImage" >
            <Route path="/signToImage/signToImage" element={<SignToImage></SignToImage>}></Route>
            <Route path="/signToImage/signature" element={<Signature></Signature>}></Route>
            <Route path="/signToImage/contract/:id" element={<Contract></Contract>}></Route>
          </Route>
          <Route path='/shoppingMall'>
             <Route path='/shoppingMall/productList' element={<ProductList></ProductList>}></Route>
          </Route>
          <Route path='/pitfall'>
             <Route path='/pitfall/myCompany' element={<MyCompany></MyCompany>}></Route>
             <Route path='/pitfall/pitfallList/:companyId' element={<PitfallList></PitfallList>}></Route>
             <Route path='/pitfall/export/:companyId' element={<ExportFile></ExportFile>}></Route>
          </Route>
          <Route path="/registration" element={<Registration></Registration>}></Route>
        </Routes>
      </Router>
    </div>
  );
}




export default App;
