// import {
//   Table,
//   Button,
//   Modal,
//   message,
//   Divider,
//   Form,
//   Upload,
//   Input,
// } from "antd";
// import { InboxOutlined } from "@ant-design/icons";
// import { useEffect, useState } from "react";
// import { useHref, useLocation, useMatch, useParams } from "react-router-dom";
// import AntdCell from "../components/antdCell";
// import { getQueryString } from "../config/utils";
// import { API_getInvoiceApplyDetail, API_handleInvoiceApply } from "../service/common";

// const FeedBack = () => {
//   const { id } = useParams();
//   const token = getQueryString("token");
//   const [info, setInfo] = useState([]);
//   const [money, setMoney] = useState();
//   const [money2, setMoney2] = useState();
//   const [rate, setRate] = useState('');
//   const rateList = [0, 1, 3, 6, 9, 13, 17];
//   const [rightNum,setRightNum]=useState(false)
//   useEffect(e => {
//     if (money && money2) {
//       setRate(((1 - money / money2) * 100).toFixed(1));
//       setRightNum(rateList.some(data => {
//         return data == ((1 - money / money2) * 100).toFixed(1);
//       }))
//     } else {
//       setRightNum(false)
//       setRate('')
//     }
//   },[money,money2])
//   useEffect(
//     (e) => {
//       if (token) {
//         localStorage.setItem("accessToken", token);
//       }
//     },
//     [token]
//   );

//   useEffect(
//     (e) => {
//       API_getInvoiceApplyDetail({ id }).then((res) => {
//         setInfo(res.data);
//       });
//     },
//     [id]
//   );

//   const columns2 = [
//     { title: "发票类型", key: "type", type: "text" },
//     { title: "开票金额", key: "money", type: "text" },
//     { title: "纳税人类型", key: "category", type: "text" },
//     { title: "申请时间", key: "createTime", type: "text" },
//     {
//       title: "状态",
//       key: "orderState",
//       type: "text",
//       render: (text) => {
//         switch (text) {
//           case 1:
//             return "待受理";
//           case 2:
//             return "待处置";
//           case 3:
//             return "已处置";
//           case 4:
//             return "已拒绝";
//         }
//       },
//     },
//   ];
//     const [isModalVisible, setIsModalVisible] = useState(false);
//     const [loadingBtn, setLoadingBtn] = useState(false);
//     const [invoiceImg,setInvoiceImg]=useState('')
//     const normFile = (e) => {
//       let response = e.file.response
//       if (response) {
//         if (response.status == 'OK') {
//           setInvoiceImg(response.data)
//         } else {
//           message.error("上传失败!")
//         }
//       }
//     };


//   const onFinish = (e) => {
//     if (!rightNum) {
//       return;
//     };
//       setLoadingBtn(true);
//       let reqData = {
//         orderId: info.id,
//         message: invoiceImg,
//         money: money2,
//         tax: rate,
//       };
//       API_handleInvoiceApply(reqData).then(res=>{
//         message.success("已提交");
//         info.orderState = 3;
//         setInfo({...info})
//         setLoadingBtn(false)
//       })
//     }

//   const scan = () => {
//       window.wx.scanQRCode({
//         needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
//         scanType: ["qrCode"], // 可以指定扫二维码还是一维码，默认二者都有
//         success: function (res) {
//           let resList = res.resultStr.split(",");
//           if (resList.length > 5) {
//             setMoney(resList[4]);
//           }
//         },
//       });
//     }
//   return (
//     <div className="feedback-Content">
//       <AntdCell columns={columns2} dataSource={info}></AntdCell>
//       {info.orderState==2 && (
//         <>
//           <Divider>处置反馈</Divider>
//           <Form
//             name="validate_other"
//             onFinish={onFinish}
//             initialValues={{
//               "input-number": 3,
//               "checkbox-group": ["A", "B"],
//               rate: 3.5,
//             }}
//           >
//             <Form.Item label="不含税金额">
//               <Input.Group compact>
//                 <Input
//                   size="large"
//                   disabled
//                   style={{ width: "calc(100% - 112px)" }}
//                   value={money}
//                 />
//                 <Button size="large" type="primary" onClick={scan}>
//                   微信扫一扫
//                 </Button>
//               </Input.Group>
//             </Form.Item>
//             <Form.Item label="含税金额">
//               <Input
//                 size="large"
//                 onChange={(e) => setMoney2(e.target.value)}
//                 value={money2}
//               />
//             </Form.Item>
//             <div style={{ textAlign: "left" }}>
//               税率:{rate}%{!rightNum&&(<span className="warning">税率错误,检查金额是否正确!</span>)}
//             </div>
//             {/* <Form.Item label="发票附件">
//               <Form.Item
//                 name="dragger"
//                 valuePropName="fileList"
//                 getValueFromEvent={normFile}
//                 noStyle
//               >
//                 <Upload.Dragger
//                   maxCount={1}
//                   name="file"
//                   action="http://api.zone.yczlsq.com/taxhelppark/UserLogin/FileUpload"
//                 >
//                   {invoiceImg ? (
//                     <img className="uploadImg" src={invoiceImg} />
//                   ) : (
//                     <>
//                       <p className="ant-upload-drag-icon">
//                         <InboxOutlined />
//                       </p>
//                       <p className="ant-upload-text">选择发票附件</p>
//                     </>
//                   )}
//                 </Upload.Dragger>
//               </Form.Item>
//             </Form.Item> */}
//             <Form.Item>
//               <Button
//                 loading={loadingBtn}
//                 style={{ width: "150px" }}
//                 type="primary"
//                 size="large"
//                 htmlType="submit"
//                 disabled={!rightNum}
//               >
//                 提交
//               </Button>
//             </Form.Item>
//           </Form>
//           <div style={{height:"50px"}}></div>
//         </>
//       )}
//     </div>
//   );
// };

export default function FeedBack(){
    return<></>
};
