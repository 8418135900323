import { useNavigate } from "react-router-dom"
import Header from "../../components/header"

const Menu =(props)=> {
    const navigate=useNavigate();
    const logout= ()=>{
     localStorage.clear()
      navigate('/')
    }
    
    return (
      <>
       <Header title="税邦工具"  right={<div onClick={logout}>退出登录</div>}></Header>
       <div>
         <div className="l-flex-xc l_flex_warp font14 align_c dash_content">
         <div
             className="dash_item"
             onClick={() => navigate("/staffMenu/staffCompanyList")}
           >
             <img src={require("../../assets/imgs/sq.png")} />
             <div>企业拜访</div>
           </div>
           <div
             className="dash_item"
             onClick={() => navigate("/pitfall/myCompany")}
           >
             <img src={require("../../assets/imgs/ht.png")} />
             <div>企业安全管理</div>
           </div>
         </div>
       </div>
      </>
    )
}

export default Menu
