import { useEffect, useRef, useState } from "react"
import Header from "../../components/header";
import { dataURLToBlob, urlToBase64 } from "../../config/utils";
import { Button, Toast } from "@nutui/nutui-react";
import { API_submitContractSign, API_uploadFile } from "../../service/common";

const SignToImage =(props)=> {
  const contract= JSON.parse(localStorage.getItem('contract'))
  const canvasRef = useRef(null);
  const imgRef=useRef(null)
  const url=contract.conteactTextLink
  const signWidth=200;
  const windowWidth=document.documentElement.clientWidth;
  const [status,setStatus]=useState('normal')
  const [contractBase64,setContractBase64]=useState('')
  useEffect(e=>{
    urlToBase64(url,function(base64){
      setContractBase64(base64)
    })
  },[])
  const handleImageLoad = ()=>{

    var canvas = canvasRef.current ;
    var ctx = canvas.getContext('2d');
    let imgNewHeight=imgRef.current.height*windowWidth/imgRef.current.width

    canvas.height=imgNewHeight
    ctx.drawImage(imgRef.current, 0, 0,windowWidth,imgNewHeight);
    var img = new Image();
    img.src = localStorage.getItem('signBase64');
    img.onload = function() {
      ctx.save(); // 保存当前的绘图状态
      ctx.translate(canvas.width - signWidth, canvas.height+signWidth/2); // 将坐标系移动到canvas的右下角
      ctx.rotate(-Math.PI/2); // 旋转90度
      ctx.drawImage(img, 0,0,signWidth,signWidth);
      ctx.restore(); // 恢复之前保存的绘图状态
    };
    
  }

  const confirmSubmit= ()=>{
    var canvas = canvasRef.current ;
    const dataURL = canvas.toDataURL('image/png');
    console.log(dataURL)
    const blob = dataURLToBlob(dataURL);
    const file = new File([blob], 'canvas.png', { type: 'image/png' });
    Toast.loading("正在提交...");
    setStatus('loading')
    API_uploadFile({file:file}).then(res=>{
      API_submitContractSign({contractId:contract.contractId,signature:res.data}).then(res=>{
        Toast.success('提交成功');
        setStatus('success')
      }).catch(e=>{
        setStatus('fail')
      })
    })
  }
    return (
      <>
      <Header title="签名预览"></Header>
       {url&&<img onLoad={handleImageLoad} style={{display:'none',width:windowWidth+"px"}} ref={imgRef} src={contractBase64} />}
       <canvas ref={canvasRef} id='sign-image' width={windowWidth}  >
          
       </canvas>
       <div className="footer-btn-fix">
         <div className="warp"></div>
         <Button loading={status=='loading'} disabled={status!='normal'} type='info' block onClick={confirmSubmit}>{status=='success'?'已提交':'确认提交'}</Button>
       </div>
      </>
    )
}

export default SignToImage
