import "./login.less";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Cell, Dialog, Icon, Input, Popup, Toast } from "@nutui/nutui-react";
import { API_accountLogin, API_accountLoginByMsg, API_getAccountSubAccount, API_getCurrentCustomer, API_getMsgCode, API_getMyOrgan, API_getUserInfo, API_subAccountLogin } from "../service/common";

const Login = () => {
  const navigate = useNavigate();
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [msg, setMsg] = useState("");
  const [isPwd, setIsPwd] = useState(true); // 密码登录
  const [isEye, setIsEye] = useState(false); // 查看密码
  const [isLoadingCode, setIsLoadingCode] = useState(false) // 获取验证码中
  const [msgTime, setMsgTime] = useState(null) // 验证码时间
  const [num, setNum] = useState(60)
  const [loading,setLoading]=useState(false)
  const [showCompanyList,setShowCompanyList]=useState(false);
  const [companyList,setCompanyList]=useState([]);
  var userInfo={}
  useEffect(() => {
    setPhone(localStorage.getItem('phone'))
    if(localStorage.getItem('accessToken')){
      API_getUserInfo().then( (res) => {
          localStorage.setItem('userID', res.data.userID)
          localStorage.setItem('userType', res.data.userType)
          switchPage(res.data)
      }).catch(e=>{
        let phone=localStorage.getItem("phone");
        localStorage.clear();
        localStorage.setItem('phone',phone)
      })
    }
  }, [])

  // 验证手机号
  const onTestPhone = (phone) => {
    let pattern = /^((\+|00)86)?1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/;
    return pattern.test(phone)
  }

  const onSendMsgCode = () => {
    if (num < 60) return;
    if (!onTestPhone(phone)) {
      Toast.fail('无效的手机号码')
      return
    }
    setIsLoadingCode(true)
    API_getMsgCode({ phone }).then(res => {
      Toast.success('验证码已发送')
      setMsgTime(setInterval(e => {
        setNum(e => e - 1);
        setIsLoadingCode(false)
      }, 1000))
    }).catch(err => {
      setIsLoadingCode(false)
    })
  }

  useEffect(e => {
    if (num < 1) {
      clearInterval(msgTime);
      setMsgTime(null);
      setNum(60)
    }
  }, [msgTime, num])

  // 提交登录
  const handleSubmit = async () => {
    if (!onTestPhone(phone)) {
      Toast.fail('无效的手机号码')
      return
    }
    setLoading(true)
    let accessToken = ''
    if (isPwd) {
      accessToken = await API_accountLogin({ phone, password }).then(res => res.data).catch(e=>{setLoading(false)})
    } else {
      accessToken = await API_accountLoginByMsg({ phone, password: msg }).then(res => res.data).catch(e=>{setLoading(false)})
    }
    if(accessToken){
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("phone", phone);
      setLoading(false)
      handleData()
    }
  };

  const handleData = () => {
    API_getUserInfo().then(async (res) => {
      localStorage.setItem('userID', res.data.userID)
      localStorage.setItem('userType', res.data.userType)
      let subUsers=await API_getAccountSubAccount({masterUserId:res.data.userID})
      if(subUsers.data.length>0){
        setCompanyList([res.data].concat(subUsers.data));
        setShowCompanyList(true);
        return;
      }else{        
        switchPage(res.data);
      }

    });
  };

  const chooseCompany =(data,index)=>{
    if(index==0){
      switchPage(data)
    }else{
      Toast.loading('正在登录...')
      let {userID,phone}=data;
      API_subAccountLogin({userID,phone}).then(row=>{
        localStorage.setItem("accessToken", row.data);
        Toast.hide()
        API_getUserInfo().then(async (res) => {
          localStorage.setItem('userID', res.data.userID)
          localStorage.setItem('userType', res.data.userType)
          switchPage(res.data)
      })
      })
    }
    setShowCompanyList(false)
  }

  const switchPage=(userInfo)=>{
    localStorage.setItem('companyInfo','')
    if(!localStorage.getItem('accessToken')){
      return;
    }
    let userType = userInfo.userType;
    if(userType==3||userType==4){
      localStorage.setItem("staffId", userInfo.staffId);
      localStorage.setItem("staffName", userInfo.staffName);
      localStorage.setItem("organId", userInfo.organId);
      navigate('/staffMenu/menu')
      if(userType==4){
        API_getMyOrgan().then(
          (res) => {
            let info = res.data;
            if (info && info.id) {
              localStorage.setItem('organId', info.organid)
            }else{
              Toast('机构用户未审核')
            }
          }
        );
      }
    }else if(userType==5){
      API_getCurrentCustomer().then(res=>{
         if(res.data.id){
          localStorage.setItem('companyInfo',JSON.stringify(res.data))
          navigate('/pitfall/pitfallList/'+res.data.id)
         }else{
          Toast.fail('无企业信息')
         }
      })
    }else{
     navigate('/home')
    }
  }
  return (
    <div className="login">
      <div className="login_logo">
        <img src={require('../assets/imgs/logo2.png')} alt="图标"></img>
        <div className="logo_name">税邦登录</div>
      </div>
      <div className="login_main">
        <div className="login_input_box">
          <input className="login_input" value={phone} onChange={e => setPhone(e.target.value)} placeholder="请输入您的手机号码" maxLength={11} type="tel" />
          {phone && <Icon onClick={() => setPhone('')} name="mask-close" color="#909699" size={20}></Icon>}
        </div>
        {isPwd ?
          <div className="login_input_box">
            <input className="login_input" value={password} onChange={e => setPassword(e.target.value)} placeholder="请输入密码" type={isEye ? 'text' : 'password'} />
            <span className="login_eye" onClick={() => setIsEye(!isEye)}>
              {isEye ? <Icon name="eye" color="#26b7ff" size={20}></Icon> : <Icon name="marshalling" color="#909699" size={20}></Icon>}
            </span>
          </div>
          :
          <div className="login_input_box">
            <input className="login_input" value={msg} onChange={e => setMsg(e.target.value)} placeholder="请输入验证码" type="number" />
            <div className="login_get_code" onClick={onSendMsgCode} style={{ color: phone && phone.length === 11 ? '#333' : '#ccc' }}>
              {num === 60 ? <span> {isLoadingCode ? <Icon name="loading1"></Icon> : '获取验证码'}</span> : num + 's'}
            </div>
          </div>
        }
        <div className="login_type">
          <span onClick={() => setIsPwd(!isPwd)}>{isPwd ? '验证码登录' : '密码登录'}</span>
        </div>
        <Button loading={loading} type="info" size="large" onClick={handleSubmit}>登录</Button>
      </div>

      <Popup visible={ showCompanyList } position='bottom' onClose={ () => { setShowCompanyList(false) } }>
         {companyList.map((row,index)=>(
           <Cell key={row.id} onClick={()=>{chooseCompany(row,index)}} title={row.name}  subTitle={row.phone}></Cell>
         ))}
      </Popup>
    </div>
  );
};

export default Login;
