import { useNavigate } from 'react-router-dom';
import { dataUrl } from '../../config/utils';
import './product.less'
const Product =(props)=> {
   const productDetail=props.itemData;
   const navigate=useNavigate()
   const goDetail=(id)=>{
    window.location.href="https://zjzlsq.cn/project/hiCat/#/store/zlsqProductDetail/"+id
   }
    return (
      <div className="product-item" onClick={()=>goDetail(productDetail.ID)}>
          <img className="product-img" src={dataUrl+productDetail.picture}/>
          <div className="product-name">{productDetail.name}</div>
          <did className='product-price'>&yen;<span>{productDetail.price/100}</span></did>
      </div>
    )
}

export default Product
