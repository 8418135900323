import ajax from "../config/ajax";
import http from "../config/ajax";
import zlsqAPI from "../config/ajax-zlsq";

//登陆
export const API_accountLogin = (data) => ajax.post("/UserLogin/UserLogin", data);

//注册
export const API_register = (data) => ajax.post("/UserLogin/UserRegister", data);


//验证码登录
export const API_accountLoginByMsg = (data) => ajax.post("/UserLogin/ValidCodeLogin", data);
//获取验证码
export const API_getMsgCode = (data) => ajax.post("/UserLogin/GetValidCode", data);

//验证手机号
export const API_checkMsgCode = (data) => ajax.post("/UserLogin/ValidPhone", data);

//获取用户信息

export const API_getUserInfo = () => ajax.get("/UserLogin/UserGetMess");


//机构申请

export const API_organRegister = (data) => ajax.post("/UserLogin/OrganRegister", data);

//公司申请

export const API_companyRegister = (data) => ajax.post("/CompanyRegistration/CompanyRegistrationApply", data);


//审核

export const API_companyVerify = (data) => ajax.post('CompanyRegistration/Acceptance', data)

//获取公司列表

export const API_companyApplyList = (data) => ajax.get('CompanyRegistration/GetOrgans', data)



//申请发票

export const API_invoiceApply = (data) => ajax.post('ManagementOfInvoices/InvoicesApply', data)

//修改发票
export const API_updateInvoiceApply = (data) => ajax.post("ManagementOfInvoices/InvoicesUpdate", data);

//取消申请
export const API_cancelInvoiceApply = (data) => ajax.post("ManagementOfInvoices/InvoicesCancel", data);

//废弃发票
export const API_discardInvoiceApply = (data) => ajax.post("ManagementOfInvoices/InvoicesDiscardl", data);

//用户获取发票列表
export const API_getInvoiceList = (data) => ajax.get('ManagementOfInvoices/UserGetApplys', data)

//获取机构列表

export const API_organList = (data) => ajax.get('UserLogin/GetOrgans', data)


//获取发票申请列表

export const API_getInvoiceApplyList = (data) => ajax.get('ManagementOfInvoices/GetApplys', data)

//获取发票详情

export const API_getInvoiceApplyDetail = (data) => ajax.get('ManagementOfInvoices/GetDetail', data)

//发票受理

export const API_acceptInvoiceApply = (data) => ajax.post('ManagementOfInvoices/Acceptance', data)

//发票拒绝受理

export const API_refuseInvoiceApply = (data) => ajax.post('ManagementOfInvoices/Refuse', data)

//发票处置

export const API_handleInvoiceApply = (data) => ajax.post('ManagementOfInvoices/Handle', data)


//申请报账

export const API_reimbursementApply = (data) => ajax.post('Bookkeeping/Apply', data)


//用户获取报账列表

export const API_getReimbursementList = (data) => ajax.get("Bookkeeping/UserGetApplys", data)



//获取报账申请列表

export const API_getReimbursementApplyList = (data) => ajax.get('Bookkeeping/GetApplys', data)

//报账受理

export const API_acceptReimbursementApply = (data) => ajax.post('Bookkeeping/Acceptance', data)

//报账处置

export const API_handleReimbursementApply = (data) => ajax.post('Bookkeeping/Handle', data)


//获取新闻列表

export const API_getNews = (data) => ajax.get('NationalPolicy/Gets', data)


//获取消息列表

export const API_getMsgs = (data) => ajax.get('UserLogin/GetMessages', data)


//消息已读

export const API_readMsgs = (data) => ajax.post("UserLogin/MarkMessagesRead", data);


//预填公司

export const API_getCompanyList = (data) => ajax.get("UserLogin/UserGetRemarkCompanys", data);

export const API_addCompanyList = (data) => ajax.post("UserLogin/AddCompany", data);


export const API_editCompanyList = (data) => ajax.post("UserLogin/UpdateCompany", data);

export const API_deleteCompanyList = (data) => ajax.post("UserLogin/RemoveCompany", data);

//获取自己的机构信息
export const API_getMyOrgan = (data) => ajax.get("Organ/UserGetOrgan", data);

//获取申请列表
export const API_getCooperList = (data) => ajax.get("/Cooperative/GetInvitations", data);

//申请确认
export const API_approveInvite = (data) => ajax.post("Cooperative/InvitationHandle", data);

export const API_getContractList = (data) => http.get("contract/user", data);

export const API_addContract = (data) => ajax.post("contract", data)

export const API_editContract = (data) => ajax.put("contract", data)

export const API_deleteContract = (data) => ajax.delete("contract/"+data.id, data)

//获取合同详情
export const API_getContractDetail = (data) => ajax.get("contract/"+data.id, data)

//上传图片
export const API_uploadFile = (file) => http.upload("upload/FileUpLoad", file);

//合同签名提交
export const API_submitContractSign = (data) => ajax.post("ManagementOfInvoices/ContractSign", data);

export const API_userGetContracts = (data) => http.get("ManagementOfInvoices/UserGetContracts", data);

export const API_getTransactionList = (data) => http.get("transaction", data);

export const API_addTransaction = (data) => http.post("transaction", data);

export const API_updateTransaction = (data) => http.put("transaction", data);

export const API_removeTransaction = (data) => http.delete("transaction/"+data.id, {});

export const API_addBusinessList = (data) => http.http("ManagementOfInvoices/AddList", data);

// 绑定合同
export const API_AddContract = (data) => http.post("ManagementOfInvoices/AddContract", data);

// 获取现金流
export const API_GetCashFlowList = (data) => http.get("cash", data);

//获取现金流总额
export const API_GetCashFlowTotal = (data) => http.get("cash/totalmoney", data);

//获取现金详情

export const API_GetCashFlowDetail = (data) => http.get("cash/"+data.id, data);

// 添加现金流
export const API_AddCashFlow = (data) => http.post("cash", data);

//删除现金流

export const API_RemoveCashFlow = (data) => http.delete("cash/"+data.id, data);

//现金流确认

export const API_ConfirmCashFlow = (data) => http.post("cash/confirm", data);

//获取掌联分类

export const API_getClasses = (data) => zlsqAPI.get('/Industry/GetClasses', data)

export const API_geCategoryList = (data) =>zlsqAPI.get("/Category/GetDetail", data);

export const API_getProductList = (data) =>zlsqAPI.get("/Product/Search", data);


export const API_getGetSignature = (data) =>zlsqAPI.get("/WeiXinUser/GetSignature", data);

//获取员工列表

export const API_getStaffList = (data) => http.get("Wages/GetStaffs", data);

//更新员工

export const API_updateStaff = (data) => http.post("Wages/UpdateStaff", data);

//添加员工

export const API_addStaff = (data) => http.post("Wages/AddStaff", data);

//删除员工

export const API_removeStaff = (data) => http.post("Wages/RemoveStaff", data);


//获取员工详情

export const API_getStaffDetail = (data) => http.get("Wages/GetStaffDetail", data);

//提交签名

export const API_submitStaffContractSign = (data) => http.post("Wages/StafSign", data);



//员工获取自己的公司
export const API_getStaffCompany = (data) => http.get("OrganCustom", data);

//获取公司隐患列表
export const API_getCompanyPitfallList = (data) => http.get("OrganCustom/Pitfall", data);

//新增公司隐患
export const API_addCompanyPitfall = (data) => http.post("OrganCustom/Pitfall", data);

//编辑公司隐患
export const API_editCompanyPitfall = (data) => http.put("OrganCustom/Pitfall", data);

//删除公司隐患
export const API_removeCompanyPitfall = (data) => http.delete("OrganCustom/Pitfall/"+data.id, data);

//员工核查公司隐患
export const API_checkCompanyPitfall = (data) => http.post("OrganCustom/Pitfall/Check", data);

//客户核查公司隐患
export const API_customerCheckCompanyPitfall = (data) => http.post("OrganCustom/Pitfall/CustomerCheck", data);

//获取公司详情
export const API_getCompanyDetail = (data) => http.get("OrganCustom/custom", data);


//获取账号下子账户
export const API_getAccountSubAccount = (data) => http.get("UserLogin/GetSubUsers", data);

//子账号登录

export const API_subAccountLogin = (data) => http.post("UserLogin/SubUserLogin", data);

//获取签名参数
export const API_getSignature = (data) => zlsqAPI.get('/WeiXinUser/GetSignature', data)

//获取当前用户的机构用户信息

export const  API_getCurrentCustomer = () => http.get("organcustom/usercustom", {});

//导出拜访记录
export const API_exportVisit = (data) => http.get("OrganCustom/Visit/total", data);


//获取客户拜访记录
export const API_getVisitList = (data) => http.get("OrganCustom/Visit", data);

//新增公司隐患
export const API_addVisit = (data) => http.post("OrganCustom/Visit", data);

//编辑公司隐患
export const API_editVisit = (data) => http.put("OrganCustom/Visit", data);

//删除公司隐患
export const API_removeVisit = (data) => http.delete("OrganCustom/Visit/"+data.id, data);

//场地申请
export const API_registration=(data)=>http.post("park/enterprisesettleapply",data)

//员工添加公司信息
export const API_staffAddCompany=(data)=>http.post("organ/enterprise", data)

//员工编辑公司信息
export const API_staffEditCompany=(data)=>http.put("organ/enterprise", data)

//员工获取公司列表
export const API_staffGetCompanyList=(data)=>http.get("organ/enterprise", data)

//员工删除公司信息
export const API_staffRemoveCompany=(data)=>http.delete("organ/enterprise/"+data.id, data)