//http.ts
import axios from "axios";
import { Toast } from "@nutui/nutui-react";
const env=1;
// 设置请求头和请求路径
const instanceZlsq = axios.create({
  baseURL:process.env.REACT_APP_ZLSQ,
  timeout:10000
})
instanceZlsq.defaults.headers.post["Content-Type"] = "application/json;charset=UTF-8";
instanceZlsq.interceptors.request.use(
  (config) => {
    const token =localStorage.getItem("accessToken");
    if (token) {
      //@ts-ignore
      config.headers.accesstoken = token;
    }
    return config;
  },
  (error) => {
    return error;
  }
);
// 响应拦截
instanceZlsq.interceptors.response.use((res) => {
  console.log(res);
  if (res.data.status !=='OK') {
    Toast.fail(res.data.message);
    return Promise.reject(res)
  } 
  return res;
});

const http2 = {
  get(url, params) {
    return new Promise((resolve, reject) => {
      instanceZlsq
        .get(url, { params })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.data);
        });
    });
  },
  post(url, params) {
    return new Promise((resolve, reject) => {
      instanceZlsq
        .post(url, JSON.stringify(params))
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.data);
        });
    });
  },
  upload(url, file) {
    return new Promise((resolve, reject) => {
      instanceZlsq
        .post(url, file, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.data);
        });
    });
  },
  download(url) {
    const iframe = document.createElement("iframe");
    iframe.style.display = "none";
    iframe.src = url;
    iframe.onload = function () {
      document.body.removeChild(iframe);
    };
    document.body.appendChild(iframe);
  },
};
export default http2;
