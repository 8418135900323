/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react";
import {
  API_GetCashFlowList,
  API_AddCashFlow,
  API_userGetContracts,
  API_getInvoiceList,
  API_uploadFile,
  API_getStaffList,
  API_RemoveCashFlow,
  API_GetCashFlowTotal,
} from "../../service/common";
import Header from "../../components/header";
import {
  Button,
  Space,
  Popup,
  Tag,
  ImageUploader,
  Image,
} from "antd-mobile";
import InfiniteScroll from "react-infinite-scroll-component";
import FileFilter from "../../components/FileFilter";
import {
  RightOutline,
  FillinOutline,
  CheckCircleFill,
} from "antd-mobile-icons";
import { Cell, Dialog, Empty, Icon, ImagePreview, Popup as Popup2 ,  Toast} from "@nutui/nutui-react";
import NormalPicker from "../../components/normalPicker/normalPicker";
import { useParams } from "react-router-dom";

export default function CashflowList(props) {
  const [pageIndex, setPageIndex] = useState(1);
  const [cashFlowList, setCashFlowList] = useState([]);
  const [invoiceLoading, setInvoiceLoading] = useState(false);
  const [refreshHasMore, setRefreshHasMore] = useState(true);
  const [showCashFlow, setShowCashFlow] = useState(false);
  const [cashFlowInfo, setCashFlowInfo] = useState({});
  const [remark, setRemark] = useState("");
  const [showContract, setShowContract] = useState(false);
  const [contractList, setContractList] = useState([]);
  const [contractHeader, setContractHeader] = useState({});
  const [showInvoice, setShowInvoice] = useState(false);
  const [invoiceList, setInvoiceList] = useState([]);
  const [invoiceHeader, setInvoiceHeader] = useState({});
  const [linkList, setLinkList] = useState([]);
  const [staffList, setStaffList] = useState([]);
  const [chooseStaff, setChooseStaff] = useState({});
  const [showImage, setShowImage] = useState("");
  const [imagePreview, setImagePreview] = useState([]);
  const [totalMoney,setTotalMoney]=useState(0)
  const params=useParams()
  useEffect((e) => {
    API_getStaffList({ pagesize: 150 }).then((res) => {
      let list = res.data;
      list.map((item) => {
        item.text = item.staffName;
        item.value = item.staffId;
      });
      setStaffList(list);
    });
    API_GetCashFlowTotal({InvoicesId:params.id}).then(res=>{
      setTotalMoney(res.data)
    })
  }, []);
  useEffect(() => {
    onGetCashFlowList();
  }, [pageIndex]);
  const onGetCashFlowList = () => {
    setInvoiceLoading(true);
    API_GetCashFlowList({
      pageIndex,
      pageSize: 10,
      InvoicesId: params.id,
    }).then((res) => {
      let list = cashFlowList.concat(res.data);
      res.data.map(row=>{
          let images=row.paymentVoucher.split(',');
          let newList=[]
          images.map((item,index)=>{
            newList.push({src:item})
          })
          row.paymentVoucher=newList
      })

      setCashFlowList([...list]);
      setInvoiceLoading(false);
      if (res.data.length < 10) {
        setRefreshHasMore(false);
      }
    });
  };
  // 触底加载
  const onLoadCashFlowList = () => {
    if (invoiceLoading) {
      return;
    }
    setPageIndex(pageIndex + 1);
  };
  // 下拉刷新
  const onRefreshCashFlowList = () => {
    cashFlowList.length = 0;
    setCashFlowList(cashFlowList);
    if (pageIndex == 1) {
      onGetCashFlowList();
    }
    setPageIndex(1);
  };
  const onEditCashFlowInfo = async () => {
    Toast.loading('正在添加...')
    let list=[]
    linkList.map(row=>{
      list.push(row.url)
    })
    let reqData = {
      invoicesId: invoiceHeader.id,
      staffId: chooseStaff.value,
      header: remark,
      money: cashFlowInfo.money,
      paymentVoucher: list.join(','),
      id: cashFlowInfo.id,
    };
    if (cashFlowInfo.id) {
    } else {
      await API_AddCashFlow(reqData).then((res) =>
        Toast.success("添加完成")
      );
    }
    setShowCashFlow(false);
    onRefreshCashFlowList();
  };
  const onGetContractList = () => {
    if (contractList.length) {
      return;
    }
    API_userGetContracts({
      accept: 0,
      pageIndex: 1,
      pageSize: 99,
    }).then((res) => {
      setContractList(res.data);
    });
  };
  const onGetInvoiceList = () => {
    if (!contractHeader.contractId) {
      return;
    }
    API_getInvoiceList({
      pageIndex: 1,
      pageSize: 99,
      contractid: contractHeader.contractId,
    }).then((res) => {
      setInvoiceList(res.data);
    });
  };
  // 更改购货单位
  const onChangeContract = (data) => {
    if (contractHeader != data) {
      setInvoiceHeader({});
    }
    setContractHeader(data);
    setShowContract(false);
  };
  // 更改购货单位
  const onChangeInvoice = (data) => {
    setInvoiceHeader(data);
    setShowInvoice(false);
  };
  //
  const confirmPicker = (e) => {
    setChooseStaff(e);
  };
  //删除

  const del = (e) => {

    Dialog.alert({title:"提示",content:"是否确认删除该记录?",onOk:row=>{
      Toast.loading('正在删除...')
        API_RemoveCashFlow({ id: e.id }).then((res) => {
            Toast.success("删除成功");
          onRefreshCashFlowList();
        });
    }})

  };

  return (
    <>
      <Header title="转账记录列表"></Header>
      <div className="costom_auto_area">
        <Cell title={`总金额:${totalMoney}元`}></Cell>
        <div className="costom_auto_height padlr30 ">
          <InfiniteScroll
            dataLength={cashFlowList.length}
            hasMore={refreshHasMore}
            endMessage={
              <div className="font14 minor align_c padtb30">没有更多了</div>
            }
            loader={
              <div className="font14 minor align_c padtb30">Loading...</div>
            }
            next={onLoadCashFlowList}
          >
            {cashFlowList.map((item) => (
              <div className="costom_card" key={item.id}>
                <div className="l_flex_xbyc pad30">
                  <div className="l_flex_xbyc">
                    <div className="font15 blod major padr10">
                      发票编号：{item.invoicesId}
                    </div>
                  </div>
                  {/* <div onClick={(e) => del(item)}>
                    <Icon name="del" color="#fa2c19"></Icon>
                  </div> */}
                </div>

                <div className="invo_info" style={{ paddingBottom: "12px" }}>
                  <div className="l_flex_xbyc">
                    <div className="minor">转账对象</div>
                    <div className="line_1 custom_label">{item.staffName}</div>
                  </div>
                  <div className="l_flex_xbyc">
                    <div className="minor">转账说明</div>
                    <div className="line_1 custom_label">{item.header}</div>
                  </div>
                  <div className="l_flex_xbyc">
                    <div className="minor">转账金额</div>
                    <div className="custom_label">¥{item.money}</div>
                  </div>
                  <div className="l_flex_xbyc">
                    <div className="minor">转账截图</div>
                    <div className="custom_label">
                      {item.paymentVoucher ? (
                        <span
                          style={{
                            color: "#409eff",
                            textDecoration: "underLine",
                          }}
                          onClick={(e) => {
                            console.log(item.paymentVoucher)
                            setImagePreview(item.paymentVoucher);
                            setShowImage(true);
                          }}
                        >
                          点击查看
                        </span>
                      ) : (
                        "无"
                      )}
                    </div>
                  </div>
                  <div className="l_flex_xbyc">
                    <div className="minor">添加时间</div>
                    <div className="line_1 custom_label">{item.createTime}</div>
                  </div>
                  {/* <div className="l_flex_xbyc">
                    <div className="minor">转账状态</div>
                    <div className="line_1 custom_label">{item.status}</div>
                  </div> */}
                  {/* {item.confirmExplain&&     <div className="l_flex_xbyc">
                    <div className="minor">确认时间</div>
                    <div className="line_1 custom_label">{item.confirmExplain}</div>
                  </div>} */}
                </div>
              </div>
            ))}
          </InfiniteScroll>
        </div>
        {/* <div className="bottom_area">
          <div className="bottom_fixed_btn">
            <Button
              onClick={() => setShowCashFlow(true)}
              color="primary"
              shape="rounded"
              block
            >
              添加转账记录
            </Button>
          </div>
        </div> */}
      </div>
      <Popup
        visible={showCashFlow}
        onClose={() => setShowCashFlow(false)}
        afterClose={() => {
          setCashFlowInfo({});
          setContractHeader("");
          setInvoiceHeader("");
        }}
        bodyStyle={{
          borderTopLeftRadius: "12px",
          borderTopRightRadius: "12px",
        }}
        closeOnMaskClick
        showCloseButton
      >
        <div className="pad30 blod align_c font15 gap_b_1">业务信息</div>
        <div
          className="l_flex_xbyc pad30 font14"
          onClick={() => setShowContract(true)}
        >
          <div>合同抬头</div>
          <div className="l_flex_xbyc minor">
            {contractHeader.header || "选择合同"}
            <RightOutline />
          </div>
        </div>
        <div
          className="l_flex_xbyc pad30 font14"
          onClick={() => setShowInvoice(true)}
        >
          <div>关联发票</div>
          <div className="l_flex_xbyc minor">
            {invoiceHeader.header || "选择关联发票"}
            <RightOutline />
          </div>
        </div>
        <NormalPicker
          confirmPicker={(e) => {
            confirmPicker(e);
          }}
          title="员工"
          listData={staffList}
        ></NormalPicker>
        {chooseStaff.staffId && (
          <div className="l_flex_xbyc pad30 font14">
            <div>员工收款码</div>
            <div className="l_flex_xbyc minor">
              <Image
                onClick={(e) => {
                  setImagePreview(chooseStaff.staffPaymentCode);
                  setShowImage(true);
                }}
                width="50px"
                height="50px"
                src={chooseStaff.staffPaymentCode}
              />
            </div>
          </div>
        )}
        <div className="l_flex_xbyc pad30 font14">
          <div>金额</div>
          <div className="l_flex_xbyc minor">
            <input
              value={cashFlowInfo.money || ""}
              className="cash_input font15"
              placeholder="请输入金额"
              type="number"
              onChange={(e) => {
                setCashFlowInfo({ ...cashFlowInfo, money: e.target.value });
              }}
            ></input>
          </div>
        </div>
        <div className="l_flex_xbyc pad30 font14">
          <div>说明</div>
          <div className="l_flex_xbyc minor">
            <input
              value={remark}
              className="cash_input font15"
              placeholder="请输入金额说明"
              type="text"
              onChange={(e) => {
                setRemark(e.target.value);
              }}
            ></input>
          </div>
        </div>
        <div className="font14 pad30">转账截图</div>
        <div className="padlr30">
          <ImageUploader
            defaultValue={linkList}
            maxCount={10}
            value={linkList}
            columns={4}
            preview
            upload={async (file) => {
              return {
                url: await API_uploadFile({ file }).then((res) => res.data),
              };
            }}
            onChange={(files) => setLinkList([...linkList.push(files)])}
          />
        </div>
        <div className="pad30">
          <Button
            onClick={onEditCashFlowInfo}
            shape="rounded"
            color="primary"
            block
          >
            确认
          </Button>
        </div>
      </Popup>
      <Popup
        visible={showContract}
        afterShow={onGetContractList}
        onClose={() => setShowContract(false)}
        position="bottom"
        round
      >
        <div className="costom_auto_area" style={{ height: "70vh" }}>
          <div className="pad30 font15 rel tc">
            合同列表
            <div
              className="costom_confi"
              onClick={() => setShowContract(false)}
            >
              确认
            </div>
          </div>
          <div className="costom_auto_height gap_b_1 padlr30">
            {contractList.map((data) => (
              <div
                className="flex_align_c costom_card"
                onClick={() => onChangeContract(data)}
                key={data.contractId}
              >
                <div className="padl30">
                  {data.contractId === cashFlowInfo.contractId ? (
                    <CheckCircleFill color="#1677ff" fontSize={20} />
                  ) : (
                    <div className="costom_radio_icon"></div>
                  )}
                </div>
                <div className="pad30 flex1">
                  <div className="l_flex_xbyc">
                    <div className="blod comer font15 line_1 major padr30 flex1">
                      {data.header}
                    </div>
                    <Tag color={data.isCanSeekContract ? "#409EFF" : "#F56C6C"}>
                      {data.isCanSeekContract ? "公开" : "私有"}
                    </Tag>
                  </div>
                  <div className="minor font13 padt10">{data.createTime}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Popup>
      <Popup
        visible={showInvoice}
        afterShow={onGetInvoiceList}
        onClose={() => setShowInvoice(false)}
        position="bottom"
        round
      >
        <div className="costom_auto_area" style={{ height: "70vh" }}>
          <div className="pad30 font15 rel tc">
            发票列表
            <div className="costom_confi" onClick={() => setShowInvoice(false)}>
              确认
            </div>
          </div>
          <div className="costom_auto_height gap_b_1 padlr30">
            {invoiceList.map((data) => (
              <div
                className="flex_align_c costom_card"
                onClick={() => onChangeInvoice(data)}
                key={data.id}
              >
                <div className="padl30">
                  {data.id === cashFlowInfo.invoicesId ? (
                    <CheckCircleFill color="#1677ff" fontSize={20} />
                  ) : (
                    <div className="costom_radio_icon"></div>
                  )}
                </div>
                <div className="pad30 flex1">
                  <div
                    className="blod comer font15 line_1 major"
                    style={{ width: "calc(100vw - 90px)" }}
                  >
                    {data.header}
                  </div>
                  <div className="font13 peony padt10">
                    发票金额：¥{data.money}
                  </div>
                  <div className="minor font13 padt10">{data.createTime}</div>
                </div>
              </div>
            ))}
            {invoiceList.length == 0 && (
              <Empty style={{ backgroundColor: "transparent" }}></Empty>
            )}
          </div>
        </div>
      </Popup>
      <ImagePreview images={imagePreview} show={showImage} onClose={e=>setShowImage(false)}></ImagePreview>
    </>
  );
}
