import Header from "../../components/header";
import { useEffect, useState } from "react";
import {
  API_getCompanyDetail,
  API_getCompanyPitfallList,
} from "../../service/common";
import { useParams } from "react-router-dom";
import { Button, Cell, Tag, Toast } from "@nutui/nutui-react";
import "./exportFile.less";
import pdfMake, { fonts } from 'pdfmake/build/pdfmake';  
var docDefinition,companyInfoVar;
const ExportFile = (props) => {
  const userType=localStorage.getItem('userType');
  const params = useParams();
  const [companyInfo, setCompanyInfo] = useState({});
  const [pitfallList, setPitfallList] = useState([[], [], []]);

  useEffect(async e=>{
    if(pdfMake){
      let pitfallListVar,checkDateVar;
      companyInfoVar=JSON.parse(localStorage.getItem('companyInfo'))
      setCompanyInfo(companyInfoVar)
      let reqData = {
        customId: params.companyId,
        PageIndex: 1,
        PageSize: 99
      };
      await API_getCompanyPitfallList(reqData).then((res) => {
        let list = [[], [], []];
        res.data.map((item, index) => {
          if (index == 0) {
            checkDateVar=item.checkDate.split(" ")[0];
          }
          if (item.type == "重要") {
            list[0].push(item);
          } else if (item.type == "一般") {
            list[1].push(item);
          } else if (item.type == "小微") {
            list[2].push(item);
          }
        });
        setPitfallList(list);
        
        pitfallListVar=list.flat(1)
      });

      let tableList=[];
      let imagesData={};
      let type={}
      pitfallListVar.map(async (row,index)=>{
        type={text:row.type,alignment: 'center'}
        let images=[];
        row.images.map(data=>{
          imagesData[data]=data;
          images.push({image:data,width:100,height:100})
        })
        let pitfall={  
          // 紧邻图片的文本内容  
          text: row.pitfall
        } 
        let checkAndAcceptDescription='';
        let checkImages=[];
        row.checkAndAcceptImages.map(data=>{
          imagesData[data]=data;
          checkImages.push({image:data,width:100,height:100})
        })
        checkAndAcceptDescription={  
          // 紧邻图片的文本内容  
          text: row.checkAndAccept?'已验收':''
        } 
        let checkAndAcceptTime=row.checkAndAcceptPerson?row.checkAndAcceptTime:''
        tableList.push([{text:index+1,alignment: 'center'},type,pitfall,row.rectification,images,checkAndAcceptTime,checkAndAcceptDescription,checkImages])
      })
      tableList.unshift( ['序号','隐患等级', '隐患描述', '整改要求','隐患照片','整改时间','完成情况','整改照片'])
      
      pdfMake.fonts = {
          "SourceHanSans.ttf": {
              normal: 'https://files.zone.zjzlsq.cn/File/20240706/ 3a345aa0851d4028a6dcbcadb5cd4655.ttf'
          }
      }
     docDefinition = {
        pageBreakBefore: false,
        pageOrientation: 'landscape',
        pageSize: 'A4',
        content: [
            {
                text:"税邦安全",
                fontSize:18,
                alignment:'left'
            },
            {
                text: userType==5?"安全隐患整改报告":"安全隐患排查报告",
                alignment: 'center',
                marginTop:100,
                fontSize:24
            },
            {  
                // 创建一个足够宽的 columns 容器来模拟整体居中  
                columns: [  
                    {  
                        stack: [  
                            // 这里是左对齐的文本  
                            { text: ` 企业名称: ${companyInfoVar ? companyInfoVar?.information : ""}`, style: 'textStyle' },  
                            { text: '服务机构: 税邦安全管理', style: 'textStyle' },  
                            { text: `检查日期: ${checkDateVar}`, style: 'textStyle' },  
                        ],  
                        marginTop:200,
                        marginBottom:200
                    }  
                ],  
          
            }  ,
            {  
                table: {  
                    // 表格头  
                  
                    // 表格体数据  
                    body: tableList,  
                    // 如果需要，可以定义列宽  
                    widths: [30,60,  '*', '*', '*', '*', 60, '*'],
                },  
                layout: {  
                    // 这里可以定义表格的布局样式，如边距、填充等  
                    // ...  
                }  
            }  ,
            {
                text: `点击这里查看详情,或者上传整改照片`,
                link:`https://mobile.taxhelppark.zjzlsq.cn/#/pitfall/pitfallList/${companyInfoVar.id}`,
                alignment: 'left',
                marginTop:50,
                color:'blue'
            },
            {
                text:userType==5?'':`机构检查人: ${localStorage.getItem('checkPeople2')}`,
                alignment: 'left',
                marginTop:20,
            },
            {
                text:userType==5?'':`企业检查人: ${localStorage.getItem('checkPeople')}`,
                alignment: 'left'
            }
        ],
        // 默认字体，默认的样式，可以在这里设置全局样式
        defaultStyle: {
            font: 'SourceHanSans.ttf',
            fontSize: 12,
            alignment:'center'
        },
        // 内容边距，不设置就会使用默认值
        pageMargins: [20, 30, 20, 30],
        // 图片引用
        images:imagesData,
        // styles 就像是css样式一样 可以在content内元素引用的
        styles: {
            head: {
                margin: [0, 5, 0, 15],
                fontSize: 24,
                color:'#000'
            },
            textStyle:{
              fontSize:18,
            }
        },
    }
      
    }
  },[pdfMake])

  const download = (url) => {
    Toast.loading('正在导出...',{duration:0})
    pdfMake.createPdf(docDefinition).getBuffer(function(buffer) {  
        var blob = new Blob([buffer], { type: 'application/pdf' });  
        var url = URL.createObjectURL(blob);  

        // 创建一个临时的a标签用于下载  
        var a = document.createElement('a');  
        a.href = url;  
        a.download = companyInfoVar.information + `隐患${userType==5?'整改':'排查'}表.pdf`; // 设置下载的文件名  
        document.body.appendChild(a);  
        a.click();  

        // 清理  
        document.body.removeChild(a);  
        URL.revokeObjectURL(url);  
    });
  };

  return (
    <div className="export-content">
      <Header title="导出PDF"></Header>
      <div style={{marginTop:'20px'}}>
        {pitfallList.map((list, index) => {
          return (
            <div>
              <div style={{padding:'0 20px'}}><Tag
                type={
                  index == "2" ? "primary" : index == "1" ? "warning" : "danger"
                }
              >
                {index == "2" ? "小微" : index == "1" ? "一般" : "重要"}隐患
              </Tag></div>
              {list.map((item, i) => {
                return <Cell title={item.pitfall} subTitle={item.rectification}></Cell>
              })}
            </div>
          );
        })}
      </div>

      {companyInfo && (
        <div className="footer-btn-fix">
          <div className="warp"></div>
          <Button
            className="button"
            onClick={(e) => download()}
            type="info"
            block
          >
            导出
          </Button>
        </div>
      )}
    </div>
  );
};

export default ExportFile;
