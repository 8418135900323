const CompanyItem =(props)=> {
    return (
      <div className="company-item">
        <div className="company-name">{props.enterprise}</div>
        <div className='company-contact'>联系人: {props.contact}</div>
        <div className='company-phone'>电话: <a href={'tel:'+props.phone}>{props.phone}</a></div>
        <div className='company-address'>地址: {props.address}</div>
        <div className='company-address'>员工: {props.staffName}</div>
      </div>
    )
}

export default CompanyItem
