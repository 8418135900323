import { useEffect, useState } from "react";
import { API_staffAddCompany, API_staffEditCompany, API_staffGetCompanyList, API_staffRemoveCompany } from "../../service/common";
import {
  Button,
  Cell,
  Dialog,
  Form,
  Icon,
  Infiniteloading,
  Input,
  Popup,
  TextArea,
  Toast,
} from "@nutui/nutui-react";
import Header from "../../components/header";
import { useNavigate } from "react-router-dom";
import CompanyItem from "../../components/companyItem/companyItem";
import { isWechatBrowser } from "../../config/utils";
var chooseIndex=-1;

const StaffCompany = (props) => {
  const userType = localStorage.getItem("userType");
  const [pageIndex, setPageIndex] = useState(1);
  const navigate = useNavigate();
  const [hasMore, setHasMore] = useState(true);
  const [list, setList] = useState([]);
  const [showBottom, setShowBottom] = useState(false);
  const [form] = Form.useForm();
  const loadMore = (first) => {
    let reqData = {
      PageIndex: pageIndex,
      PageSize: 20,
    };
    API_staffGetCompanyList(reqData).then((res) => {
      if(pageIndex==1){
        setList(res.data);
      }else{
        setList(prevList => [...prevList, ...res.data]);  
      }
      if (res.data.length < 20) {
        setHasMore(false);
      }
    });
  };
  useEffect((e) => {
    window.scrollTo(0, 0);
  }, []);

  useEffect((e) => {
      if(hasMore){
        loadMore();
      }
  }, [pageIndex,hasMore]);

  const goVisitList = (item) => {
    localStorage.setItem("companyInfo", JSON.stringify(item));
    navigate("/staffMenu/visit");
  };

  const submitSucceed = () => {
    let reqData={
      enterprise:form.getFieldValue("enterprise"),
      address:form.getFieldValue("address"),
      contact:{
        name:form.getFieldValue("name"),
        tel:form.getFieldValue("phone")
      },
      point:[120,30],
      images:[]
    }

    if(chooseIndex!=-1){
      reqData.id=list[chooseIndex].id;
      API_staffEditCompany(reqData).then((res) => {
        Toast.success('修改成功')
        list[chooseIndex]=reqData;
        setList([...list])
        setShowBottom(false)
        form.resetFields();
      });
    }else{
      API_staffAddCompany(reqData).then((res) => {
        Toast.success('添加成功')
        setShowBottom(false)
        form.resetFields();
        setHasMore(true);
        pageIndex=1;
        setList([]);
        loadMore(true);
      });
    }
  
  
  };

  const remove =(item,index)=>{
    Dialog.alert({
       title: "提示",
       content: "是否删除该公司?",
       onOk: () => {
         let reqData = {
           id: item.id,
         };
         API_staffRemoveCompany(reqData).then((res) => {
           list.splice(index, 1);
           setList([...list]);
         });
       },
     });
  }

  const edit=(item,index)=>{
    chooseIndex=index;
    let row={...item}
    row.name=row?.contact?.name
    row.phone=row?.contact?.tel
    form.setFieldsValue(row)
    setShowBottom(true)
  }

  const [showDownload,setShowDownload]=useState(false)

  const goExport= ()=>{
    if(isWechatBrowser()){
      setShowDownload(true)
      // Dialog.alert({title:'提示',content:"微信浏览器无法使用导出功能,请从右上角在默认浏览器打开!", noCancelBtn: true})
      return;
    }
    navigate('/staffMenu/exportVisit')
  }


  return (
    <div className="home_page">
      <Header title="公司列表"></Header>
      <div style={{textAlign:'center',fontSize:'16px',padding:'15px 0',backgroundColor:'#fff',marginBottom:'15px'}} onClick={e=>goVisitList({})}>查看所有拜访记录</div>
      <div id="scroll">
        <Infiniteloading
          hasMore={hasMore}
          onLoadMore={e=>setPageIndex(pageIndex+1)}
        >
          {list.map((item, index) => {
            return (
              <div>
                 <CompanyItem staffName={item.staffName} enterprise={item.enterprise} address={item.address} contact={item?.contact?.name} phone={item?.contact?.tel}></CompanyItem>
                 <div className="item-btn flex-end">
                 <Button type="info" onClick={e=>goVisitList(item)}>添加/查看拜访记录</Button>
                      {userType==3&&<>
                       <Button  plain  type="primary" onClick={e=>remove(item,index)}>删除</Button>
                       <Button  plain  type="info" onClick={e=>edit(item,index)}>编辑</Button>
                      </>}
                      
                 </div>
              </div>
            );
          })}
        </Infiniteloading>
      </div>
      <div className="footer-btn-fix">
        <div className="warp"></div>
        <div className="btns">
        <Button
            className="button"
            style={{ width: userType==4?"100%":"45%" }}
            type="info"
            plain
            block
            onClick={goExport}
          >
            导出PDF
          </Button>
          {userType==3&&<Button
            className="button"
            style={{ width: "45%" }}
            type="info"
            block
            onClick={(e) => {
              setShowBottom(true);
            }}
          >
            新增公司
          </Button>}
        </div>
      </div>
      <Popup
        destroyOnClose
        visible={showBottom}
        style={{}}
        position="bottom"
        onClose={() => {
          setShowBottom(false);
        }}
      >
        <Form form={form}>
          <Form.Item
            label="企业名称"
            name="enterprise"
            rules={[{ required: true, message: "请输入企业名称" }]}
          >
            <Input placeholder="请输入企业名称" />
          </Form.Item>
          <Form.Item
            label="企业地址"
            name="address"
            rules={[{ required: true, message: "请输入企业地址" }]}
          >
            <Input placeholder="请输入企业地址" />
          </Form.Item>
          <Form.Item
            label="企业负责人"
            name="name"
            rules={[{ required: true, message: "请输入企业负责人" }]}
          >
            <Input placeholder="请输入企业负责人" />
          </Form.Item>
          <Form.Item
            label="联系电话"
            name="phone"
            rules={[{ required: true, message: "请输入联系电话" }]}
          >
               <Input type='number' placeholder="请输入联系电话" />
          </Form.Item>
          <div className="footer-btn">
            <Button type="info" block onClick={submitSucceed}>
              保存
            </Button>
          </div>
        </Form>
      </Popup>
      <Popup   height='100%' style={{backgroundColor:'#000'}}  visible={showDownload} position="top">
         <img  src={require('../../assets/imgs/download.png')} style={{width:'100%'}}></img>
      </Popup>
    </div>
  );
};

export default StaffCompany;
