import InvoiceTemplate from "../../components/invoiceTemplate/invoiceTemplate"
import Header from "../../components/header"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { API_getInvoiceApplyDetail } from "../../service/common"

/**
 * 发票信息
 * @param {string} id 发票id 
 * @returns 
 */
const InvoicePage = () => {
  const params = useParams()
  const [invoiceDetail, setInvoiceDetail] = useState({})
  useEffect(() => {
    if (params.id !== '0') {
      API_getInvoiceApplyDetail({
        id: params.id
      }).then(res => {
        setInvoiceDetail(res.data)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div className="page-color">
      <Header title="发票申请"></Header>
      <InvoiceTemplate invoiceDetail={invoiceDetail}></InvoiceTemplate>
    </div>
  )
}

export default InvoicePage
