import { useEffect, useState } from "react";
import {
  API_addCompanyPitfall,
  API_checkCompanyPitfall,
  API_customerCheckCompanyPitfall,
  API_editCompanyPitfall,
  API_getCompanyDetail,
  API_getCompanyPitfallList,
  API_getCurrentCustomer,
  API_getUserInfo,
  API_removeCompanyPitfall,
  API_uploadFile,
} from "../../service/common";
import {
  Button,
  Dialog,
  Form,
  Infiniteloading,
  Input,
  Popup,
  Radio,
  Tabs,
  TextArea,
  Toast,
  Uploader,
} from "@nutui/nutui-react";
import Header from "../../components/header";
import { useNavigate, useParams } from "react-router-dom";
import PitfallItem from "../../components/pitfallItem/pitfallItem";
import { getDistanceFromLatLonInMeters, isWechatBrowser } from "../../config/utils";
import { ImageUploader } from "antd-mobile";

const dayjs = require("dayjs");
const PitfallList = (props) => {
  const [hasMore, setHasMore] = useState(true);
  const [form] = Form.useForm();
  const [checkForm] = Form.useForm();
  const [list, setList] = useState([]);
  const [type, setType] = useState("全部");
  const [showBottom, setShowBottom] = useState(false);
  const [showBottom2, setShowBottom2] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const [imageList, setImageList] = useState([]);
  const [lngLat, setLngLat] = useState([]);
  const [distance, setDistance] = useState("");
  const [userType, setUserType] = useState(false);
  const [companyInfo, setCompanyInfo] = useState({});
  const [itemData, setItemData] = useState({});
  const [editIndex,setEditIndex]=useState(0)
  const [pageIndex,setPageIndex]=useState(1)
  const loadMore = (more) => {
    if (!hasMore && !more) {
      return;
    }
    let reqData = {
      PageIndex: pageIndex,
      PageSize: 10,
      CheckAndAccept: false,
      customId: params.companyId,
    };
    if (type == "已整改") {
      reqData.CheckAndAccept = true;
    } else if (type != "全部") {
      reqData.Type = type;
    }
    API_getCompanyPitfallList(reqData).then((res) => {
      if (pageIndex==1) {
        setList(res.data);
      } else {
        setList(list.concat(res.data));
      }
      if (res.data.length < 10) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
    });
  };
  const firstLoad =()=>{
    setHasMore(true);
    setList([]);
    loadMore(true);
  }

  useEffect(
    (e) => {
      if(!hasMore) return;
      firstLoad()
    },
    [type,pageIndex,hasMore]
  );



  useEffect((e) => {
    window.scrollTo(0, 0);
    if (localStorage.getItem("lng")) {
      setLngLat([localStorage.getItem("lng"), localStorage.getItem("lat")]);
    }

    API_getUserInfo().then((res) => {
      setUserType(res.data.userType);
      if (res.data.userType == 3) {
        API_getCompanyDetail({ customuserid: params.companyId }).then((res) => {
          setCompanyInfo(res.data);
          localStorage.setItem('companyInfo',JSON.stringify(res.data))
        });
      } else {
        setCompanyInfo(JSON.parse(localStorage.getItem("companyInfo")) || {});
        return;
      }
      let AMap = window.AMap;
      console.log(AMap);
      AMap.plugin("AMap.Geolocation", function () {
        var geolocation = new AMap.Geolocation({
          // 是否使用高精度定位，默认：true
          enableHighAccuracy: true,
          // 设置定位超时时间，默认：无穷大
          timeout: 10000,
          // 定位按钮的停靠位置的偏移量，默认：Pixel(10, 20)
          buttonOffset: new AMap.Pixel(10, 20),
          //  定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
          zoomToAccuracy: true,
          //  定位按钮的排放位置,  RB表示右下
          buttonPosition: "RB",
        });

        geolocation.getCurrentPosition();
        AMap.event.addListener(geolocation, "complete", onComplete);
        AMap.event.addListener(geolocation, "error", onError);

        function onComplete(data) {
          const { lng, lat } = data.position;
          setLngLat([lng, lat]);
          localStorage.setItem("lng", lng);
          localStorage.setItem("lat", lat);
          if (localStorage.getItem("companyPoint")) {
            let companyPoint = localStorage.getItem("companyPoint").split(",");
            let meters = getDistanceFromLatLonInMeters(
              companyPoint[1],
              companyPoint[0],
              lat,
              lng
            );
            setDistance(Math.floor(meters));
          }
        }

        function onError(data) {
          Toast.fail(JSON.stringify(data));
          console.log(data, "e");
          // 定位出错
        }
      });
    });
  }, []);

  const submitSucceed = (e) => {
    if(lngLat.length<2){
      Toast.fail("当前无法定位,请重新定位后尝试")
      return
    }
    let reqData = {
      type: form.getFieldValue("type"),
      pitfall: form.getFieldValue("pitfall"),
      rectification: form.getFieldValue("rectification"),
      checkDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      checkPersons: ["机构员工"],
      images: imageList,
      point: lngLat,
    };
    if (!itemData.id) {
      reqData.customId = params.companyId;
      API_addCompanyPitfall(reqData).then((res) => {
        firstLoad()
        setShowBottom(false)
      });
    } else {
      reqData.id = itemData.id;
      API_editCompanyPitfall(reqData).then((res) => {
          let object=Object.assign(list[editIndex],reqData);
          list[editIndex]=object;
          setList([...list])
          setShowBottom(false)
      });
    }
  };

  const changeUpload = async (e) => {
    const file = e;
    let image = null;
    await API_uploadFile({ file: file }).then((res) => {
      imageList.push(res.data);
      setImageList([...imageList]);
      image = res.data;
    });
    return { url: image };
  };
  const remove = (e) => {
    let index = imageList.indexOf(e.url);
    imageList.splice(index, 1);
    setImageList([...imageList]);
  };

  const done = (item, index) => {
    Dialog.alert({
      title: "提示",
      content: "是否确认该隐患已整改?",
      onOk: () => {
        let reqData = {
          id: item.id,
          checkAndAccept: true,
        };
        API_checkCompanyPitfall(reqData).then((res) => {
          list.splice(index, 1);
          setList([...list]);
        });
      },
    });
  };
  const clearFormData = () => {
    form.resetFields({});
    checkForm.resetFields({});
    setImageList([]);
    setItemData({});
    setDefaultImages([]);
  };

  const removeItem = (item, index) => {
    Dialog.alert({
      title: "提示",
      content: "是否删除该隐患?",
      onOk: () => {
        let reqData = {
          id: item.id,
        };
        API_removeCompanyPitfall(reqData).then((res) => {
          list.splice(index, 1);
          setList([...list]);
        });
      },
    });
  };
  const [defaultImages, setDefaultImages] = useState([]);
  const editItem = (data, index) => {
    setEditIndex(index)
    clearFormData();
    let item = { ...data };
    setItemData(item);
    form.setFieldsValue(item);
    setImageList([...item.images]);
    let list = [];
    item.images.map((data) => {
      list.push({ url: data });
    });
    setDefaultImages(list);
    setShowBottom(true);
  };
  var checkPeople = "";
  var checkPeople2 = localStorage.getItem("checkPeople2");
  const goExport = () => {
    if(isWechatBrowser()){
      setShowDownload(true)
      // Dialog.alert({title:'提示',content:"微信浏览器无法使用导出功能,请从右上角在默认浏览器打开!", noCancelBtn: true})
      return;
    }
    Dialog.alert({
      title: "请填写检查人",
      content: (
        <>
          <Input
            defaultValue={checkPeople2}
            onChange={(e) => (checkPeople2 = e)}
            placeholder="请填写机构检查人"
          ></Input>
          <Input
            onChange={(e) => (checkPeople = e)}
            placeholder="请填写企业检查人"
          ></Input>
        </>
      ),
      onOk: () => {
        if (checkPeople&&checkPeople2) {
          localStorage.setItem("checkPeople", checkPeople);
          localStorage.setItem("checkPeople2", checkPeople2);
          navigate("/pitfall/export/" + params.companyId);
        } else {
          Toast.fail("请填写检查人");
        }
      },
    });
  };
  const [showDownload,setShowDownload]=useState(false)

  const customerGoExport= ()=>{
    if(isWechatBrowser()){
      setShowDownload(true)
      // Dialog.alert({title:'提示',content:"微信浏览器无法使用导出功能,请从右上角在默认浏览器打开!", noCancelBtn: true})
      return;
    }
    navigate("/pitfall/export/" + params.companyId);
  }

  const logout = () => {
    localStorage.clear();
    navigate("/");
  };

  const customerCheck = (item,index) => {
    setEditIndex(index)
    clearFormData();
    checkForm.setFieldsValue(item);
    let list = [];
    item.checkAndAcceptImages.map((data) => {
      list.push({ url: data });
    });
    setDefaultImages(list);
    setImageList(item.checkAndAcceptImages);
    setShowBottom2(true);
  };

  const submitCheck = () => {
    let reqData = {
      id: checkForm.getFieldValue("id"),
      checkAndAcceptDescription: checkForm.getFieldValue(
        "checkAndAcceptDescription"
      ),
      checkAndAcceptImages: imageList,
      checkAndAcceptPerson: "企业员工",
    };
    
    API_customerCheckCompanyPitfall(reqData).then((res) => {
      let object=Object.assign(list[editIndex],reqData);
      list[editIndex]=object;
      setList([...list])
      setShowBottom2(false);
    });
  };

  return (
    <div className="main-page">
      <Header
        title={`${companyInfo.information}${
          userType == 3 ? `(距离:${distance}米)` : ""
        }`}
        right={<div onClick={logout}>退出登录</div>}
      ></Header>
      <Tabs
        value={type}
        tabStyle={{ position: "sticky", top: "44px", zIndex: 1 }}
        onChange={({ paneKey }) => {
          setType(paneKey);
        }}
        type="smile"
      >
        <Tabs.TabPane title="全部隐患" paneKey="全部">
          {" "}
        </Tabs.TabPane>
        <Tabs.TabPane title="重要" paneKey="重要">
          {" "}
        </Tabs.TabPane>
        <Tabs.TabPane title="一般" paneKey="一般">
          {" "}
        </Tabs.TabPane>
        <Tabs.TabPane title="小微" paneKey="小微">
          {" "}
        </Tabs.TabPane>
        <Tabs.TabPane title="已整改" paneKey="已整改">
          {" "}
        </Tabs.TabPane>
      </Tabs>
      <div id="scroll">
        <Infiniteloading
          hasMore={hasMore}
          onLoadMore={(e) => setPageIndex(e=>e+1)}
        >
          {list.map((item, index) => {
            return (
              <div style={{ backgroundColor: "#fff" }}>
                <PitfallItem
                  images={item.images}
                  title={item.pitfall}
                  rectification={item.rectification}
                  type={item.type}
                  checkDate={item.checkDate}
                  item={item}
                ></PitfallItem>
                {item.checkAndAccept ? (
                  <div className="pit-btn">
                    <Button disabled type="default">
                      整改已完成
                    </Button>
                  </div>
                ) : (
                  <div className="pit-btn">
                    {userType == 3 && (
                      <>
                        {item.checkAndAcceptPerson ? (
                          <Button
                            onClick={(e) => done(item)}
                            style={{ marginRight: "15px" }}
                            type="success"
                          >
                            确认整改
                          </Button>
                        ) : (
                          <>
                            <Button
                              style={{ marginRight: "15px" }}
                              onClick={(e) => {
                                removeItem(item, index);
                              }}
                              type="primary"
                              icon="del2"
                            >
                              删除
                            </Button>
                            <Button
                              style={{ marginRight: "15px" }}
                              onClick={(e) => {
                                editItem(item, index);
                              }}
                              type="info"
                              icon="edit"
                            >
                              编辑
                            </Button>
                          </>
                        )}
                      </>
                    )}
                    {userType == 5 && (
                      <Button
                        onClick={(e) => customerCheck(item,index)}
                        style={{ marginRight: "15px" }}
                        type="success"
                      >
                        上传整改
                      </Button>
                    )}
                  </div>
                )}
              </div>
            );
          })}
        </Infiniteloading>
      </div>
      {userType == 3 && (
        <div className="footer-btn-fix">
          <div className="warp"></div>
          <div className="btns">
            <Button className="button" block onClick={goExport}>
              PDF导出
            </Button>
            <Button
              className="button"
              type="info"
              block
              onClick={(e) => {
                clearFormData();
                setShowBottom(true);
              }}
            >
              新增隐患
            </Button>
          </div>
        </div>
      )}
      {userType == 5 && (
        <div className="footer-btn-fix">
          <div className="warp"></div>
          <div className="btns btns2">
            <Button className="button" type="info" block onClick={customerGoExport}>
              导出已整改单
            </Button>
          </div>
        </div>
      )}
      {userType == 4 && (
        <div className="footer-btn-fix">
          <div className="warp"></div>
          <div className="btns btns2">
            <Button className="button" type="info" block onClick={goExport}>
            PDF导出
            </Button>
          </div>
        </div>
      )}
      <Popup
        destroyOnClose
        visible={showBottom}
        style={{}}
        position="bottom"
        onClose={() => {
          setShowBottom(false);
        }}
      >
        <Form form={form}>
          <Form.Item
            label="隐患描述"
            name="pitfall"
            rules={[{ required: true, message: "请输入隐患描述" }]}
          >
            <TextArea rows="1" autosize placeholder="请输入隐患描述" />
          </Form.Item>
          <Form.Item
            label="隐患类型"
            name="type"
            rules={[{ required: true, message: "请选择隐患类型" }]}
          >
            <Radio.RadioGroup>
              <Radio value="重要">重要隐患</Radio>
              <Radio value="一般">一般隐患</Radio>
              <Radio value="小微">小微隐患</Radio>
            </Radio.RadioGroup>
          </Form.Item>
          <Form.Item label="现场照片">
            <ImageUploader
              value={defaultImages}
          
              upload={changeUpload}
              accept="image/*"
              multiple
              maxCount={5}
              onDelete={remove}
            />
          </Form.Item>
          <Form.Item
            label="整改建议"
            name="rectification"
            rules={[{ required: true, message: "请输入整改建议" }]}
          >
            <TextArea rows="1" autosize placeholder="请输入整改建议" />
          </Form.Item>
          <div className="footer-btn">
            <Button type="info" block onClick={submitSucceed}>
              保存
            </Button>
          </div>
        </Form>
      </Popup>

      <Popup
        destroyOnClose
        visible={showBottom2}
        position="bottom"
        onClose={() => {
          setShowBottom2(false);
        }}
      >
        <Form form={checkForm}>
          <Form.Item label="整改描述" name="checkAndAcceptDescription">
            <TextArea rows="1" autosize placeholder="请输入整改描述" />
          </Form.Item>
          <Form.Item label="整改照片">
            <ImageUploader
              value={defaultImages}
        
              upload={changeUpload}
              accept="image/*"
              multiple
              maxCount={5}
              onDelete={remove}
            />
          </Form.Item>
          <div className="footer-btn">
            <Button type="info" block onClick={submitCheck}>
              保存
            </Button>
          </div>
        </Form>
      </Popup>
      <Popup   height='100%' style={{backgroundColor:'#000'}}  visible={showDownload} position="top">
         <img  src={require('../../assets/imgs/download.png')} style={{width:'100%'}}></img>
      </Popup>
    </div>
  );
};

export default PitfallList;
