import { Button, Icon, Input, NavBar, Picker } from "@nutui/nutui-react"
import BigNumber from "bignumber.js"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

export default function InvoiceItems() {
    const navigate = useNavigate()
    const [goodsList, setGoodsList] = useState([])
    const [invoicePreviewInfo, setInvoicePreviewInfo] = useState({})
    const [taxNum, setTaxNum] = useState(0) // 税率
    const taxList = [
        [
            { value: 0, text: '0' },
            { value: 1, text: '1' },
            { value: 3, text: '3' },
            { value: 6, text: '6' },
            { value: 9, text: '9' },
            { value: 13, text: '13' },
            { value: 17, text: '17' }
        ]]
    const [showTaxList, setShowTaxList] = useState(false)
    const [changeValue, setChangeValue] = useState(false); // 取反重新计算金额
    const [taxAmountTotal, setTaxAmountTotal] = useState(0) // 税额总额
    const [amountTotal, setAmountTotal] = useState(0) // 含税总额
    useEffect(() => {
        window.onbeforeunload = () => { // 页面刷新时保存已填数据
            invoicePreviewInfo.goodsList = goodsList
            localStorage.setItem('invoicePreviewInfo', JSON.stringify(invoicePreviewInfo))
        }
        let invoicePreviewInfo = JSON.parse(localStorage.getItem('invoicePreviewInfo')) || {}
        let goodsList = invoicePreviewInfo.goodsList || []
        if (goodsList.length) {
            setTaxNum(goodsList[0].rate)
            setChangeValue(!changeValue)
        }
        setGoodsList(goodsList)
        setInvoicePreviewInfo(invoicePreviewInfo)
        return e => {
            window.onbeforeunload = null;
        }
    }, [])
    useEffect(() => {
        let initTaxAmountTotal = 0
        let initAmountTotal = 0
        goodsList.forEach((row) => {
            row.rate = taxNum;
            if (row.amount && row.price) { // 计算有效数据
                let priceNum = BigNumber(row.price).times(row.amount).toNumber();
                initAmountTotal = BigNumber(priceNum).plus(initAmountTotal).toNumber()
                let rateNum = BigNumber(priceNum).times(taxNum / 100).div(1 + taxNum / 100).toNumber()
                initTaxAmountTotal = BigNumber(rateNum).plus(initTaxAmountTotal).toFixed(2)
                setGoodsList([...goodsList]);
            }
        });
        setTaxAmountTotal(initTaxAmountTotal)
        setAmountTotal(initAmountTotal)
    }, [changeValue, taxNum]);
    const chaceInvoiceGoodsList = () => {
        invoicePreviewInfo.goodsList = goodsList
        localStorage.setItem('invoicePreviewInfo', JSON.stringify(invoicePreviewInfo))
    }
    const onNavInvoicePreview = () => {
        chaceInvoiceGoodsList()
        navigate('/invoicePreview')
    }
    const onSaveInvoiceItems = () => {
        chaceInvoiceGoodsList()
        navigate('/invoicePage/0')
    }
    return (
        <>
            <NavBar onClickBack={e => { navigate(-1) }} title="发票项目" desc="保存" onClickRight={onSaveInvoiceItems}></NavBar>
            <div className="costom_auto_area">
                <div className="costom_auto_height padlr30">
                    {goodsList.map((item, index) => (
                        <div className="costom_card" key={item.name}>
                            <div className="l_flex_xbyc gap_b_1 padr30">
                                <Input defaultValue={item.name} onChange={(e) => { goodsList[index].name = e }} placeholder="请输入项目名称" border={false} />
                                <Icon name="mask-close"
                                    onClick={() => {
                                        goodsList.splice(index, 1)
                                        setGoodsList([...goodsList])
                                        setChangeValue(!changeValue)
                                    }}>
                                </Icon>
                            </div>
                            <Input defaultValue={item.size} onChange={(e) => { goodsList[index].size = e }} label="规格型号" placeholder="请输入" inputAlign="right" border={false} clearable />
                            <Input defaultValue={item.unit} onChange={(e) => { goodsList[index].unit = e }} label="单位" placeholder="请输入" inputAlign="right" border={false} clearable />
                            <Input defaultValue={item.amount} onChange={(e) => { goodsList[index].amount = e; setChangeValue(!changeValue) }} label="数量" placeholder="请输入" type="number" inputAlign="right" border={false} clearable />
                            <Input defaultValue={item.price} onChange={(e) => { goodsList[index].price = e; setChangeValue(!changeValue) }} label="含税单价" placeholder="请输入含税单价" type="number" inputAlign="right" border={false} clearable />
                        </div>
                    ))}
                </div>
                <div className="bottom_area">
                    <div className="l_flex_xbyc font14" >
                        <div>价税合计</div>
                        <div className="minor flex_align_c">¥{amountTotal}</div>
                    </div>
                    <div className="l_flex_xbyc font14 padtb30" onClick={setShowTaxList}>
                        <div>税率</div>
                        <div className="minor flex_align_c">{taxNum}%<Icon name="rect-right"></Icon></div>
                    </div>
                    <Picker isVisible={showTaxList} listData={taxList} onConfirm={(values, list) => { setTaxNum(values[0]) }} onClose={() => setShowTaxList(false)} />
                    <div className="l_flex_xbyc font14 padb30" >
                        <div>合计税额</div>
                        <div className="minor flex_align_c">¥{taxAmountTotal}</div>
                    </div>
                    <div className="bottom_fixed_btn">
                        <Button className="flex1" onClick={onNavInvoicePreview}>预览发票</Button>
                        <Button className="flex1" onClick={() => {
                            goodsList.push({})
                            setGoodsList([...goodsList])
                        }} style={{marginLeft:'15px'}} type="info">添加项目</Button>
                    </div>
                </div>
            </div>
        </>)
}