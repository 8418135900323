import { NavBar } from "@nutui/nutui-react"
import { useNavigate } from "react-router-dom";

const Header = (props) => {
  const { title,right } = props;
  const navigate = useNavigate()
  return (
    <div className="header">
      <NavBar  onClickRight={(e) =>{}} onClickBack={e =>props.noLeft?{}:navigate(-1)} title={title} >
          <div slot='right'>{right}</div>
      </NavBar> 
      <div className="warp"></div>
    </div>
  )
}

export default Header
