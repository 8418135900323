import './FileFilter.less'
import { useEffect, useState } from "react"
import { getFileType } from "../config/utils"
import { Icon, ImagePreview } from '@nutui/nutui-react'
import { FileOutline } from 'antd-mobile-icons'
const FileFilter = (props) => {
    const [FileType, setFileType] = useState("");
    const [showImage, setShowImage] = useState(false)
    useEffect(e => {
        console.log('文件处理')
        setFileType(getFileType(props.url))
    }, [props.url])
    return <div>
        <ImagePreview images={[{ src: props.url }]} show={showImage} onClose={() => setShowImage(false)} />
        <div className="file_content" style={{ width: props.width || '100%', height: props.width || '100%' }}  onClick={e=>window.open(props.url)}>
            {FileType === 'pdf' && <FileOutline fontSize={36}  /> }
            {FileType === 'txt' && <FileOutline fontSize={36}  />}
            {FileType === 'word' && <FileOutline fontSize={36}  />}
            {FileType === 'excel' && <FileOutline fontSize={36}  />}
            {FileType === 'rar' && <FileOutline fontSize={36}  />}
            {FileType === 'other' && <FileOutline fontSize={36}  />}
            {FileType === 'image' ? <img className='file_image'  src={props.url} alt="" /> : <div className='file_name line_2'>{props.name}</div>}
            <div className='file_mask'>
                {props.onRemove && <Icon onClick={() => props.onRemove(props.index)} name="mask-close"></Icon>}
            </div>
        </div>
    </div>

}

export default FileFilter