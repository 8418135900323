import { Cell, Picker } from "@nutui/nutui-react";
import React, { useEffect, useState } from "react";

const NormalPicker = React.memo((props) => {
    console.log(props)
  const [isVisible1, setIsVisible1] = useState(false);
  const [defaultValue, setDefaultValue] = useState(props.defaultValue||'');
  const [listData, setListData] = useState(props.listData||[]);
  const confirmPicker = (values,list) => {
    setDefaultValue(list[0].text);
    setIsVisible1(false);
    props.confirmPicker(list[0]);
  };
  return (
    <>
      <Cell
        style={{ boxShadow: "none" }}
        title={props.title}
        desc={defaultValue || ""}
        isLink
        onClick={() => setIsVisible1(true)}
      />
      <Picker
        isVisible={isVisible1}
        listData={props.listData}
        onConfirm={( values,list) => confirmPicker(values,list)}
        onClose={() => setIsVisible1(false)}
      />
    </>
  );
});

export default NormalPicker;
