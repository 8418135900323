import { useEffect, useState } from "react";
import { API_getStaffCompany } from "../../service/common";
import { Cell, Infiniteloading } from "@nutui/nutui-react";
import Header from "../../components/header";
import { useNavigate } from "react-router-dom";

const MyCompany = (props) => {
  const navigate = useNavigate();
  const [hasMore, setHasMore] = useState(true);
  const [list,setList]=useState([])
  const userType=localStorage.getItem('userType')
  const [pageIndex,setPageIndex]=useState(1)
  const loadMore = () => {
    if(!hasMore){return}
    let reqData={
      PageIndex:pageIndex,
      PageSize:20
    }
    if(userType==4){
      reqData.Organid=localStorage.getItem('organId')
    }else{
      reqData.StaffId=localStorage.getItem('staffId')
    }
    API_getStaffCompany(reqData).then(res=>{
      if(pageIndex==1){
        setList(res.data)
      }else{
        setList(list.concat(res.data));
      }
      if(res.data.length<20){
        setHasMore(false);
      }
    })
  }
  useEffect((e) => {
    
    window.scrollTo(0, 0);
  },[]);
  useEffect((e) => {
    if(!hasMore) return;
    loadMore();
  },[pageIndex]);

  const goPitFallList=(item)=>{
    localStorage.setItem('companyPoint',item.point.join(','))
    localStorage.setItem('companyName',item.information)
    localStorage.setItem('companyInfo',JSON.stringify(item))
    navigate('/pitfall/pitfallList/'+item.id)
  }




  return (
    <div>
      <Header title="负责公司列表" ></Header>
      <div id='scroll'>
        <Infiniteloading
          hasMore={hasMore}
          onLoadMore={e=>setPageIndex(e=>e+1)}
        >
          {list.map((item, index) => {
            return (
              <Cell onClick={e=>{goPitFallList(item)}} key={item.id} title={item.information} subTitle={item.address} isLink >

              </Cell>
            )
          })}
        </Infiniteloading>
      </div>
    </div>
  );
};

export default MyCompany;
