import { useParams } from "react-router-dom"
import Header from "../../components/header"
import { useEffect, useRef, useState } from "react";
import { API_getStaffDetail, API_submitStaffContractSign, API_uploadFile } from "../../service/common";
import { CellGroup,Cell,Signature,Button, Popup, Toast  } from '@nutui/nutui-react';
import { dataURLToBlob, urlToBase64 } from "../../config/utils";

const SignContract =(props)=> {
    const params=useParams();
    const [staffInfo,setStaffInfo]=useState({});
    const [showBasic,setShowBasic]=useState(false);
    const [showContact,setShowContact]=useState(false); 
    const canvasRef = useRef(null);
    const imgRef=useRef(null)
    const ratio = window.devicePixelRatio
    const windowWidth=document.documentElement.clientWidth-20;
    const signWidth=0.15*windowWidth;
    const [imgNewHeight,setImgNewHeight]=useState(0);
    const [contractBase64,setContractBase64]=useState('')
    useEffect(e=>{
        if(params.id){
            API_getStaffDetail({id:params.id}).then(res=>{
                setStaffInfo(res.data);
                urlToBase64(res.data.contract,function(base64){
                    setContractBase64(base64)
                })
            })
        }
    },[params.id]);
    const confirm=(canvas,data)=>{
        setShowContact(true);
        Toast.loading()
        setTimeout(e=>{
            Toast.hide()
            handleImageLoad(data)
        },1000)
    }

    const handleImageLoad = (imgData)=>{

        var canvas = canvasRef.current ;
        var ctx = canvas.getContext('2d');
        setImgNewHeight(imgRef.current.height*windowWidth/imgRef.current.width)
        let imgHeight=imgRef.current.height*windowWidth/imgRef.current.width
        canvas.width=windowWidth*ratio
        canvas.height=imgHeight*ratio
        ctx.drawImage(imgRef.current, 0, 0,windowWidth*ratio,imgHeight*ratio);
        var img = new Image();
        img.src = imgData;
        img.onload = function() {
          ctx.save(); // 保存当前的绘图状态
          ctx.drawImage(img,(0.9*windowWidth-signWidth)*ratio,(0.9*imgHeight-signWidth)*ratio,signWidth*ratio,(signWidth/2)*ratio);
          ctx.restore(); // 恢复之前保存的绘图状态
        };
        
      }

      const submit= ()=>{
        var canvas = canvasRef.current ;
        const dataURL = canvas.toDataURL('image/png');
        const blob = dataURLToBlob(dataURL);
        const file = new File([blob], 'canvas.png', { type: 'image/png' });
        Toast.loading("正在提交...");
        API_uploadFile({file:file}).then(res=>{
            let reqData={
                staffId:params.id,
                signature:res.data
            };
            API_submitStaffContractSign(reqData).then(res=>{
                Toast.success("提交成功!");
                setStaffInfo({...staffInfo,eSignature:dataURL})
                setShowContact(false);
                setShowBasic(false)
            })
        })

      }
    return (
      <div className="home_page">
        <Header title="员工合同"></Header>
        <CellGroup>
              <Cell title="姓名" desc={staffInfo.staffName}></Cell>
              <Cell title="电话" desc={staffInfo.staffPhone}></Cell>
              <Cell title="合同签名状态" desc={staffInfo.eSignature?"已签名":"待签名"}></Cell>
              <Cell title="合同内容"></Cell>
              <img ref={imgRef} src={staffInfo.eSignature||contractBase64} style={{width:'100%'}} alt=""  />
        </CellGroup>
        {!staffInfo.eSignature&&<Button style={{width:'90%',margin:"20px 5%"}} block type="info" onClick={e=>setShowBasic(true)}>去签名</Button>}
        <div style={{height:"50px"}}></div>
        <Popup visible={ showBasic } style={{ padding: '10px',width:"calc(100% - 40px)" }} onClose={ () => { setShowBasic(false) } }>
            <div style={{fontSize:'16px',fontWeight:"600",textAlign:"center",padding:"0 10px"}}>签名区</div>
             <Signature onConfirm={confirm}></Signature>
        </Popup>
        <Popup visible={showContact} style={{ padding: '10px',width:"calc(100% - 40px)" }} onClose={ () => { setShowContact(false) } }>
        <canvas style={{width:windowWidth,height:imgNewHeight}} ref={canvasRef} id='sign-image' width={windowWidth}  >
          
          </canvas>
          <div className="flex">
            <Button style={{width:'45%'}} block type="primary" onClick={e=>setShowContact(false)}>重签</Button>
            <Button style={{width:'45%'}} block type="info" onClick={e=>submit()}>提交</Button>      
          </div>
        </Popup>
      </div>
    )
}

export default SignContract
