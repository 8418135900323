import { useEffect, useState } from "react"
import Header from "../../components/header"
import { Cell, Image, NumberKeyboard, ShortPassword, Toast } from "@nutui/nutui-react"
import { API_ConfirmCashFlow, API_GetCashFlowDetail, API_getMsgCode } from "../../service/common"
import { useParams } from "react-router-dom"
import { Button } from "antd-mobile"

const CashflowDetail =(props)=> {
    const params=useParams()
    const [cashDetail,setCashDetail] = useState({});
    const [visible6, setVisible6] = useState(false)
    const [value, setValue] = useState('')
    const [num,setNum]=useState(0)
    //计时器
    useEffect(() => {
      if (num > 0) {
        setTimeout(() => {
          setNum(num - 1)
        }, 1000)
      }
    }
    , [num])

    const sendMsg= () => {
      setVisible6(true)
      if(num==0){
        API_getMsgCode({phone:cashDetail.staffPhone}).then(res=>{})
        setNum(60)
      }
    }

    const confirmSubmit= (e)=>{
        setValue(e);
        if(e.length==6){
          setVisible6(false);
          Toast.loading("正在确认");
          API_ConfirmCashFlow({cashId:params.id,validCode:e}).then(res=>{
               Toast.success("确认成功");
               window.location.reload()
          })
        }
        
    }
    
 

    useEffect(()=>{
      API_GetCashFlowDetail({id:params.id}).then(res=>{
        res.data.paymentVoucher=res.data.paymentVoucher.split(',')
        setCashDetail(res.data)
      })
    },[])
    return (
      <>
       <Header title="转账记录详情"></Header>
       <Cell title="转账对象" desc={cashDetail.staffName} />
       <Cell title="转账金额" desc={cashDetail.money} />
       <Cell title="转账状态" desc={cashDetail.status} />
       {(cashDetail.status=="员工确认"||cashDetail.status=="系统确认")&&<Cell title="确认时间" desc={cashDetail.confirmExplain} />}
       <Cell title="转账时间" desc={cashDetail.createTime} />
       <Cell title="转账截图" desc='' />
       {cashDetail.paymentVoucher.map((item,index)=>{
         return <Image src={item} />
       })}
       {(cashDetail.status!="员工确认"&&cashDetail.status!="系统确认")&&<div >
        <div className="bottom_area">
          <div className="bottom_fixed_btn">
            <Button
              onClick={() => sendMsg()}
              color="primary"
              shape="rounded"
              block
            >
              转账确认
            </Button>
          </div>
        </div>
        <ShortPassword
        visible={visible6}
        value={value}
        title="请输入验证码"
        desc={`已向${cashDetail.staffPhone}发送验证码`}
        tips={`${num}秒后重试`}
        onClose={() => {
          setVisible6(false)
          setValue('')
        }}
        onChange={(value) => confirmSubmit(value)}
        autoFocus
      />
       </div>}
      </>
    )
}

export default CashflowDetail
