import Header from "../../components/header";
import { useEffect, useState } from "react";
import {
  API_getVisitList,
} from "../../service/common";
import { useParams } from "react-router-dom";
import { Button, Calendar, Cell, CellGroup, Tag, Toast } from "@nutui/nutui-react";
import pdfMake, { fonts } from 'pdfmake/build/pdfmake';  
import { getWeekDates } from "../../config/utils";
var docDefinition;
const ExportVisit = (props) => {
  const userType=localStorage.getItem('userType');
  const params = useParams();
  const [visitList, setVisitList] = useState([[], [], []]);
  const [date1, setDate1] = useState([getWeekDates(0).start, getWeekDates(0).end])
  const [isVisible1, setIsVisible1] = useState(false)
  useEffect( e=>{
    const getData= async()=>{
      let visitListVar;
        let reqData = {
          PageIndex: 1,
          PageSize: 999,
          VisitDateStart:date1[0]+' 00:00:00',
          VisitDateEnd:date1[1]+' 23:59:59',
        };
        if(userType==3){
          reqData.StaffId=localStorage.getItem('staffId')
        }
        
        await API_getVisitList(reqData).then((res) => {
          let list = {};
          res.data.sort((a,b)=>a.staffId>b.staffId?-1:1)
          res.data.map((item, index) => {
             if(!list[item.enterpriseId]){

              list[item.enterpriseId]={companyName:item.enterprise,staffName:item.staffName,name:item.enterpriseContact.name,phone:item.enterpriseContact.tel,visitList:[{images:item.images,name:item.contact.name,content:item.content,time:item.createTime}]};
             }else{
              list[item.enterpriseId].visitList.push({images:item.images,name:item.contact.name,content:item.content,time:item.createTime})
             }
          });
          let newList=[];
          for(let key in list){
            newList.push(list[key])
          }
          setVisitList(newList);
          visitListVar=newList
        });
        let tableContent=[]
        let imagesData={};
        visitListVar.map((row,index)=>{
          tableContent.push({text:row.companyName,fontSize:18,marginTop:index==0?0:100,alignment:"left"})
          tableContent.push({text:`联系人:${row.name}-${row.phone}`, fontSize:14,alignment:"left"})
          tableContent.push({text:`负责员工:${row.staffName}`, fontSize:14,alignment:"left"})
          let tableItemList=[]
          row.visitList.map((visitItem,i)=>{
            let images=[];
            visitItem.images.map(data=>{
                imagesData[data]=data;
                images.push({image:data,width:100,height:100})
              })
              
              tableItemList.push([{text:i+1,alignment: 'center'},visitItem.content,visitItem.name,images,visitItem.time])
          })
          tableContent.push({table:{body:tableItemList,widths:[30,'*',60, 100,100]},marginTop:20})
        })
        pdfMake.fonts = {
            "SourceHanSans.ttf": {
                normal: 'https://files.zone.zjzlsq.cn/File/20240706/ 3a345aa0851d4028a6dcbcadb5cd4655.ttf'
            }
        }
       docDefinition = {
          pageBreakBefore: false,
          pageSize: 'A4',
          content: tableContent,
          // 默认字体，默认的样式，可以在这里设置全局样式
          defaultStyle: {
              font: 'SourceHanSans.ttf',
              fontSize: 12,
              alignment:'center'
          },
          // 内容边距，不设置就会使用默认值
          pageMargins: [20, 30, 20, 30],
          // 图片引用
          images:imagesData,
          // styles 就像是css样式一样 可以在content内元素引用的
          styles: {
              head: {
                  margin: [0, 5, 0, 15],
                  fontSize: 24,
                  color:'#000'
              },
              textStyle:{
                fontSize:18,
              }
          },
      }
    }
    if(pdfMake){
     getData() 
    }
  },[pdfMake,date1])
  const setChooseValue1 = (param) => {
    setDate1([param[0][3], param[1][3]])
  };
  const download = (url) => {
    Toast.loading('正在导出...')
    pdfMake.createPdf(docDefinition).getBuffer(function(buffer) {  
        var blob = new Blob([buffer], { type: 'application/pdf' });  
        var url = URL.createObjectURL(blob);  

        // 创建一个临时的a标签用于下载  
        var a = document.createElement('a');  
        a.href = url;  
        a.download =`企业拜访表.pdf`; // 设置下载的文件名  
        document.body.appendChild(a);  
        a.click();  

        // 清理  
        document.body.removeChild(a);  
        URL.revokeObjectURL(url);  
    });
  };

  return (
    <div className="export-content">
      <Header title="导出PDF"></Header>

      <div  className="main-page">
      <Cell title="选择日期区间" desc={ date1 ? `${date1[0]}至${date1[1]}` : '请选择' } onClick={ e=>setIsVisible1(true) } />
        <Calendar
           visible={ isVisible1 }
           defaultValue={ date1 }
           type="range"
           startDate="2019-12-22"
           onClose={ e=>setIsVisible1(false) }
           onChoose={ setChooseValue1 }
        />
        {visitList.map((item, index) => (
          <div style={{marginTop:"20px",backgroundColor:'#fff',padding:"10px 20px"}}>
              <div>{item.companyName}</div>
              <div>{item.name}-{item.phone}</div>
              <div style={{borderBottom:"1px solid #ccc",paddingBottom:"10px"}}>负责员工:{item.staffName}</div>
              {item?.visitList?.map((visitItem, i)=>{
                return <div style={{marginTop:'20px'}}>
                  <div>{visitItem.content}</div>
                  {visitItem.images.map(img=>{
                    return <img src={img} style={{width:'100px', height:'100px'}}></img>
                  })}
                  <div>{visitItem.time}</div>
                </div>
              })}
          </div>
        ))}
      </div>

      
        <div className="footer-btn-fix">
          <div className="warp"></div>
          <Button
            className="button"
            onClick={(e) => download()}
            type="info"
            block
          >
            导出
          </Button>
        </div>
      
    </div>
  );
};

export default ExportVisit;
