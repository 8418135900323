import { Button, Tabbar, TabbarItem } from "@nutui/nutui-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Message from "./message";
const Dashboard = () => {
  const navigate = useNavigate();
  const [active, setActive] = useState(0);
  useEffect(() => {
    document.title = "浙江税邦园区运营管理有限公司";
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeActive = (item, index) => {
    if (index != 3) {
      navigate(item.props.name);
    }
  };

  const logOut= ()=>{
    localStorage.clear();
    navigate("/");
  }
  return (
    <div className="home_page">
        <div style={{marginBottom:'20px'}}><img style={{width: '100%'}} src={require("../assets/imgs/banner2.png")}/></div>
      {active === 0 && (
        <div>
          <div className="l-flex-xc l_flex_warp font14 align_c dash_content">
          <div
              className="dash_item"
              onClick={() => navigate("/invoicePage/0")}
            >
              <img src={require("../assets/imgs/sq.png")} />
              <div>发票申请</div>
            </div>
            <div
              className="dash_item"
              onClick={() => navigate("/contractList/0")}
            >
              <img src={require("../assets/imgs/ht.png")} />
              <div>合同管理</div>
            </div>
            <div className="dash_item" onClick={() => navigate("/transaction/0")}>
              <img src={require("../assets/imgs/yw.png")} />
              <div>业务管理</div>
            </div>
            <div className="dash_item" onClick={() => navigate("/invoiceList")}>
              <img src={require("../assets/imgs/fp.png")} />
              <div>发票管理</div>
            </div>
            <div
              className="dash_item"
              onClick={() => navigate("/cashflowList")}
            >
              <img src={require("../assets/imgs/zz.png")} />
              <div>转账记录</div>
            </div>
            <div
              className="dash_item"
              onClick={() => navigate("/staffList")}
            >
              <img src={require("../assets/imgs/yg.png")} />
              <div>员工管理</div>
            </div>
          </div>
    
          <div className="dash_name">四流管理</div>
          <div className="flex-center" style={{position:'fixed',bottom:"100px",width:'100%'}}><Button onClick={logOut} type='primary'>退出登录</Button></div>
        </div>
      )}
      {active === 1 && <Message></Message>}
      <div className="tabbar_content">
        <Tabbar
          activeVisible={active}
          onSwitch={(child, index) => {
            changeActive(child, index);
          }}
          bottom
          activeColor="#409eff"
        >
          <TabbarItem tabTitle="首页" icon="home" name="/" />
          <TabbarItem tabTitle="消息" name="/message" icon="message" />
          <TabbarItem
            tabTitle="集采"
            name="/shoppingMall/productList"
            icon="cart"
          />
          <TabbarItem icon="my" tabTitle="订单"></TabbarItem>
        </Tabbar>
        <wx-open-launch-weapp // 需要把该标签设置为透明的 在非微信微信环境下 是不显示该该标签的
          username="gh_06d20f9e5b3e" // 所需跳转的小程序原始id，即小程序对应的以gh_开头的id 必填
          path={`/pages/myOrder/myOrder.html?type=1`} // 所需跳转的小程序内页面路径及参数 需要在路径后面添加.html
          env-version="release" // 小程序的版本
          style={{
            position: "absolute",
            right: 0,
            bottom: 0,
            width: "25%",
            height: "50px",
            zIndex: 999,
          }}
        >
          <script type="text/wxtag-template">
          <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}></div>
          </script>
        </wx-open-launch-weapp>
      </div>
    </div>
  );
};
export default Dashboard;
