import { Button, Form, Input, TextArea, Toast } from "@nutui/nutui-react";
import Header from "../../components/header"
import { useEffect, useState } from "react";
import { API_checkMsgCode, API_getMsgCode, API_registration } from "../../service/common";
import { onTestPhone } from "../../config/utils";

const Registration =(props)=> {
    let t=null;
    const [num, setNum] = useState(60)
    const [form] = Form.useForm();
    const [msg,setMsg]=useState('');
    const[hasSubmit,setHasSubmit]=useState(false)
    const [isLoadingCode, setIsLoadingCode] = useState(false) // 获取验证码中
    const onSendMsgCode = () => {
      let phone = form.getFieldValue('phone')
      if (isLoadingCode) return;
      if (!onTestPhone(phone)) {
        Toast.fail('无效的手机号码')
        return
      }
      clearInterval(t)
      setIsLoadingCode(true)
      API_getMsgCode({ phone }).then(res => {
        Toast.success('验证码已发送');
        
        t=setInterval(e => {
          setNum(num=>num-1);
        }, 1000)
      }).catch(err => {
        setIsLoadingCode(false)
      })
    }
    useEffect(e=>{
        if(num==1){
            clearInterval(t)
            setNum(60)
            setIsLoadingCode(false)
        }
    },[num])

    const submit =(e)=>{
        let phone = form.getFieldValue('phone');
        API_checkMsgCode({phone,password:msg}).then(res=>{
          let reqData={
            enterprise:form.getFieldValue('enterprise'),
            businessScope:form.getFieldValue('businessScope'),
            area:form.getFieldValue('area'),
            peoples:form.getFieldValue('peoples'),
            outputValue:form.getFieldValue('outputValue'),
            tax:form.getFieldValue('tax'),
            contact:{tel:phone,name:form.getFieldValue('name')},
          }
          API_registration(reqData).then(res=>{
            Toast.success('申请成功,请等待工作人员联系!',{ duration: 0 })
            setHasSubmit(true)
            form.resetFields();
            setMsg('')
          })
        })
    }
    return (
      <>
      <Header title="园区入驻申请"></Header>
        <Form form={form} >
          <Form.Item
            label="申请单位"
            name="enterprise"
            rules={[{ required: true, message: "请输入申请单位" }]}
          >
             <TextArea rows="1" autosize placeholder="请输入申请单位" />
          </Form.Item>
          <Form.Item
            label="经营范围"
            name="businessScope"
            rules={[{ required: true, message: "请输入经营范围" }]}
          >
             <TextArea rows="1" autosize placeholder="请输入经营范围" />
          </Form.Item>
          <Form.Item
            label="需求面积m²"
            name="area"
            rules={[{ required: true, message: "请输入需求面积" }]}
          >
             <TextArea rows="1" autosize placeholder="请输入需求面积" />
          </Form.Item>
          <Form.Item
            label="企业人数"
            name="peoples"
            rules={[{ required: true, message: "请输入企业人数" }]}
          >
             <TextArea rows="1" autosize placeholder="请输入企业人数" />
          </Form.Item>
          <Form.Item
            label="预计产值(万元)"
            name="outputValue"
            rules={[{ required: true, message: "请输入预计产值" }]}
          >
             <TextArea rows="1" autosize placeholder="请输入预计产值" />
          </Form.Item>
          <Form.Item
            label="预计税收(万元)"
            name="tax"
            rules={[{ required: true, message: "请输入预计税收" }]}
          >
             <TextArea rows="1" autosize placeholder="请输入预计税收" />
          </Form.Item>
          <Form.Item
            label="联系人"
            name="name"
            rules={[{ required: true, message: "请输入联系人" }]}
          >
             <Input  placeholder="请输入联系人" />
          </Form.Item>
          <Form.Item
            label="联系电话"
            name="phone"
            rules={[{ required: true, message: "请输入联系电话" }]}
          >
             <Input type='number' placeholder="请输入联系电话" />
          </Form.Item>
          <Form.Item
            label="验证码"
          >
             <div className='flex-start'>
             <Input type='number' onChange={e=>{setMsg(e)}} placeholder="请输入验证码" />
             <Button type='primary' onClick={onSendMsgCode} disabled={isLoadingCode} size="mini">{isLoadingCode?(num+'s'):'获取验证码'}</Button>
             </div>
          </Form.Item>

          <div className="footer-btn">
            <Button type="primary" disabled={hasSubmit} onClick={submit} block  >
              {hasSubmit?'已提交申请,请等待工作人员联系':'提交申请'}
            </Button>
          </div>
        </Form>
      </>
    )
}

export default Registration
