import { Form, Input, Button, NavBar, Uploader } from '@nutui/nutui-react';
import './personRegister.less'
import { useEffect, useState } from 'react';
import AddressPicker from "../components/addressPicker/addressPicker";
import { API_companyRegister } from "../service/common";
const PersonRegister = (props) => {
  const onFinish = (values) => {
    values.defatluAddress = {
      point: lnglat,
      address: address
    };
    values.acceptanceer = 1;
    API_companyRegister(values).then(res => {
    })
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const [businessLicense, setBusinessLicense] = useState('');
  const normFile = (e) => {
    let response = JSON.parse(e.responseText).data;
    if (response) {
      setBusinessLicense(response)
    }
  };
  const [lnglat, setLnglat] = useState([]);
  const [address, setAddress] = useState(null);

  const getAddress = (point, address) => {
    setAddress(address);
    setLnglat(point)
  }

  return (
    <div className="companyRegister-page">
      <NavBar title="个体户申请"></NavBar>
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        size="large"
      >
        <Form.Item
          label="公司名称"
          name="firstName"
          rules={[{ required: true, message: "请输入公司名称" }]}
        >
          <Input placeholder="请输入公司名称" />
        </Form.Item>

        <Form.Item
          label="备选名称"
          name="backName"
          rules={[{ required: true, message: "请输入公司备选名称" }]}
        >
          <Input placeholder="请输入公司备选名称" />
        </Form.Item>

        <Form.Item
          label="注册金额"
          name="money"
          rules={[{ required: true, message: "请输入公司注册金额" }]}
        >
          <Input placeholder="请输入公司注册金额" />
        </Form.Item>

        <Form.Item
          label="法人"
          name="legalperson"
          rules={[{ required: true, message: "请输入法人" }]}
        >
          <Input placeholder="请输入法人" />
        </Form.Item>

        <Form.Item
          label="联系人"
          name="legalPerson"
          rules={[{ required: true, message: "请输入联系人" }]}
        >
          <Input placeholder="请输入联系人" />
        </Form.Item>

        <Form.Item
          label="联系电话"
          name="phone"
          rules={[{ required: true, message: "请输入联系电话" }]}
        >
          <Input placeholder="请输入联系电话" />
        </Form.Item>

        <Form.Item label="营业执照">
          <Form.Item
            name="businessLicense"
            noStyle
          >
            <Uploader
              name="file"
              url="http://api.zone.yczlsq.com/taxhelppark/UserLogin/FileUpload"
              onSuccess={normFile}
              listType="picture"
              onRemove={e => setBusinessLicense('')}
            >
            </Uploader>
          </Form.Item>
        </Form.Item>
        <Form.Item label="公司地址">
          <div className="flex-start">
            <Input change={e => { setAddress(e) }} type='text' placeholder="请选择地址" defaultValue={address}></Input>
            <AddressPicker getAddress={getAddress}></AddressPicker>
          </div>
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button style={{ width: "150px" }} type="primary">
            申请
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default PersonRegister;