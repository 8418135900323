import { useEffect, useState } from "react"
import { API_getContractDetail } from "../../service/common"
import { useNavigate, useParams } from "react-router-dom"
import { Button } from "@nutui/nutui-react";
import Header from "../../components/header";

const Contract = (props) => {
    const params = useParams();
    const navigate = useNavigate()
    const [contractDetail, setContractDetail] = useState({})
    useEffect(e => {
        API_getContractDetail({ id: params.id }).then(res => {
            setContractDetail(res.data.conteactTextLink);
            localStorage.setItem('contract', JSON.stringify(res.data))
        })
    }, [params.id])
    return (
        <>
            <Header title="合同签名"></Header>
            <img src={contractDetail} style={{ width: '100%' }} alt="" />
            <div className="footer-btn">
                <Button type="info" block onClick={e => navigate('/signToImage/signature')}>去签名</Button>
            </div>
        </>
    )
}

export default Contract
