import { Divider, Image, ImagePreview, Tag } from '@nutui/nutui-react'
import './visitItem.less'
import { useEffect, useState } from 'react'
const VisitItem =(props)=> {
    const [previewList,setPreviewList]=useState([]);
    const [showPreview1, setShowPreview1] = useState(false);
    const [indexNum,setIndexNum]=useState(0)
    useEffect(e=>{
        if(props.images){
            let list=[];
            props.images.map(row=>{
                list.push({src:row})
            })
            setPreviewList(list)
        }
    },[])
    return (
      <div className="visit-item">
         <div className='visit-title'>{props.enterprise}</div>
         <div className='visit-imgs'>
         {props.images&&props.images.map((item,index)=>{
                return <Image onClick={e=>{setShowPreview1(true);setIndexNum(index+1)}} width='70px' height='70px' fit='cover' src={item} key={index}/>

         })}
         </div>
         <div className='visit-rectification'>拜访对象:{props.contact.name}</div>
         <div className='visit-rectification'>拜访内容:{props.content}</div>
         <div className='visit-rectification'>拜访员工:{props.item.staffName}</div>
         <div className='visit-date'>{props.visitDate}</div>
         
   
         {showPreview1&&<ImagePreview images={previewList} show={showPreview1} initNo={indexNum} onClose={e=>setShowPreview1(false)}></ImagePreview>}
      </div>
    )
}

export default VisitItem
