import "./invoicePreview.less";
import { useEffect, useState } from "react";
import BigNumber from "bignumber.js";
import { digitUppercase } from "../../config/utils";
import Header from "../../components/header";
import { ShrinkOutline, ArrowsAltOutline } from "antd-mobile-icons";
import { Space } from "antd-mobile";

export default function InvoicePreview() {
  const [invoiceInfo, setInvoiceInfo] = useState({});
  const [buyer, setBuyer] = useState({}); // 购货单位
  const [seller, setSeller] = useState({}); // 销货单位
  const [goodsList, setGoodsList] = useState([]); // 发票项目
  const [taxAmountTotal, setTaxAmountTotal] = useState(0); // 税额总额
  const [amountTotal, setAmountTotal] = useState(0); // 含税总额
  const [taxAddAmountTotal, setTaxAddAmountTotal] = useState(0); // 价税合计小写
  const [uppertaxAddAmountTotal, setUpperTaxAddAmountTotal] = useState(""); // 价税合计大写
  const [scaleNum, setScaleNum] = useState(0.4);
  const [lastX, setLastX] = useState(null);
  const [lastY, setLastY] = useState(null);
  const [initialDistance, setInitialDistance] = useState(0);
  const handleTouchStart = (e) => {
    if (e.touches.length === 2) {
      // 如果同时触摸了两个点
      const x1 = e.touches[0].clientX;
      const y1 = e.touches[0].clientY;
      const x2 = e.touches[1].clientX;
      const y2 = e.touches[1].clientY;

      const distance = Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);
      setLastX((x1 + x2) / 2);
      setLastY((y1 + y2) / 2);
      setInitialDistance(distance);
    }
  };

  const handleTouchMove = (e) => {
    if (e.touches.length === 2) {
      const x1 = e.touches[0].clientX;
      const y1 = e.touches[0].clientY;
      const x2 = e.touches[1].clientX;
      const y2 = e.touches[1].clientY;

      const distance = Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);

      const scale = (distance / initialDistance) * scaleNum;
      if(scale<2&&scale>0.4){
        setScaleNum(scale);
        setLastX((x1 + x2) / 2);
        setLastY((y1 + y2) / 2);
      }
    }
  };

  const handleTouchEnd = () => {
    setLastX(null);
    setLastY(null);
  };
  useEffect(() => {
    let invoiceInfo = JSON.parse(localStorage.getItem("invoicePreviewInfo"));
    let { buyer, seller, goodsList, taxNum } = invoiceInfo;
    setSeller(seller);
    setBuyer(buyer);
    setInvoiceInfo(invoiceInfo);
    let initTaxAmountTotal = 0;
    let initAmountTotal = 0;
    let taxAddAmountTotal = 0;
    console.log(taxNum);
    let initGoodsList = [];
    goodsList.forEach((row) => {
      if (row.amount && row.price) {
        // 计算有效数据
        taxAddAmountTotal = BigNumber(row.price)
          .times(row.amount)
          .plus(taxAddAmountTotal)
          .toFixed(2); // 价税合计金额
        row.unitPrice = BigNumber(row.price)
          .div(1 + taxNum / 100)
          .toFixed(2); // 单价 (含税销售额÷（1+税率）)
        row.priceNum = BigNumber(row.unitPrice).times(row.amount).toFixed(2); // 单个项目金额
        initAmountTotal = BigNumber(row.priceNum)
          .plus(initAmountTotal)
          .toFixed(2); // 项目金额总和
        if (taxNum || taxNum === 0) {
          // 税率有效
          row.rateNum = BigNumber(row.price)
            .times(row.amount)
            .times(taxNum / 100)
            .div(1 + taxNum / 100)
            .toFixed(2); // 单个项目税额
          initTaxAmountTotal = BigNumber(row.rateNum)
            .plus(initTaxAmountTotal)
            .toFixed(2); // 税额合计
        }
        initGoodsList.push(row);
      }
    });
    setTaxAddAmountTotal(taxAddAmountTotal);
    setTaxAmountTotal(initTaxAmountTotal);
    setAmountTotal(initAmountTotal);
    setGoodsList(initGoodsList);
  }, []);
  // 价税合计转大写
  useEffect(() => {
    setUpperTaxAddAmountTotal(digitUppercase(taxAddAmountTotal));
  }, [taxAddAmountTotal]);
  return (
    <div className="preview">
      <Header title="发票预览"></Header>
      <div className="preview_content"
                 onTouchStart={handleTouchStart}
                 onTouchMove={handleTouchMove}
                 onTouchEnd={handleTouchEnd}
                 
      >
        <div>

        <div
          className="perview_model"
          style={{
            transform: `scale(${scaleNum})`,
            transformOrigin: `${lastX}px ${lastY}px`,
          }}
        >
          <div className="preview_main">
            <div className="preview_type_text">{invoiceInfo.typeText}</div>
            <div className="preview_time">
              开票日期：
              <div className="preview_time_text">{invoiceInfo.time}</div>
            </div>
            <div className="preview_info">
              <table className="preview_table">
                <tr>
                  <td className="table_cell_1 align_c">购买方</td>
                  <td>
                    <div className="table_cell_2">
                      <div className="table_row_item">
                        <div className="table_just">
                          <div className="table_just_name">名称</div>
                        </div>
                        <div className="table_row_value">
                          {buyer.name || ""}
                        </div>
                      </div>
                      <div className="table_row_item">
                        <div className="table_just">
                          <div className="table_just_name">税务登记号</div>
                        </div>
                        <div className="table_row_value">
                          {buyer.taxAccount || ""}
                        </div>
                      </div>
                      <div className="table_row_item">
                        <div className="table_just">
                          <div className="table_just_name">地址、电话</div>
                        </div>
                        <div className="table_row_value">
                          {buyer.addressPhone || ""}
                        </div>
                      </div>
                      <div className="table_row_item">
                        <div className="table_just">
                          <div className="table_just_name">开户行及帐号</div>
                        </div>
                        <div className="table_row_value">
                          {buyer.bankAccount || ""}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="table_cell_9 align_c">密码区</td>
                  <td className="table_cell_3"></td>
                </tr>
                <tr>
                  <td colSpan={4}>
                    <div className="table_goods align_c">
                      <div className="table_line table_line_1"></div>
                      <div className="table_line table_line_2"></div>
                      <div className="table_line table_line_3"></div>
                      <div className="table_line table_line_4"></div>
                      <div className="table_line table_line_5"></div>
                      <div className="table_line table_line_6"></div>
                      <div className="table_line table_line_7"></div>
                      <div className="flex_align_c">
                        <div className="table_cell_4">
                          货物或应税劳务、服务名称
                        </div>
                        <div className="table_cell_5">规格型号</div>
                        <div className="table_cell_6">单位</div>
                        <div className="table_cell_7">数量</div>
                        <div className="table_cell_7">单价</div>
                        <div className="table_cell_8">金额</div>
                        <div className="table_cell_6">税率</div>
                        <div className="table_cell_8">税额</div>
                      </div>
                      {goodsList.map((item) => (
                        <div
                          className="flex_align_c table_goods_item"
                          key={item.name}
                        >
                          <div className="table_cell_4">{item.name}</div>
                          <div className="table_cell_5">{item.size}</div>
                          <div className="table_cell_6">{item.unit}</div>
                          <div className="table_cell_7">{item.amount}</div>
                          <div className="table_cell_7">{item.unitPrice}</div>
                          <div className="table_cell_8">{item.priceNum}</div>
                          <div className="table_cell_6">
                            {invoiceInfo.taxNum}%
                          </div>
                          <div className="table_cell_8">{item.rateNum}</div>
                        </div>
                      ))}
                      <div className="flex_align_c table_goods_total">
                        <div className="table_cell_4">合计</div>
                        <div className="table_cell_5"></div>
                        <div className="table_cell_6"></div>
                        <div className="table_cell_7"></div>
                        <div className="table_cell_7"></div>
                        <div className="table_cell_8 pre_color_1">
                          ¥<span className="padlr10 ">{amountTotal}</span>
                        </div>
                        <div className="table_cell_6"></div>
                        <div className="table_cell_8 pre_color_1">
                          ¥<span className="padlr10">{taxAmountTotal}</span>
                        </div>
                      </div>
                    </div>
                    <div className="table_total">
                      <div
                        className="table_r_1 align_c"
                        style={{ width: "208px" }}
                      >
                        价税合计（大写）
                      </div>
                      <div className="flex_align_c flex1">
                        <div className="flex1 pre_color_1 padlr20 align_l">
                          ⊗{uppertaxAddAmountTotal}
                        </div>
                        （小写）
                        <div className="table_cell_10 pre_color_1">
                          ¥<span className="padlr10">{taxAddAmountTotal}</span>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="table_cell_1 align_c">销售方</td>
                  <td>
                    <div className="table_cell_2">
                      <div className="table_row_item">
                        <div className="table_just">
                          <div className="table_just_name">名称</div>
                        </div>
                        <div className="table_row_value">
                          {seller.name || ""}
                        </div>
                      </div>
                      <div className="table_row_item">
                        <div className="table_just">
                          <div className="table_just_name">税务登记号</div>
                        </div>
                        <div className="table_row_value">
                          {seller.taxAccount || ""}
                        </div>
                      </div>
                      <div className="table_row_item">
                        <div className="table_just">
                          <div className="table_just_name">地址、电话</div>
                        </div>
                        <div className="table_row_value">
                          {seller.addressPhone || ""}
                        </div>
                      </div>
                      <div className="table_row_item">
                        <div className="table_just">
                          <div className="table_just_name">开户行及帐号</div>
                        </div>
                        <div className="table_row_value">
                          {seller.bankAccount || ""}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="table_cell_9 align_c">备注</td>
                  <td className="table_cell_3">
                    <div className="table_remark">{invoiceInfo.remark}</div>
                  </td>
                </tr>
              </table>
            </div>
            <div className="flex_align_c">
              <div className="table_just padl20">
                <div className="table_just_name" style={{ width: "60px" }}>
                  收款人
                </div>
              </div>
              <div style={{ width: "160px" }}></div>
              <div className="table_just padl20">
                <div className="table_just_name" style={{ width: "40px" }}>
                  复核
                </div>
              </div>
              <div style={{ width: "130px" }}></div>
              <div className="table_just padl20">
                <div className="table_just_name" style={{ width: "60px" }}>
                  开票人
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
      {/* <div className='perview_model'>
                <div className='preview_main'>
                    <div className='preview_type_text'>{invoiceInfo.typeText}</div>
                    <div className='preview_time'>开票日期：<div className='preview_time_text'>{invoiceInfo.time}</div></div>
                    <div className='preview_info'>
                        <table className='preview_table'>
                            <tr>
                                <td className='table_cell_1 align_c'>购买方</td>
                                <td>
                                    <div className='table_cell_2'>
                                        <div className='table_row_item'>
                                            <div className='table_just'>
                                                <div className='table_just_name'>名称</div>
                                            </div>
                                            <div className='table_row_value'>{buyer.name || ''}</div>
                                        </div>
                                        <div className='table_row_item'>
                                            <div className='table_just'>
                                                <div className='table_just_name'>税务登记号</div>
                                            </div>
                                            <div className='table_row_value'>{buyer.taxAccount || ''}</div>
                                        </div>
                                        <div className='table_row_item'>
                                            <div className='table_just'>
                                                <div className='table_just_name'>地址、电话</div>
                                            </div>
                                            <div className='table_row_value'>{buyer.addressPhone || ''}</div>
                                        </div>
                                        <div className='table_row_item'>
                                            <div className='table_just'>
                                                <div className='table_just_name'>开户行及帐号</div>
                                            </div>
                                            <div className='table_row_value'>{buyer.bankAccount || ''}</div>
                                        </div>
                                    </div>
                                </td>
                                <td className='table_cell_9 align_c'>密码区</td>
                                <td className='table_cell_3'></td>
                            </tr>
                            <tr>
                                <td colSpan={4}>
                                    <div className='table_goods align_c'>
                                        <div className='table_line table_line_1'></div>
                                        <div className='table_line table_line_2'></div>
                                        <div className='table_line table_line_3'></div>
                                        <div className='table_line table_line_4'></div>
                                        <div className='table_line table_line_5'></div>
                                        <div className='table_line table_line_6'></div>
                                        <div className='table_line table_line_7'></div>
                                        <div className='flex_align_c'>
                                            <div className='table_cell_4'>货物或应税劳务、服务名称</div>
                                            <div className='table_cell_5'>规格型号</div>
                                            <div className='table_cell_6'>单位</div>
                                            <div className='table_cell_7'>数量</div>
                                            <div className='table_cell_7'>单价</div>
                                            <div className='table_cell_8'>金额</div>
                                            <div className='table_cell_6'>税率</div>
                                            <div className='table_cell_8'>税额</div>
                                        </div>
                                        {goodsList.map(item => (
                                            <div className='flex_align_c table_goods_item' key={item.name}>
                                                <div className='table_cell_4'>{item.name}</div>
                                                <div className='table_cell_5'>{item.size}</div>
                                                <div className='table_cell_6'>{item.unit}</div>
                                                <div className='table_cell_7'>{item.amount}</div>
                                                <div className='table_cell_7'>{item.unitPrice}</div>
                                                <div className='table_cell_8'>{item.priceNum}</div>
                                                <div className='table_cell_6'>{invoiceInfo.taxNum}%</div>
                                                <div className='table_cell_8'>{item.rateNum}</div>
                                            </div>
                                        ))}
                                        <div className='flex_align_c table_goods_total'>
                                            <div className='table_cell_4'>合计</div>
                                            <div className='table_cell_5'></div>
                                            <div className='table_cell_6'></div>
                                            <div className='table_cell_7'></div>
                                            <div className='table_cell_7'></div>
                                            <div className='table_cell_8 pre_color_1'>¥<span className='padlr10 '>{amountTotal}</span></div>
                                            <div className='table_cell_6'></div>
                                            <div className='table_cell_8 pre_color_1'>¥<span className='padlr10'>{taxAmountTotal}</span></div>
                                        </div>
                                    </div>
                                    <div className='table_total'>
                                        <div className='table_r_1 align_c' style={{ width: '208px' }}>价税合计（大写）</div>
                                        <div className='flex_align_c flex1'>
                                            <div className='flex1 pre_color_1 padlr20 align_l'>⊗{uppertaxAddAmountTotal}</div>
                                            （小写）
                                            <div className='table_cell_10 pre_color_1'>¥<span className='padlr10'>{taxAddAmountTotal}</span></div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className='table_cell_1 align_c'>销售方</td>
                                <td>
                                    <div className='table_cell_2'>
                                        <div className='table_row_item'>
                                            <div className='table_just'>
                                                <div className='table_just_name'>名称</div>
                                            </div>
                                            <div className='table_row_value'>{seller.name || ''}</div>
                                        </div>
                                        <div className='table_row_item'>
                                            <div className='table_just'>
                                                <div className='table_just_name'>税务登记号</div>
                                            </div>
                                            <div className='table_row_value'>{seller.taxAccount || ''}</div>
                                        </div>
                                        <div className='table_row_item'>
                                            <div className='table_just'>
                                                <div className='table_just_name'>地址、电话</div>
                                            </div>
                                            <div className='table_row_value'>{seller.addressPhone || ''}</div>
                                        </div>
                                        <div className='table_row_item'>
                                            <div className='table_just'>
                                                <div className='table_just_name'>开户行及帐号</div>
                                            </div>
                                            <div className='table_row_value'>{seller.bankAccount || ''}</div>
                                        </div>
                                    </div>
                                </td>
                                <td className='table_cell_9 align_c'>备注</td>
                                <td className='table_cell_3'>
                                    <div className='table_remark'>{invoiceInfo.remark}</div>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className='flex_align_c'>
                        <div className='table_just padl20'>
                            <div className='table_just_name' style={{ width: '60px' }}>收款人</div>
                        </div>
                        <div style={{ width: '160px' }}></div>
                        <div className='table_just padl20'>
                            <div className='table_just_name' style={{ width: '40px' }}>复核</div>
                        </div>
                        <div style={{ width: '130px' }}></div>
                        <div className='table_just padl20'>
                            <div className='table_just_name' style={{ width: '60px' }}>开票人</div>
                        </div>
                    </div>
                </div>
            </div> */}
      {/* <div className='hover_func'>
                <div className='hover_func_area'>
                    <Space style={{ '--gap': '20px' }}>
                        <span onClick={() => { if (scaleNum > 0.4) setScaleNum(scaleNum - 0.3) }}><ShrinkOutline />缩小</span>
                        <span onClick={() => { if (scaleNum < 2) setScaleNum(scaleNum + 0.3) }}><ShrinkOutline />放大</span>
                    </Space>
                </div>
            </div> */}
    </div>
  );
}
