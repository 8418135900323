/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { API_getTransactionList, API_addTransaction, API_updateTransaction, API_removeTransaction, API_userGetContracts, API_uploadFile } from "../../service/common"
import Header from "../../components/header";
import {  Dialog, Space, Popup, Tag, Toast, ImageUploader, Input } from "antd-mobile";
import InfiniteScroll from 'react-infinite-scroll-component';
import FileFilter from "../../components/FileFilter";
import { RightOutline, FillinOutline, DeleteOutline, CheckCircleFill } from "antd-mobile-icons";
import { useParams } from "react-router-dom";
import { Button } from "@nutui/nutui-react";

const Transaction = (props) => {
    const [pageIndex, setPageIndex] = useState(1);
    const [transactionList, setTransactionList] = useState([]);
    const [invoiceLoading, setInvoiceLoading] = useState(false);
    const [refreshHasMore, setRefreshHasMore] = useState(true);
    const [showTransaction, setShowTransaction] = useState(false)
    const [transactionInfo, setTransactionInfo] = useState({})
    const [linkList, setLinkList] = useState([])
    const [showContract, setShowContract] = useState(false)
    const [contractList, setContractList] = useState([]);
    const [contractHeader, setContractHeader] = useState('')
    const params=useParams()
    useEffect(() => {
        onGetTransactionList()
    }, [pageIndex])
    const onGetTransactionList = () => {
        setInvoiceLoading(true);
        API_getTransactionList({
            pageIndex,
            pageSize: 10,
            Conid:params.id
        }).then(res => {
            let list = transactionList.concat(res.data);
            setTransactionList([...list]);
            setInvoiceLoading(false);
            if (res.data.length < 10) {
                setRefreshHasMore(false)
            }
        })
    }
    // 触底加载
    const onLoadTransactionList = () => {
        if (invoiceLoading) { return; }
        setPageIndex(pageIndex + 1)
    }
    // 下拉刷新
    const onRefreshTransactionList = () => {
        transactionList.length = 0
        setTransactionList(transactionList)
        setPageIndex(1)
        onGetTransactionList()
    }
    const onEditTransactionInfo = async () => {
        let transImg = []
        linkList.forEach(data => {
            transImg.push(data.url)
        })
        transactionInfo.transaction = transImg
        console.log(transactionInfo)
        if (transactionInfo.id) {
            await API_updateTransaction({
                transactionId: transactionInfo.id,
                transaction: transactionInfo.transaction,
                contractId: transactionInfo.contractId,
                transactionDescribe: transactionInfo.transactionDescribe
            }).then(res => Toast.show({ content: '编辑完成' }))
        }
        else {
            await API_addTransaction({
                contractId: transactionInfo.contractId,
                transaction: transactionInfo.transaction,
                transactionDescribe: transactionInfo.transactionDescribe    
            }).then(res => Toast.show({ content: '添加完成' }))
        }
        setShowTransaction(false)
        onRefreshTransactionList()
    }
    const onGetContractList = () => {
        if (contractList.length) { return; }
        API_userGetContracts({
            accept: 0,
            pageIndex: 1,
            pageSize: 99
        }).then(res => {
            console.log(res)
            let list = contractList.concat(res.data);
            setContractList([...list]);
        })
    }
    // 更改购货单位
    const onChangeContract = ({ contractId, header }) => {
        console.log(contractId)
        setTransactionInfo({ ...transactionInfo, contractId })
        setContractHeader(header)
        setShowContract(false)
    }
    return (
        <>
            <Header title="业务列表"></Header>
            <div className="costom_auto_area">
                <div className="costom_auto_height padlr30 " >
                    <InfiniteScroll dataLength={transactionList.length} hasMore={refreshHasMore} endMessage={<div className="font14 minor align_c padtb30">没有更多了</div>}
                        loader={<div className="font14 minor align_c padtb30">Loading...</div>} next={onLoadTransactionList}>
                        {transactionList.map(item =>
                            <div className="costom_card pad30" key={item.id}>
                                      <div className="l_flex_xbyc">
                                    <div className="font15 blod major line_1 flex1">业务名称：{item.transactionDescribe}</div>
                                    <div className="minor font15">{ }</div>
                                </div>
                                <div className="l_flex_xbyc">
                                    <div className="font15 blod major line_1 flex1">合同台头：{item.contractlist.header}</div>
                                    <div className="minor font15">{ }</div>
                                </div>
                                <div className="invo_x_scroll">
                                    {item.transaction && item.transaction.map((data, index) => <FileFilter url={data} width={'80px'} key={index}></FileFilter>)}
                                </div>
                                <div className="l_flex_xbyc">
                                    <div className="minor">{item.createTime}</div>
                                    <div className="l_flex_xeyc">
                                       {params.id==0?<Space style={{ '--gap': '12px' }}>
                                           <Button className="custom_btn_middle"
                                               onClick={() => {
                                                   setShowTransaction(true);
                                                   setContractHeader(item.contractlist.header)
                                                   setTransactionInfo(item);
                                                   if (item.transaction) {
                                                       let initLinkList = []
                                                       item.transaction.forEach(data => {
                                                           initLinkList.push({ url: data })
                                                       })
                                                       setLinkList(initLinkList)
                                                   }
                                               }} >
                                               <Space><FillinOutline />编辑</Space>
                                           </Button>
                                           <Button className="custom_btn_middle" onClick={() =>
                                               Dialog.confirm({
                                                   content: '删除的业务无法恢复，是否继续?',
                                                   onConfirm: async () => {
                                                       API_removeTransaction({ transactionId: item.id }).then(res => {
                                                           Toast.show({
                                                               content: '删除成功',
                                                               position: 'bottom',
                                                           })
                                                           onRefreshTransactionList()
                                                       })
                                                   },
                                               })
                                           }  type='primary' fill="outline"><Space><DeleteOutline />删除</Space></Button>
                                       </Space>:<Space style={{ '--gap': '12px' }}>
                                           {/* <Button  type='primary'>查看现金流</Button> */}
                                        </Space>}
                                    </div>
                                </div>
                            </div>
                        )}
                    </InfiniteScroll>
                </div>
                {/* <div className="bottom_area">
                    <div className="bottom_fixed_btn">
                        <Button onClick={() => setShowTransaction(true)} color='primary' shape='rounded' block >添加业务</Button>
                    </div>
                </div> */}
            </div>
            <Popup visible={showTransaction} afterShow={() => { console.log(linkList) }} onClose={() => { setTransactionInfo({}); setContractHeader(''); setLinkList([]); setShowTransaction(false) }}
                bodyStyle={{ borderTopLeftRadius: '12px', borderTopRightRadius: '12px' }} closeOnMaskClick showCloseButton>
                <div className="pad30 blod align_c font15 gap_b_1">业务信息</div>
                <div className="l_flex_xbyc pad30 font14" >
                    <div>业务名称</div>
                    <div className="l_flex_xbyc minor">
                        <Input type="text"   placeholder="请输入业务名称" value={transactionInfo.transactionDescribe} onChange={e => {setTransactionInfo({ ...transactionInfo, transactionDescribe: e })}}></Input>
                    </div>
                </div>
                <div className="l_flex_xbyc pad30 font14" onClick={() => !transactionInfo.id && setShowContract(true)}>
                    <div>合同抬头</div>
                    <div className="l_flex_xbyc minor">
                        {contractHeader || '选择合同'}
                        <RightOutline />
                    </div>
                </div>
                <div className="font14 pad30">业务文件</div>
                <div className="padlr30">
                    <ImageUploader defaultValue={linkList} value={linkList} columns={4} preview
                        upload={async file => {
                            return { url: await API_uploadFile({ file }).then(res => res.data) }
                        }}
                        onChange={files => setLinkList(files)}
                    />
                </div>
                <div className="pad30">
                    <Button onClick={onEditTransactionInfo} shape="rounded" color="primary" block>确认</Button>
                </div>
            </Popup>
            <Popup visible={showContract} afterShow={onGetContractList} onClose={() => setShowContract(false)} position="bottom" round>
                <div className="costom_auto_area" style={{ height: '70vh' }}>
                    <div className="pad30 font15 rel tc">合同列表
                        <div className="costom_confi" onClick={() => setShowContract(false)}>确认</div>
                    </div>
                    <div className="costom_auto_height gap_b_1 padlr30">
                        {contractList.map(data => (
                            <div className="flex_align_c costom_card" onClick={() => onChangeContract(data)} key={data.contractId}>
                                <div className="padl30">
                                    {data.contractId === transactionInfo.contractId ? <CheckCircleFill color="#1677ff" fontSize={20} /> : <div className="costom_radio_icon"></div>}
                                </div>
                                <div className="pad30 flex1">
                                    <div className="l_flex_xbyc">
                                        <div className="blod comer font15 line_1 major padr30 flex1">{data.header}</div>
                                        <Tag color={data.isCanSeekContract ? '#409EFF' : '#F56C6C'}>{data.isCanSeekContract ? '公开' : '私有'}</Tag>
                                    </div>
                                    <div className="minor font13 padt10">{data.createTime}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </Popup>
        </>
    )
}

export default Transaction
