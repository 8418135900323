import { useEffect, useRef } from "react";
import Header from "../../components/header";

const VisitMap =(props)=> {
        const mapRef = useRef(null);  

        useEffect(() => {  
            if (typeof window.AMap !== 'undefined') {  
                const map = new window.AMap.Map(mapRef.current, {  
                    zoom: 17, // 初始地图层级  
                    center: [localStorage.getItem('lng'), localStorage.getItem('lat')], // 初始地图中心点  
                });  

                const marker = new window.AMap.Marker({  
                    position:[localStorage.getItem('lng'), localStorage.getItem('lat')], // 标记的位置  
                    map: map, // 标记添加到哪个地图上  
                    title: '定位地点' // 鼠标悬停时显示的标题  
                });  

                // 可以在这里添加更多地图功能，如标记、路线等  
            } else {  
                console.error('AMap is not loaded!');  
            }  
        }, []);  

        return   <>
          <Header title="查看位置" ></Header>
          <div ref={mapRef} style={{ width: '100%', height: 'calc(100vh - 44px)' }} />
        </>; 
}

export default VisitMap
