export const dataUrl = 'https://upfiles.zjzlsq.cn/'
export const mapKey = '030324c1d7cbaa237cbd0c4069f33a13';

export function getQueryString(name) {
  let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  let r = window.location.search.substr(1).match(reg);
  if (r != null) {
    return unescape(r[2]);
  }
  return null;
}
export const getFileType =(url)=> {
  let pdfReg = /^.+(\.pdf)$/
  let txtReg = /^.+(\.txt)$/
  let wordReg = /^.+(\.doc|\.docx)$/
  let excelReg = /^.+(\.xls|\.xlsx)$/
  let jpgReg = /^.+(\.png|\.jpg|\.jpeg|\.bmp|\.gif)$/
  let rarReg = /^.+(\.rar|\.zip|\.7z)$/
  if (pdfReg.test(url)) {
    return 'pdf'
  }
  if (txtReg.test(url)) {
    return 'txt'
  }
  if (wordReg.test(url)) {
    return 'word'
  }
  if (excelReg.test(url)) {
    return 'excel'
  }
  if (jpgReg.test(url)) {
    return 'image'
  }
  if(rarReg.test(url)){
    return 'rar'
  }
  return 'other'
}

export const digitUppercase = (num) => {
  let fraction = ['角', '分'];
  let digit = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖'];
  let unit = [['元', '万', '亿'], ['', '拾', '佰', '仟']];
  let head = num < 0 ? '欠' : '';
  num = Math.abs(num);
  let s = '';
  for (let i = 0; i < fraction.length; i++) {
    s += (digit[Math.floor(num * 10 * Math.pow(10, i)) % 10] + fraction[i]).replace(/零./, '');
  }
  s = s || '整';
  num = Math.floor(num);
  for (let i = 0; i < unit[0].length && num > 0; i++) {
    let p = '';
    for (let j = 0; j < unit[1].length && num > 0; j++) {
      p = digit[num % 10] + unit[1][j] + p;
      num = Math.floor(num / 10);
    }
    s = p.replace(/(零.)*零$/, '').replace(/^$/, '零') + unit[0][i] + s;
  }
  return head + s.replace(/(零.)*零元/, '元')
    .replace(/(零.)+/g, '零')
    .replace(/^整$/, '零元整');
}


export function urlToBase64(url, callback) {
  var xhr = new XMLHttpRequest();
  xhr.onload = function() {
    var reader = new FileReader();
    reader.onloadend = function() {
      callback(reader.result);
    }
    reader.readAsDataURL(xhr.response);
  };
  xhr.open('GET', url);
  xhr.responseType = 'blob';
  xhr.send();
}

export function dataURLToBlob(dataURL) {
  const byteString = atob(dataURL.split(',')[1]);
  const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: mimeString });
}




export const invoiceTypes = [{ text: "增值税专用发票", value: 1 }, { text: "增值税电子专用发票", value: 2 }, { text: "增值税电子普通发票", value: 3 }]; // 发票类型
export const invoiceCategories = [{ text: "一般纳税人", value: 1 }, { text: "小规模纳税人", value: 2 }];

export function returnTypeText (id){
     let item=invoiceTypes.find(item=>item.value==id)
     return item.text
}

export function returnCategoryText (id){
  let item=invoiceCategories.find(item=>item.value==id)
  return item.text
}


export function getDistanceFromLatLonInMeters(lat1, lon1, lat2, lon2) {
  var R = 6371; // 地球半径，单位为千米
  var dLat = deg2rad(lat2 - lat1);
  var dLon = deg2rad(lon2 - lon1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var distance = R * c * 1000; // 距离，单位为米
  return distance;
}

function deg2rad(deg) {
  return deg * (Math.PI / 180);
}

export function isWechatBrowser() {
  var ua = navigator.userAgent.toLowerCase();
  if (ua.match(/MicroMessenger/i) == "micromessenger") {
      return true;
  } else {
      return false;
  }
}

export function onTestPhone (phone)  {
  let pattern = /^((\+|00)86)?1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/;
  return pattern.test(phone)
}


export function formatDate(date) {  
  const year = date.getFullYear();  
  const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份加1，并确保是两位数  
  const day = String(date.getDate()).padStart(2, '0'); // 确保是两位数  
  return `${year}-${month}-${day}`;  
}  

export function getWeekDates(weekOffset = 0) {  
  const now = new Date();  
  const today = now.getDate();  
  const todayWeekday = now.getDay(); // 0-周日, 1-周一, ..., 6-周六  

  // 计算本周一的日期  
  const mondayOfThisWeek = new Date(now);  
  mondayOfThisWeek.setDate(today - todayWeekday + 1);  

  // 计算目标周的周一和周日日期  
  const mondayOfWeek = new Date(mondayOfThisWeek);  
  mondayOfWeek.setDate(mondayOfWeek.getDate() + weekOffset * 7);  
  const sundayOfWeek = new Date(mondayOfWeek);  
  sundayOfWeek.setDate(sundayOfWeek.getDate() + 6);  

  // 格式化日期并返回  
  return {  
      start: formatDate(mondayOfWeek), // 本周或目标周的周一  
      end: formatDate(sundayOfWeek),    // 本周或目标周的周日  
  };  
}  