import { Button, Dialog, Form, Infiniteloading, Input, Popup, Radio, TextArea, Toast } from "@nutui/nutui-react";
import Header from "../../components/header"
import { ImageUploader } from "antd-mobile";
import { API_addVisit, API_editVisit, API_getVisitList, API_removeVisit, API_uploadFile } from "../../service/common";
import { useEffect, useState } from "react";
import VisitItem from "../../components/visitItem/visitItem.js";
import { useNavigate } from "react-router-dom";
const dayjs = require("dayjs");
var pageIndex = 1;
const VisitList =(props)=> {
  const userType=localStorage.getItem('userType');
  const [showBottom, setShowBottom] = useState(false);
  const [imageList, setImageList] = useState([]);
  const [form] = Form.useForm();
  const [lngLat, setLngLat] = useState([]);
  const [defaultImages, setDefaultImages] = useState([]);
  const [itemData, setItemData] = useState({});
  const [editIndex,setEditIndex]=useState(0);
  const [list, setList] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [companyName,setCompanyName]=useState()
  const navigate=useNavigate()
  const [hasPosition,setHasPosition]=useState(0)

  var companyInfo=JSON.parse(localStorage.getItem('companyInfo'));
  useEffect(e=>{
    setCompanyName(companyInfo.enterprise)
    firstLoad()
   let AMap = window.AMap;

     AMap.plugin("AMap.Geolocation", function () {
       var geolocation = new AMap.Geolocation({
         // 是否使用高精度定位，默认：true
         enableHighAccuracy: true,
         // 设置定位超时时间，默认：无穷大
         timeout: 10000,
         // 定位按钮的停靠位置的偏移量，默认：Pixel(10, 20)
         buttonOffset: new AMap.Pixel(10, 20),
         //  定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
         zoomToAccuracy: true,
         //  定位按钮的排放位置,  RB表示右下
         buttonPosition: "RB",
       });

       geolocation.getCurrentPosition();
       AMap.event.addListener(geolocation, "complete", onComplete);
       AMap.event.addListener(geolocation, "error", onError);

       function onComplete(data) {
         const { lng, lat } = data.position;
         setLngLat([lng, lat]);
         localStorage.setItem("lng", lng);
         localStorage.setItem("lat", lat);
         setHasPosition(1)
       }

       function onError(data) {
         Toast.fail(JSON.stringify(data));
         console.log(data, "e");
         setHasPosition(2)
         // 定位出错
       }
     });
  },[])
  const changeUpload = async (e) => {
    const file = e;
    let image = null;
    await API_uploadFile({ file: file }).then((res) => {
      imageList.push(res.data);
      setImageList([...imageList]);
      image = res.data;
    });
    return { url: image };
  };
  const remove = (e) => {
    let index = imageList.indexOf(e.url);
    imageList.splice(index, 1);
    setImageList([...imageList]);
  };
  const submitSucceed = (e) => {
    let reqData = {
      enterprise: form.getFieldValue("enterprise"),
      contact:{name: form.getFieldValue("contact")},
      visitDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      content: form.getFieldValue("content"),
      images: imageList,
      point: lngLat[0]?lngLat:[120,30],
    };

    if (!itemData.id) {
      if(companyInfo.id){
        reqData.enterpriseId=companyInfo.id
      }
      API_addVisit(reqData).then((res) => {
        firstLoad()
        setShowBottom(false)
      });
    } else {
      reqData.id = itemData.id;
      API_editVisit(reqData).then((res) => {
          let object=Object.assign(list[editIndex],reqData);
          list[editIndex]=object;
          setList([...list])
          setShowBottom(false)
      });
    }
  }



  const loadMore = (more) => {
    if (!hasMore ) {
      return;
    }
    if(more){
      pageIndex=1;
      setList([])
    }
    let reqData = {
      PageIndex: pageIndex,
      PageSize: 20
    };
    if(companyInfo.id){
      reqData.EnterpriseId=companyInfo.id
    }
    API_getVisitList(reqData).then((res) => {
      if (more) {
        setList(res.data);
      } else{
        setList(prevList => [...prevList, ...res.data]);  
      }
      if (res.data.length < 20) {
        setHasMore(false);
      } else {
        setHasMore(true);
        pageIndex=pageIndex+1;
      }
    });
  };
  const firstLoad =()=>{
    pageIndex = 1;
    setHasMore(true);
    loadMore(true);
  }

  const clearFormData = () => {
    form.resetFields({});
    setImageList([]);
    setItemData({});
    setDefaultImages([]);
  };
  const goMap=(e)=>{
    if(e.point[0]==120){
      Toast.fail("该记录没有定位信息")
      return
    }
    localStorage.setItem('lng',e.point[0]);
    localStorage.setItem('lat',e.point[1]);
    navigate('/staffMenu/visitMap')
    
  }

  const removeItem =(item,index)=>{
    Dialog.alert({
       title: "提示",
       content: "是否删除该拜访记录?",
       onOk: () => {
         let reqData = {
           id: item.id,
         };
         API_removeVisit(reqData).then((res) => {
           list.splice(index, 1);
           setList([...list]);
         });
       },
     });
  }
    return (
      <div className="main-page">
       <Header title={(companyName?companyName:'全部拜访记录')+`(${hasPosition==0?'定位中':(hasPosition==1?'定位成功':'定位失败')})`} ></Header>
       <div id="scroll">
         <Infiniteloading
           hasMore={hasMore}
           onLoadMore ={(e) => loadMore(false)}
         >
           {list.map((item, index) => {
               return <>
                 <VisitItem
                   images={item.images}
                   contact={item.contact}
                   content={item.content}
                   point={item.point}
                   enterprise={item.enterprise}
                   visitDate={item.visitDate}
                   item={item}
                 ></VisitItem>
                 {userType==4&&<div className="pit-btn">
                   <Button  type="default" onClick={e=>goMap(item)}>
                     查看定位
                   </Button>
                 </div>}
                 {userType==3&&<div className="pit-btn">
                   <Button  type="primary" onClick={e=>removeItem(item,index)}>
                     删除
                   </Button>
                 </div>}
               </>
           })}
         </Infiniteloading>
       </div>
       {(userType==3&&companyInfo.id)&&<div className="footer-btn-fix">
         <div className="warp"></div>
         <div className="btns btns2">
           <Button className="button" style={{width:"90%"}} type="info" block onClick={(e) => {
             clearFormData();
             setShowBottom(true);
           }}>
             新增拜访
           </Button>
         </div>
       </div>}
       <Popup
         destroyOnClose
         visible={showBottom}
         style={{}}
         position="bottom"
         onClose={() => {
           setShowBottom(false);
         }}
       >
         <Form form={form}>
           <Form.Item
             label="企业名称"
             name="enterprise"
             rules={[{ required: true, message: "请输入企业名称" }]}
           >
             <Input defaultValue={companyName} disabled  placeholder="请输入企业名称" />
           </Form.Item>
           <Form.Item
             label="拜访对象"
             name="contact"
             rules={[{ required: true, message: "请输入拜访对象" }]}
           >
             <Input  placeholder="请输入拜访对象" />
           </Form.Item>
           <Form.Item label="拜访照片"   rules={[{ required: true}]}>
             <ImageUploader
               value={defaultImages}
               upload={changeUpload}
               accept="image/*"
               capture="camera"
               multiple
               maxCount={5}
               onDelete={remove}
             />
           </Form.Item>
           <Form.Item
             label="拜访内容"
             name="content"
             rules={[{ required: true, message: "请输入拜访内容" }]}
           >
             <TextArea rows="1" autosize placeholder="请输入拜访内容" />
           </Form.Item>
           <div className="footer-btn">
             <Button type="info" block onClick={submitSucceed}>
               保存
             </Button>
           </div>
         </Form>
       </Popup>
      </div>
    )
}

export default VisitList
