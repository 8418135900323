/* eslint-disable react-hooks/exhaustive-deps */
import "./invoiceTemplate.less";
import { useEffect, useState } from "react";
import { API_getCompanyList, API_invoiceApply } from "../../service/common";
import { Calendar, Popup, Cell, CellGroup, Picker, Button, Toast, TextArea, Icon, Price, Input } from "@nutui/nutui-react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import BigNumber from "bignumber.js";
import { Space } from "antd-mobile";
import { invoiceTypes,invoiceCategories, returnTypeText, returnCategoryText } from "../../config/utils";
const InvoiceTemplate = (props, ref) => {
  const { invoiceDetail } = props;
  const [editable, setEditable] = useState(true) // 可编辑信息
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false); // 日历控制
  const taxList = [ // 税率分类
    { value: 0, text: '0' },
    { value: 1, text: '1' },
    { value: 3, text: '3' },
    { value: 6, text: '6' },
    { value: 9, text: '9' },
    { value: 13, text: '13' },
    { value: 17, text: '17' }
  ]
  const [showTaxList, setShowTaxList] = useState(false)
  const [taxNum, setTaxNum] = useState(0) // 税率
  const [time, setTime] = useState('') // 申请日期
  const [type, setType] = useState(1) // 发票类型
  const [typeText, setTypeText] = useState('') // 类型文本
  const [category, setCategory] = useState(1) // 发票类型
  const [categoryText, setCategoryText] = useState('') // 类型文本
  const [showTypePick, setShowTypePicker] = useState(false)
  const [showCategoryPick, setShowCategoryPicker] = useState(false)
  const [buyerId, setBuyerId] = useState(null) // 购货单位id
  const [buyer, setBuyer] = useState({}) // 购货单位
  const [showBuyer, setShowBuyer] = useState(false)
  const [buyerList, setBuyerList] = useState([]) // 购货单位列表
  const [goodsList, setGoodsList] = useState([]) // 发票项目
  const [seller, setSeller] = useState({}) // 销货单位
  const [remark, setRemark] = useState('')
  const [money, setMoney] = useState(0)
  const [loading, setLoading] = useState(false)
  const [changeValue, setChangeValue] = useState(false); // 取反重新计算金额
  const [taxAmountTotal, setTaxAmountTotal] = useState(0) // 税额总额
  const [amountTotal, setAmountTotal] = useState(0) // 含税总额

  // 数据初始化
  useEffect(() => {
    window.onbeforeunload = () => { chaceInvoiceGoodsList() }
    if (invoiceDetail.id) { // 有发票详情
      let { eType, orderState } = invoiceDetail
      let { buyer, time, remarks, seller, goodsList } = invoiceDetail.invoiceNotice
      setTypeText(returnTypeText(invoiceDetail.eType))
      setCategoryText(returnCategoryText(invoiceDetail.eCategory))
      setTaxNum(goodsList[0].rate)
      setGoodsList(goodsList)
      setRemark(remarks)
      setSeller(seller)
      setBuyer(buyer)
      setType(eType)
      setTime(time)
      orderState !== 4 && setEditable(false)
    } else {
      API_getCompanyList({ pageindex: 1, pagesize: 1, isseller: true }).then((res) => {
        setSeller(res.data[0] || {});
      })
      setTime(dayjs(new Date()).format('YYYY-MM-DD'))
      // let invoicePreviewInfo = JSON.parse(localStorage.getItem('invoicePreviewInfo'))

      // if (invoicePreviewInfo) {
      //   let hasNull = false // 是否有无效的发票项目
      //   let { goodsList, remark, seller, buyer, typeText, time, taxNum } = invoicePreviewInfo
      //   let initGoodsList = []
      //   let money = 0
      //   goodsList.forEach(data => {
      //     if (data.amount && data.price) { // 项目数量及项目含税单价(有效)
      //       initGoodsList.push(data)
      //       money += data.amount * data.price
      //     } else {
      //       console.log(hasNull)
      //       if (!hasNull) hasNull = !hasNull // 含有无效的发票项目
      //     }
      //   })
      //   if (hasNull) { Toast.text('已排除发票的无效项目') }
      //   setGoodsList(goodsList)
      //   setTypeText(typeText)
      //   setTaxNum(taxNum)
      //   setRemark(remark)
      //   setSeller(seller)
      //   setMoney(money)
      //   setBuyer(buyer)
      //   setTime(time)
      // }
    }
    return e => {
      window.onbeforeunload = null;
    }
  }, [props.invoiceDetail])

  // 发票项目 (数量,含税单价) 及 税率变动
  // 改动税额,总额
  useEffect(() => {
    let initTaxAmountTotal = 0
    let initAmountTotal = 0
    goodsList.forEach((row) => {
      row.rate = taxNum
      if (row.amount && row.price) { // 计算有效数据
        let priceNum = BigNumber(row.price).times(row.amount).toNumber();
        initAmountTotal = BigNumber(priceNum).plus(initAmountTotal).toNumber()
        let rateNum = BigNumber(priceNum).times(taxNum / 100).div(1 + taxNum / 100).toNumber()
        initTaxAmountTotal = BigNumber(rateNum).plus(initTaxAmountTotal).toFixed(2)
        setGoodsList([...goodsList]);
      }
    });
    setTaxAmountTotal(initTaxAmountTotal)
    setAmountTotal(initAmountTotal)
  }, [changeValue, taxNum]);

  // 缓存发票数据
  const chaceInvoiceGoodsList = () => {
    localStorage.setItem('invoicePreviewInfo', JSON.stringify({ time, typeText, buyer, goodsList, seller, remark, taxNum }))
  }


  const handleShowBuyerList = () => {
    if (!editable) return;
    setShowBuyer(true)
    API_getCompanyList({
      pageindex: 1,
      pagesize: 999,
      isseller: false
    }).then(res => {
      setBuyerList(res.data)
    })
  }
  // 更改购货单位
  const onChangeBuyer = (data) => {
    console.log(data)
    setBuyerId(data.id)
    setBuyer(data)
    setShowBuyer(false)
  }
  // 确认购货单位
  const onConfirmBuyer = () => {
    setShowBuyer(false)
  }
  const handleEditInvoice = async () => {
    if (!buyer.name) {
      Toast.warn("未选择购货单位");
      return;
    }
    if (!seller.name) {
      Toast.warn("未选择销货单位");
      return;
    }
    const reqData = {
      header: buyer.name,
      isCanSeekContract: true,
      type,
      category,
      money,
      invoiceNotice: {
        remarks: remark,
        seller,
        buyer,
        time,
        goodsList,
      },
    };
    setLoading(true);
    await API_invoiceApply(reqData).then((res) => {
      Toast.success("申请成功!");
      navigate('/invoiceList');
      setLoading(false);
      localStorage.removeItem('invoicePreviewInfo')
    });
  };
  return (
    <div className="costom_auto_area">
      <div className="costom_auto_height padlr30">
        <div className="costom_card padlr30 font14">
          <div className="l_flex_xbyc padtb30" onClick={(e) => { }}>
            <div><span className="peony">*</span>申请日期：</div>
            <div className="minor flex_align_c">{time || '请选择申请日期'}</div>
          </div>
          <Calendar visible={isVisible} startDate={null} endDate={null} onClose={(e) => setIsVisible(false)} onChoose={(param) => { setTime(param[3]); }} />
          <div className="l_flex_xbyc padtb30" onClick={(e) => editable && setShowTypePicker(true)}>
            <div><span className="peony">*</span>发票类型：</div>
            <div className="minor flex_align_c">{typeText || '请选择发票类型'} <Icon name="rect-right"></Icon></div>
          </div>
          <div className="l_flex_xbyc padtb30" onClick={(e) => editable && setShowCategoryPicker(true)}>
            <div><span className="peony">*</span>企业类别:</div>
            <div className="minor flex_align_c">{categoryText || '请选择企业类别'} <Icon name="rect-right"></Icon></div>
          </div>
          <Picker isVisible={showCategoryPick} listData={invoiceCategories} onConfirm={(e, options) => setCategory(e[0], setCategoryText(options[0].text))} onClose={() => setShowCategoryPicker(false)} />
          <Picker isVisible={showTypePick} listData={invoiceTypes} onConfirm={(e, options) => setType(e[0], setTypeText(options[0].text))} onClose={() => setShowTypePicker(false)} />
        </div>
        <div className="padtb20 font15 minor">购货单位</div>
        <div className="costom_card">
          {buyer.name ?
            <div className="l_flex_xbyc pad30 font14" onClick={handleShowBuyerList}>
              <div><span className="peony">*</span>购货单位：</div>
              <div className="minor flex_align_c">{buyer.name} <Icon name="rect-right"></Icon></div>
            </div>
            :
            <div className="invo_buyer" onClick={handleShowBuyerList}>
              <Icon name="uploader" size={20} color="#396acc"></Icon>
              <div>选择购货单位</div>
            </div>
          }
        </div>
        <div className="padtb20 font15 minor">发票类目</div>
        {goodsList.map((item, index) => (
          <div className="costom_card" key={item.name}>
            <div className="l_flex_xbyc gap_b_1 padr30">
              <Input defaultValue={item.name} onChange={(e) => { goodsList[index].name = e }} placeholder="请输入项目名称" readonly={!editable} border={false} clearable />
              {editable && <Icon name="mask-close" onClick={() => { goodsList.splice(index, 1); setGoodsList([...goodsList]); setChangeValue(!changeValue) }}></Icon>}
            </div>
            <Input defaultValue={item.size} onChange={(e) => { goodsList[index].size = e }} label="规格型号" placeholder="请输入" inputAlign="right" readonly={!editable} border={false} clearable />
            <Input defaultValue={item.unit} onChange={(e) => { goodsList[index].unit = e }} label="单位" placeholder="请输入" inputAlign="right" readonly={!editable} border={false} clearable />
            <Input defaultValue={item.amount} onChange={(e) => { goodsList[index].amount = e; setChangeValue(!changeValue) }} label="数量" readonly={!editable} placeholder="请输入" type="number" inputAlign="right" border={false} clearable />
            <Input defaultValue={item.price} onChange={(e) => { goodsList[index].price = e; setChangeValue(!changeValue) }} label="含税单价" readonly={!editable} placeholder="请输入含税单价" type="number" inputAlign="right" border={false} clearable />
          </div>
        ))}
        {editable && <Button className="coutom_button" onClick={() => { setGoodsList([...goodsList, {}]) }} type="primary" icon="plus" block plain>添加项目</Button>}
        <div className="costom_card pad30 font14 comor">
          <div className="l_flex_xbyc" onClick={() => editable && setShowTaxList(true)}>
            <div>税率</div>
            <div className="minor flex_align_c peony font21">{taxNum}%<Icon name="rect-right" color="#909090"></Icon></div>
          </div>
          <Picker isVisible={showTaxList} listData={taxList} onConfirm={(values, list) => { setTaxNum(values[0]) }} onClose={() => setShowTaxList(false)} />
          <div className="l_flex_xbyc padtb20">
            <div>合计税额</div>
            <Price price={taxAmountTotal} needSymbol thousands />
          </div>
          <div className="l_flex_xbyc">
            <div>价税合计</div>
            <Price price={amountTotal} needSymbol thousands />
          </div>
        </div>
        <div className="padtb20 font15 minor">备注</div>
        <div className="costom_card">
          <TextArea defaultValue={remark || ''} onChange={(value) => setRemark(value)} placeholder="请输入发票备注" disabled={!editable} autosize></TextArea>
        </div>
        <div className="padtb20 font15 minor">备注</div>
        <div className="costom_card padlr30 font14">
          <div className="l_flex_xbyc padtb30 gap_b_1">
            <div style={{ minWidth: '140px' }}>名称</div>
            <div className="minor">{seller.name || ''}</div>
          </div>
          <div className="l_flex_xbyc padtb30 gap_b_1">
            <div style={{ minWidth: '140px' }}>税务登记号</div>
            <div className="minor">{seller.taxAccount || ''}</div>
          </div>
          <div className="l_flex_xbyc padtb30 gap_b_1">
            <div style={{ minWidth: '140px' }}>地址电话</div>
            <div className="minor">{seller.addressPhone || ''}</div>
          </div>
          <div className="l_flex_xbyc padtb30">
            <div style={{ minWidth: '140px' }}>开户银行及帐号</div>
            <div className="minor">{seller.bankAccount || ''}</div>
          </div>
        </div>
      </div>
      <div className="bottom_area">
        <div className="bottom_fixed_btn">
            <Button className="flex1" onClick={() => { chaceInvoiceGoodsList(); navigate('/invoicePreview') }}>预览发票</Button>
            {editable && <Button className="flex1" style={{marginLeft:'15px'}} onClick={handleEditInvoice} loading={loading} type="info">确认</Button>}
        </div>
      </div>
      <Popup visible={showBuyer} onClose={() => setShowBuyer(false)} style={{ height: '70%' }} position="bottom" round>
        <div className="costom_auto_area" style={{ height: '70vh' }}>
          <div className="pad30 font15 rel tc">购货单位
            <div className="costom_confi" onClick={onConfirmBuyer}>确认</div>
          </div>
          <div className="costom_auto_height gap_b_1 padlr30">
            {buyerList.map(data => (
              <div className="buyer_radio" onClick={() => onChangeBuyer(data)} key={data.id}>
                <div className="padr30">
                  {data.id === buyerId ? <Icon name="check-checked" color="#fa2c19"></Icon> : <Icon name="check-normal"></Icon>}
                </div>
                <div className="padtb20">
                  <div className="blod font17 comer lh16">{data.name}</div>
                  <div className="font15 comor">{data.taxAccount}</div>
                  <div className="minor font14">{data.addressPhone}</div>
                  <div className="minor font14">{data.bankAccount}</div>
                </div>
              </div>
            )
            )}
          </div>
          <div className="pad30 font15 tc">
            管理购货单位
          </div>
        </div>
      </Popup>
    </div>
  );
};

export default InvoiceTemplate;
