import { Button, Cell, Dialog, Infiniteloading, NavBar, Popup } from "@nutui/nutui-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_approveInvite, API_getCooperList } from "../service/common";

const Message = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  const [hasMore, setHasMore] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [pageIndex, setPageIndex] = useState(1);
  const [showBasic, setShowBasic] = useState(false);
  const [info, setInfo] = useState({})
  let loading = false;
  useEffect((e) => {
    if (loading) return;
    loading = true;
    let reqData = {
      pageindex: pageIndex,
      pagesize: 10,
      isoneself: true,
    };
    API_getCooperList(reqData).then((res) => {
      loading = false;
      let newData = dataList.concat(res.data);
      if (pageIndex === 1) {
        newData = res.data;
      }
      setDataList(newData);
      if (res.data.length < 10) {
        setHasMore(false);
      }
    });
  },
    [pageIndex]
  );

  const loadMore = () => {
    if (hasMore) {
      setPageIndex(pageIndex + 1);
    }
  };

  const submit = (data, e) => {
    Dialog.alert({
      title: "确认提醒",
      content: `是否确认${e ? '同意' : '拒绝'}该合作社邀请?`,
      onOk: () => {
        let reqData = {
          id: data.id,
          isaccept: e
        };
        API_approveInvite(reqData).then(res => {
          data.state = e ? 3 : 4;
          setDataList(dataList)
        });
      },
    });
  };

  const showInfo = (item) => {
    setInfo(item.cooperativeDescribe);
    setShowBasic(true)
  }
  return (
    <div className="message-page">
      <NavBar onClickBack={goBack} title="消息列表"></NavBar>
      <Infiniteloading hasMore={hasMore} onLoadMore={loadMore}>
        {dataList.map((data) => {
          return (
            <Cell
              title={data.cooperativeDescribe.name + `邀请你加入合作社`}
              desc={data.createTime}
              subTitle={
                data.state === 1 ?
                  <div className="flex-start" style={{ marginTop: "20px" }}>
                    <Button size="mini" onClick={e => showInfo(data)}>查看信息</Button>
                    <Button style={{ marginLeft: "20px" }} onClick={(e) => submit(data, true)} type="info" size="mini">
                      同意邀请
                    </Button>
                    <Button style={{ marginLeft: "20px" }} onClick={(e) => submit(data, false)} type="danger" size="mini">
                      拒绝邀请
                    </Button>
                  </div> :
                  <div>{data.state === 4 && '已拒绝'}{data.state === 3 && '已同意'}</div>
              }
            />
          );
        })}
      </Infiniteloading>
      <Popup style={{ width: '70%' }} visible={showBasic} onClose={() => { setShowBasic(false) }}>
        <Cell title="机构名字" desc={info.name}></Cell>
        <Cell title="服务项目" desc={info.serviceItem}></Cell>
        <Cell title="联系电话" desc={info.phone}></Cell>
      </Popup>
    </div>
  );
};

export default Message;
