/* eslint-disable react-hooks/exhaustive-deps */
import { createElement, useEffect, useState } from "react";
import { API_getStaffList, API_addStaff, API_updateStaff, API_removeStaff,  API_uploadFile } from "../../service/common"
import Header from "../../components/header";
import { Button, Dialog, Space, Popup, Tag, Toast, ImageUploader, Input } from "antd-mobile";
import InfiniteScroll from 'react-infinite-scroll-component';
import FileFilter from "../../components/FileFilter";
import { RightOutline, FillinOutline, DeleteOutline, CheckCircleFill } from "antd-mobile-icons";
import copy from "copy-to-clipboard";

const StaffManage = (props) => {
    const [pageIndex, setPageIndex] = useState(1);
    const [staffList, setStaffList] = useState([]);
    const [invoiceLoading, setInvoiceLoading] = useState(false);
    const [refreshHasMore, setRefreshHasMore] = useState(true);
    const [showStaff, setShowStaff] = useState(false)
    const [staffInfo, setStaffInfo] = useState({})
    const [linkList, setLinkList] = useState([])
    const [contract, setContract] = useState([]);
    useEffect(() => {
        onGetStaffList()
    }, [pageIndex])
    const onGetStaffList = () => {
        setInvoiceLoading(true);
        API_getStaffList({
            pageIndex,
            pageSize: 10
        }).then(res => {
            let list = staffList.concat(res.data);
            setStaffList([...list]);
            setInvoiceLoading(false);
            if (res.data.length < 10) {
                setRefreshHasMore(false)
            }
        })
    }
    // 触底加载
    const onLoadStaffList = () => {
        if (invoiceLoading) { return; }
        setPageIndex(pageIndex + 1)
    }
    // 下拉刷新
    const onRefreshStaffList = () => {
        staffList.length = 0
        setStaffList(staffList)
        setPageIndex(1)
        onGetStaffList()
    }
    const onEditStaffInfo = async () => {
        staffInfo.staffPaymentCode = linkList[0]?.url;
        staffInfo.contract=contract[0].url;
        if (staffInfo.staffId) {
            await API_updateStaff(staffInfo).then(res => Toast.show({ content: '编辑完成' }))
        }
        else {
            await API_addStaff(staffInfo).then(res => Toast.show({ content: '添加完成' }))
        }
        setShowStaff(false)
        onRefreshStaffList()
    }


    const copyAddress= (id)=>{
        if(copy("http://zjzlsq.cn/project/zoneMobile/#/sc/"+id)){
            Toast.show({content:"复制成功!"})
        }else{
            Toast.show({content:"复制失败!"})
        }
    }


    return (
        <>
            <Header title="员工列表"></Header>
            <div className="costom_auto_area">
                <div className="costom_auto_height padlr30 " >
                    <InfiniteScroll dataLength={staffList.length} hasMore={refreshHasMore} endMessage={<div className="font14 minor align_c padtb30">没有更多了</div>}
                        loader={<div className="font14 minor align_c padtb30">Loading...</div>} next={onLoadStaffList}>
                        {staffList.map(item =>
                            <div className="costom_card pad30" key={item.id}>
                                      <div className="l_flex_xbyc">
                                    <div className="font15 blod major line_1 flex1">员工姓名：{item.staffName}</div>
                                    <div className="minor font15">{ }</div>
                                </div>
                                <div className="l_flex_xbyc">
                                    <div className="font15 blod major line_1 flex1">员工电话：{item.staffPhone}</div>
                                    <div className="minor font15">{ }</div>
                                </div>
                                <div className="l_flex_xbyc">
                                    <div className="font15 blod major line_1 flex1">添加时间：{item.createTime}</div>
                                    <div className="minor font15">{ }</div>
                                </div>
                                <div className="invo_x_scroll">
                                    {item.eSignature && <FileFilter url={item.eSignature} width={'80px'} ></FileFilter> }
                                </div>
                                <div className="l_flex_xbyc">
                                    <div className="l_flex_xeyc">
                                        <Space style={{ '--gap': '12px' }}>
                                            <Button className="custom_btn_middle" shape="rounded" onClick={e=>{copyAddress(item.staffId)}}  color="success">复制签名地址</Button>
                                            <Button className="custom_btn_middle"
                                                onClick={() => {
                                                    setShowStaff(true);
                                                    setStaffInfo(item);
                                                    if (item.staffPaymentCode) {
                                                        setLinkList([{url:item.staffPaymentCode}])
                                                    }
                                                    if (item.contract) {
                                                        setContract([{url:item.contract}])
                                                    }
                                                }} shape="rounded">
                                                <Space><FillinOutline />编辑</Space>
                                            </Button>
                                            <Button className="custom_btn_middle" onClick={() =>
                                                Dialog.confirm({
                                                    content: '删除的员工无法恢复，是否继续?',
                                                    onConfirm: async () => {
                                                        API_removeStaff({ staffId: item.staffId }).then(res => {
                                                            Toast.show({
                                                                content: '删除成功',
                                                                position: 'bottom',
                                                            })
                                                            onRefreshStaffList()
                                                        })
                                                    },
                                                })
                                            } shape="rounded" color="danger" fill="outline"><Space><DeleteOutline />删除</Space></Button>
                                        </Space>
                                    </div>
                                </div>
                            </div>
                        )}
                    </InfiniteScroll>
                </div>
                <div className="bottom_area">
                    <div className="bottom_fixed_btn">
                        <Button onClick={() => setShowStaff(true)} color='primary' shape='rounded' block >添加员工</Button>
                    </div>
                </div>
            </div>
            <Popup visible={showStaff} afterShow={() => { console.log(linkList) }} onClose={() => { setStaffInfo({});  setLinkList([]); setShowStaff(false) }}
                bodyStyle={{ borderTopLeftRadius: '12px', borderTopRightRadius: '12px' }} closeOnMaskClick showCloseButton>
                <div className="pad30 blod align_c font15 gap_b_1">员工信息</div>
                <div className="l_flex_xbyc pad30 font14" >
                    <div>员工姓名</div>
                    <div className="l_flex_xbyc minor">
                        <Input type="text"   placeholder="请输入员工姓名" value={staffInfo.staffName} onChange={e => {setStaffInfo({ ...staffInfo, staffName: e })}}></Input>
                    </div>
                </div>
                <div className="l_flex_xbyc pad30 font14" >
                    <div>员工电话</div>
                    <div className="l_flex_xbyc minor">
                    <Input type="number"   placeholder="请输入员工电话" value={staffInfo.staffPhone} onChange={e => {setStaffInfo({ ...staffInfo, staffPhone: e })}}></Input>
                    </div>
                </div>
                <div className="font14 pad30">员工合同</div>
                <div className="padlr30">
                    <ImageUploader defaultValue={contract} value={contract} columns={4} preview  maxCount={1}
                        upload={async file => {
                            return { url: await API_uploadFile({ file }).then(res => res.data) }
                        }}
                        onChange={files => setContract(files)}
                    />
                </div>
                <div className="font14 pad30">转账二维码</div>
                <div className="padlr30">
                    <ImageUploader defaultValue={linkList} value={linkList} columns={4} preview  maxCount={1}
                        upload={async file => {
                            return { url: await API_uploadFile({ file }).then(res => res.data) }
                        }}
                        onChange={files => setLinkList(files)}
                    />
                </div>
                <div className="pad30">
                    <Button onClick={onEditStaffInfo} shape="rounded" color="primary" block>确认</Button>
                </div>
            </Popup>
        </>
    )
}

export default StaffManage
