import { Divider, Image, ImagePreview, Tag } from '@nutui/nutui-react'
import './pitfallItem.less'
import { useEffect, useState } from 'react'
const PitfallItem =(props)=> {
    const [previewList,setPreviewList]=useState([]);
    const [previewList2,setPreviewList2]=useState([]);
    const [showPreview1, setShowPreview1] = useState(false);
    const [showPreview2, setShowPreview2] = useState(false);
    const [indexNum,setIndexNum]=useState(0)
    useEffect(e=>{
        if(props.images){
            let list=[];
            props.images.map(row=>{
                list.push({src:row})
            })
            setPreviewList(list)
        }
        if(props.item.checkAndAcceptImages){
            let list=[];
            props.item.checkAndAcceptImages.map(row=>{
                list.push({src:row})
            })
            setPreviewList2(list)
        }
    },[])
    return (
      <div className="pitfall-item">
         <div className='pitfall-title'><Tag type={props.type=='小微'?'primary':(props.type=='一般'?'warning':'danger')}>{props.type}</Tag>{props.title}</div>
         <div className='pitfall-imgs'>
         {props.images&&props.images.map((item,index)=>{
                return <Image onClick={e=>{setShowPreview1(true);setIndexNum(index+1)}} width='70px' height='70px' fit='cover' src={item} key={index}/>

         })}
         </div>
         <div className='pitfall-rectification'>整改意见:{props.rectification}</div>
         <div className='pitfall-date'>{props.checkDate}</div>
         {props.item.checkAndAcceptPerson&&<>
            <Divider>整改记录</Divider>
            <div className='pitfall-rectification'>整改内容:{props.item.checkAndAcceptDescription}</div>
            <div className='pitfall-imgs'>
               {props.item.checkAndAcceptImages&&props.item.checkAndAcceptImages.map((item,index)=>{
                       return <Image onClick={e=>{setShowPreview2(true);setIndexNum(index+1)}} width='70px' height='70px' fit='cover' src={item} key={index}/>

               })}
            </div>
            {props.item.checkAndAcceptTime&&<div className='pitfall-date'>整改时间:{props.item.checkAndAcceptTime}</div>}
         </>}

  
         {showPreview1&&<ImagePreview images={previewList} show={showPreview1} initNo={indexNum} onClose={e=>setShowPreview1(false)}></ImagePreview>}
         {showPreview2&&<ImagePreview images={previewList2} show={showPreview2} initNo={indexNum} onClose={e=>setShowPreview2(false)}></ImagePreview>}
      </div>
    )
}

export default PitfallItem
